import React, { createContext } from "react";
import { useActor, useInterpret, useMachine } from "@xstate/react";

import { getUserOnboardingMachine, userOnboardingStates } from "@/machines";
import { useHistory } from "react-router-dom";

/** xstate machine React context for tracking machine service **/
export const XstateContext = createContext({ userOnboardingService: null });

/** There are a few cases where you might want to create a service and
  pass it down in the context instead of using useMachine:
          **/

export const XstateProvider = ({ children }) => {
  // call profile hook here to get the initial data to rehydrate the last state for the user
  const history = useHistory();

  // declare machines for each user journey you want to track
  // To use a piece of state from the service inside a render, use the useSelector(...) hook to subscribe to it.
  const userOnboardingService = useInterpret(
    getUserOnboardingMachine(),
    { context: { history } }, // options
    (state, event) => {
      // subscribes to state changes
      console.log("[xstate] transition to ", state?.value);

      // track if state has changed
      if (!state.changed) {
        console.warn(
          `No transition for event ${event} in state ${state.value}`
        );
      }
    }
  );

  // const userOnboardingService = useMachine(
  //   getUserOnboardingMachine(userOnboardingStates.ADD_EXCHANGE),
  //   {
  //     context: { history },
  //   }
  // );
  // const [state, send] = useActor(machine, (actor) => ({
  //   ...context,
  //   state: actor.state.value,
  // }));
  // const [current] = userOnboardingService;
  // // Listen for transitions
  // current.onTransition((state) => {
  //   console.log("[xstate] current state: ", state.value);
  // });

  /** can add more machine here e.g. , csvUploadMachine to track csv uploading */

  return (
    // @ts-ignore - eslint bracket shit
    <XstateContext.Provider value={{ userOnboardingService }}>
      {children}
    </XstateContext.Provider>
  );
};
