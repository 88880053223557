import React from "react";
import {
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

export const ReferralSettings = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <div>
        <Route exact path={`${path}`}>
          <ReferralSettingsContent />
        </Route>
        <Route exact path={`${path}/new`}>
          <p>tax sub screen</p>
        </Route>
      </div>
    </Switch>
  );
};

const ReferralSettingsContent = () => {
  return (
    <div>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Refer a Friend
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Invite your friends and unlock rewards for both of you! Share the
            experience, reap the benefits.
          </p>
        </div>
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
              <dt className="text-sm font-medium text-gray-500">
                Referral codes are coming soon!
                <br />
                <br />
                Want an early start?
                <br />
                Just drop us an email, and we'll get you set up ahead of the
                crowd.
                <a
                  href="mailto:hello@moonscape.app?subject=[referral codes]&body=Hi! Send me some referral codes! I want to share."
                  className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                >
                  <button
                    type="button"
                    className="rounded-full mt-6 bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Request referral codes
                  </button>
                </a>
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow"></span>

                <span className="ml-4 flex-shrink-0 flex items-start space-x-4">
                  {/* <p>Referral Settings</p> */}
                </span>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};
