import dayjs from 'dayjs';

import en from 'dayjs/locale/en';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
// const utc = require("dayjs/plugin/utc")
dayjs.extend(utc);

export default dayjs;
