import React, { useContext } from "react";

import { Link, useLocation } from "react-router-dom";
import { clsx } from "clsx";
import { Bar, BarChart, Line, LineChart, ResponsiveContainer } from "recharts";

import { Cards } from "@/components";
import { LinkExchangeAccountPrompt } from "./LinkExchangeAccountPrompt";
import { usePositionsData, useProfile } from "@moonscape/shared";

import { usePageTracking } from "@/hooks";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Notifications } from "@/components/Notifications";
import { Stats } from "@/components/Stats";
import { CustomPieChart } from "@/components/PieChart";
import { HoldingsTable } from "@/components/HoldingsTable";
import { PrivacyModeContext } from "@/contexts";

// TODO: check route
// if it's home then dont' show full positions

interface Props {
  hasLinkedExchangeAccount: boolean;
  showAll: boolean;
}

export const Positions = ({ hasLinkedExchangeAccount, showAll }: Props) => {
  const { privacyMode, setPrivacyMode } = useContext(PrivacyModeContext);

  // usePageTracking();
  const { isLoading, isIdle, isError, data: positions } = usePositionsData();

  const { profile } = useProfile();

  const location = useLocation();

  return (
    <div className="max-w-7xl mx-auto">
      {/* 
      <Card>
        <CardHeader>
          <CardTitle>Card Title</CardTitle>
          <CardDescription>Card Description</CardDescription>
        </CardHeader>
        <CardContent>
          <p>Card Content</p>
        </CardContent>
        <CardFooter>
          <p>Card Footer</p>
        </CardFooter>
      </Card>

      <Notifications /> */}
      {/* <Stats /> */}
      <div className="grid gap-4 mt-8 sm:grid-cols-2 xl:grid-cols-2">
        <Card className="flex-col">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-2xl font-semibold">
              Portfolio
            </CardTitle>
          </CardHeader>
          <div className="flex w-full h-full">
            <div className="h-[calc(50vw-100px-32px)] w-[calc(50vw-100px-32px)] mt-4">
              <CustomPieChart
                data={positions}
                baseCurrency={profile?.baseCurrency}
              />
            </div>
          </div>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Balances</CardTitle>
            <CardDescription>
              A list of your cryptocurrency balances
            </CardDescription>
          </CardHeader>
          <CardContent>
            <HoldingsTable showTopXLoading={3} />
          </CardContent>
          {/* <CardFooter>
          <p>Card Footer</p>
        </CardFooter> */}
        </Card>
      </div>

      {hasLinkedExchangeAccount ? (
        <>
          <div
            className={clsx(
              {
                "mt-4 hidden md:grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 pb-3":
                  location?.pathname === "/home",
              },
              {
                "mt-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 pb-3":
                  location?.pathname !== "/home",
              }
            )}
          >
            {/* <Cards showAll={showAll} /> */}
          </div>
          {/* <Link to="/positions" className="flex hyperlink justify-end">
            View all positions
          </Link> */}
        </>
      ) : (
        <LinkExchangeAccountPrompt />
      )}
    </div>
  );
};
