import React, { FC, useState } from "react";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { AxiosResponse, AxiosError } from "axios";

export const ErrorView = ({ errors }) => {
  console.log("errorsView", errors);

  const ErrorList = () => {
    if (!errors) return null;
    const errArr = Array.from(errors);
    if (!errArr.length) return null;
    return errArr.map((e) => {
      console.log("e ", e);
      return <li key={e}>{e}</li>;
    });
  };

  return (
    <div className="rounded-sm bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            There were errors with your submission
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc pl-5 space-y-1">
              <ErrorList />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
