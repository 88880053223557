// xstate actions
// Path: web/src/machines/actions/userOnboardingActions.ts
import { assign } from "xstate";

export const userOnboardingActions = {
  navigateToSettings: (ctx, event) => {
    console.log("[xstate] navigateToSettings", ctx, event);
    ctx.history.push("/settings/exchange");
  },
  someAction: assign((ctx, event: { value: string }) => {
    console.log("[xstate] someAction triggered", ctx, event);
    return {
      ...ctx,
      firstName: event.value,
    };
  }),
  setLastName: assign((ctx, event: { value: string }) => {
    return {
      ...ctx,
      lastName: event.value,
    };
  }),
  // action to update the backend with the user's exchange
  updateStateOnBackend: (ctx, event) => {
    console.log(
      "[xstate] calling function to update user state on BE",
      ctx,
      event?.type
    );
  },
  trackAnalyticsEvent: (ctx, event) => {
    console.log(
      "[xstate] calling function to update user state on BE",
      ctx,
      event?.type
    );
  },
};
