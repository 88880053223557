import React, { useState } from "react";
import { useTaxEventsPagination } from "@moonscape/shared";
import { TaxEventHeadings } from "./TaxEventHeadings";
import { TaxEventRow } from "./TaxEventRow";

import { Button, Loading } from "@/components";
import { useTriggerTaxReport } from "../useTriggerTaxReport";
import { RecentActivityPagination } from "@/screens/TradesScreen";
import { useCapitalGainsData } from "@moonscape/shared";


import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

// render 5 empty rows
let items = [
  {
    datetime: "",
    disposal_type: "",
    asset: "",
    tax_year: "",
    proceeds: "",
    gain: "",
    cost: "",
    fees: "",
    quantity: "",
  },
];

// copied from TradeList (TradeTable)
const TaxEventTable = ({ children }) => {
  return (
    <>
      {/* for sm screens only  */}
      <div className="mt-4 flex flex-col">
        <div className=" sm:block md:hidden">
          <div className="grid grid-cols-1 gap-4 ">
            {/* <TradeListError {...tradeListErrorProps} /> */}
            <p> error </p>
            <div className="px-4 rounded-lg shadow ">
              {items && items.length ? (
                items?.map((txn) => <TaxEventRow screen="sm" {...txn} />)
              ) : (
                <TaxEventRow screen="sm" isEmpty={true} text="No results" />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* md and lg screens */}
      <div className="items-center max-w-screen-2xl mx-auto mt-2  ">
        <div className=" hidden md:block lg:block">
          <div className=" align-middle inline-block min-w-full ">
            <div
              className="overflow-x-auto
              rounded-lg shadow  border-gray-200 sm:rounded-lg "
            >
              {/* <TradeListError {...tradeListErrorProps} /> */}
              {/* <p> error </p> */}

              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const TaxEvents = ({ taxYear }) => {
  const {
    taxEvents,
    isQueryLoading,
    error,
    // items,
    // pagination handlers
    previousPagination,
    nextPagination,
    resetPagination,
    // pagination data
    hasMore,
    remaining,
    page,
    isPreviousData,
  } = useTaxEventsPagination({
    taxYear: taxYear,
    onLoading: () => {
      // console.log("onLoadingHandler");
    },
  });
  const { recalculateTax } = useTriggerTaxReport();

  console.log("TaxEvents", taxEvents);

  items = taxEvents?.items;

  // get the last updated data
  const { cgt_report: cgt } = useCapitalGainsData({
    taxYear: taxYear,
  });

  const lastUpdatedTaxReport = dayjs(dayjs(cgt?.updated_at)).fromNow();


  // items = Array(16).fill(items[0]);

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h2 className="font-semibold leading-6 text-gray-900">Tax Events</h2>
          <p className="mt-2 text-sm text-gray-700">
            Your taxable disposals in the selected tax year
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button
            onClick={async () => {
              await recalculateTax();
            }}
            buttonText={"Re-calculate tax"}
          />
          <p className="text-xs mt-1 text-gray-600">
            Last updated: {lastUpdatedTaxReport}
          </p>
        </div>
      </div>
      <TaxEventTable>
        <table className="w-full border border-collapse table-auto min-w-full">
          <TaxEventHeadings />

          <tbody className=" bg-white divide-y divide-gray-200">
            {items && items.length ? (
              items?.map((te) => {
                return <TaxEventRow screen="md" {...te} />;
              })
            ) : (
              <TaxEventRow screen="md" isEmpty={true} text="No results" />
            )}
          </tbody>
        </table>
      </TaxEventTable>
      {console.log("remaining", remaining)}

      <RecentActivityPagination
        next={nextPagination}
        prev={previousPagination}
        isPreviousData={isPreviousData}
        hasMore={hasMore}
        remaining={remaining}
        trades={taxEvents}
        resetPagination={resetPagination}
        page={page}
      />
      <div className="sm:flex sm:items-center pb-12">
        <div className="sm:flex-auto">
          {/* <p className="px-4 mt-2 text-sm text-gray-500">some footer text</p> */}
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
      </div>
    </>
  );
};
