import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import clsx from "clsx";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { TransactionListContext } from "@/contexts";
import {
  Alert,
  AlertWithComponent,
  Screen,
  TradeList,
  ButtonLink,
} from "@/components";
import {
  usePositionsData,
  useProfile,
  useTradesPagination,
  useSearch,
} from "@moonscape/shared";
import {
  RecentActivity,
  RecentActivitySearch,
  RecentActivityPagination,
  Positions,
} from "@/screens/TradesScreen";

import {
  TaxJurisdictionSelection,
  TaxSelectBanner,
} from "./TaxJurisdictionSelect";
import { MarketData } from "./MarketData";
import { ReadyToDiveIn } from "./ReadyToDiveIn";
import { TransactionAuditModal } from "@/screens/TransactionAuditScreen/TransactionAuditModal";
import { coinbaseAuthURL, formatAmount, getCurrencySymbol } from "@/utils";
import { Stats } from "@/components/Stats";
import { Notifications } from "@/components/Notifications";
import { useTaxEventsPagination } from "@moonscape/shared";
import {
  TaxStats,
  TaxSummary,
  getTaxYearByValue,
  taxYears,
} from "../TaxScreen";
import { PrivacyModeContext } from "@/contexts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { HoldingsTable } from "@/components/HoldingsTable";
import { Skeleton } from "antd";
import { TransactionsListContext } from "@/contexts/TransactionsListContext";

interface Props {
  hasLinkedExchangeAccount: boolean;
  taxJurisdiction?: string;
}

export const TradesScreen = () => {
  // usePageTracking();
  const { privacyMode, setPrivacyMode } = useContext(PrivacyModeContext);
  const [
    shouldShowCoinbaseOAuthNeedsReauth,
    setshouldShowCoinbaseOAuthNeedsReauth,
  ] = useState(false);

  // get the data for search
  const {
    isLoading,
    isSearching,
    searchedValue,
    onLoadingHandler,
    setIsSearching,
    onChangeHandler,
  } = useSearch();

  const {
    isQueryLoading: isProfileQueryLoading,
    error: profileQueryError,
    profile: profile, //{ taxJurisdiction, linkedExchangeAccounts, baseCurrency },
  } = useProfile();

  const {
    trades,
    isQueryLoading,
    error,
    items,
    // pagination handlers
    previousPagination,
    nextPagination,
    resetPagination,
    // pagination data
    hasMore,
    remaining,
    page,
    isPreviousData,
  } = useTradesPagination({
    exchange: null,
    asset: null,
    isSearching,
    searchedValue,
    onLoading: () => {
      // onLoadingHandler();
      // console.log("onLoadingHandler");
    },
  });

  const { isLoading: isLoadingPosition, data: positions } = usePositionsData();

  const { path, url } = useRouteMatch();

  const [isModalOpen, setModalOpen] = useState(false);
  const [extTxnId, setExtnTxnId] = useState(null);
  const [isRefreshTransactions, setIdRefreshTransactions] = useState(false);
  const { isRefresh: isRefreshTransactionsContext } = useContext(
    TransactionListContext
  );

  ///////////////////////////////////////////////////
  // TAX EVENTS
  ///////////////////////////////////////////////////
  const currentTaxYear = new Date().getFullYear();
  const { taxEvents } = useTaxEventsPagination({
    taxYear: currentTaxYear, // get last tax years or rather the first item in the array ,
    onLoading: () => {
      // console.log("onLoadingHandler");
    },
  });

  const hasTaxEvents = taxEvents?.items > 0;
  const [showStats, setShowStats] = useState(true);

  useEffect(() => {
    if (extTxnId) {
      setModalOpen(true);
    }
  }, [extTxnId]);

  useEffect(() => {
    if (!isProfileQueryLoading && profile) {
      const shouldShowCoinbaseOAuthNeedsReauth =
        profile?.linkedExchangeAccounts?.oauth.some((oauth) => {
          return (
            oauth.exchange === "coinbase" && oauth.token_status !== "valid"
          );
        });
      setshouldShowCoinbaseOAuthNeedsReauth(shouldShowCoinbaseOAuthNeedsReauth);
    }
  }, [shouldShowCoinbaseOAuthNeedsReauth, isProfileQueryLoading]);

  useEffect(() => {
    if (isRefreshTransactions) {
      console.log("refresh transactions");
      setIdRefreshTransactions(false);
      resetPagination(true);
    }
  }, [isRefreshTransactions]);

  const getTotalPositions = () => {
    const totalPortfolioValue = Object.keys(positions?.totals || {}).reduce(
      (total, asset) => {
        return (
          total +
          (positions?.totals[asset].amount * positions?.totals[asset].price ||
            0)
        );
      },
      0
    );
    return totalPortfolioValue;
  };

  return (
    //  container
    <TransactionListContext.Provider
      value={{
        setModalOpen,
        setExtnTxnId,
        isRefresh: isRefreshTransactions,
        setIsRefresh: setIdRefreshTransactions,
      }}
    >
      <div className=" min-h-screen flex-1 flex flex-col overflow-hidden">
        <Screen heading="Home">
          <main className="pb-8 z-0 overflow-y-auto">
            {!isProfileQueryLoading ? (
              !profile?.hasLinkExchangeAccounts ? (
                <Alert text={"Please link exchanges to maketax reports"} />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
            {shouldShowCoinbaseOAuthNeedsReauth && (
              <div className="mx-auto py-2  px-4 sm:px-6 lg:px-8">
                <AlertWithComponent>
                  <div className="ml-3">
                    <p>Please login with Coinbase to resume synching</p>
                    <a className="hyperlink" href={coinbaseAuthURL}>
                      <button>Login with Coinbase</button>
                    </a>
                  </div>
                </AlertWithComponent>
              </div>
            )}
            {!isProfileQueryLoading ? (
              !profile?.taxJurisdiction ? (
                <div className="mx-auto ">
                  <TaxSelectBanner />
                </div>
              ) : (
                <></>
              )
            ) : (
              <Skeleton />
            )}

            <div className="">
              <div className="grid gap-4 mt-8 sm:grid-cols-2 xl:grid-cols-2">
                <Card>
                  <CardHeader>
                    <CardTitle>
                      <Link to={`positions`}>Balances</Link>
                    </CardTitle>
                    <CardDescription>
                      A list of your cryptocurrency balances
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <HoldingsTable showTopX={3} showTopXLoading={3} />
                  </CardContent>
                  <CardFooter>
                    <div className="flex w-full justify-between items-center">
                      <Link className="text-sm" to={`positions`}>
                        Show more
                      </Link>
                      <p className="text-gray-400 text-sm text-right flex items-center gap-2">
                        Portfolio value:{" "}
                        <span className={clsx("", { "blur-md": privacyMode })}>
                          {`${getCurrencySymbol(
                            profile?.baseCurrency
                          )}${formatAmount(getTotalPositions())} `}
                        </span>
                      </p>
                    </div>
                  </CardFooter>
                </Card>

                <Card>
                  <CardHeader>
                    <CardTitle>
                      <Link to={`tax/cgt`}>Tax stats</Link>{" "}
                    </CardTitle>
                    <CardDescription>
                      Your tax stats for the year{" "}
                      {getTaxYearByValue((currentTaxYear + 1).toString())}
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <TaxSummary
                      data={{ taxYear: currentTaxYear, showHeading: false }}
                    />
                    {/* <TaxStats
                      selectedTaxYear={currentTaxYear}
                      showStats={showStats}
                      setShowStats={setShowStats}
                      variant="LandingScreen"
                      invertColors={true}
                    /> */}
                  </CardContent>
                  {isProfileQueryLoading ? (
                    profile?.hasLinkExchangeAccounts && (
                      <CardFooter>
                        <Link className="text-sm" to={`tax/cgt`}>
                          See Tax Reports
                        </Link>

                        <p className="text-sm text-gray-500">
                          Connect an exchange to view your tax stats{" "}
                        </p>
                      </CardFooter>
                    )
                  ) : (
                    <></>
                  )}
                </Card>
              </div>

              <div className="py-8">
                <Card>
                  <CardHeader>
                    <CardTitle>
                      <Link to={`tax/cgt`}>Recent Activity</Link>
                    </CardTitle>
                    <CardDescription>
                      Your transactions across all of your connected exchanges
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <RecentActivitySearch
                      isLoading={isLoading}
                      setIsSearching={setIsSearching}
                      onChangeHandler={onChangeHandler}
                      // search should reset pagination
                      resetPagination={resetPagination}
                      page={page}
                    />
                    <RecentActivity
                      isSearching={isSearching}
                      searchedValue={searchedValue}
                      hasLinkedExchangeAccount={false}
                      trades={trades}
                      // pagination handlers
                      previousPagination={previousPagination}
                      nextPagination={nextPagination}
                      resetPagination={resetPagination}
                      // pagination data
                      hasMore={hasMore}
                      remaining={remaining}
                      page={page}
                      // loading states
                      isPreviousData={isPreviousData}
                      isQueryLoading={isQueryLoading}
                      error={error}
                      exchange={undefined}
                      asset={undefined}
                    >
                      <TradeList />
                    </RecentActivity>
                  </CardContent>
                  {isProfileQueryLoading ? (
                    profile?.hasLinkExchangeAccounts && (
                      <CardFooter>
                        <Link className="text-sm" to={`tax/cgt`}>
                          See Tax Reports
                        </Link>

                        <p className="text-sm text-gray-500">
                          Connect an exchange to view your tax stats{" "}
                        </p>
                      </CardFooter>
                    )
                  ) : (
                    <></>
                  )}
                </Card>
              </div>
            </div>
          </main>
          <div className="py-40"></div>
        </Screen>
        <div className="border-t border-gray-200 sticky top-[100vh] pr-24">
          <RecentActivityPagination
            isQueryLoading={isQueryLoading}
            next={nextPagination}
            prev={previousPagination}
            isPreviousData={isPreviousData}
            hasMore={hasMore}
            remaining={remaining}
            trades={trades}
            resetPagination={resetPagination}
            page={page}
          />
        </div>
        {isModalOpen && (
          <TransactionAuditModal
            extTxnId={extTxnId}
            setOpen={setModalOpen}
            open={isModalOpen}
          />
        )}
      </div>
    </TransactionListContext.Provider>
  );
};
