import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { authorizedAxios } from "@/axios";
import { useAuth0 } from "@auth0/auth0-react";

import { minutes } from "@/utils";

interface FilingStatusesOptions {
  refetchOnWindowFocus?: boolean;
}

export const useGetTaxFilingStatusesKey = ["filingStatuses"];

export const useGetTaxFilingStatuses = (
  options?: FilingStatusesOptions
): UseQueryResult<string[], unknown> => {
  const { getAccessTokenSilently } = useAuth0();

  const getFilingStatuses = async (): Promise<string[]> => {
    const url = `/profile/tax-filing-statuses`;
    try {
      const token = await getAccessTokenSilently();

      const res = await authorizedAxios(token).get(url);

      const data = res?.data;
      console.log("[tax] data: ", data);
      return data;
    } catch (error) {
      console.log("[useGetTaxFilingStatuses] error", error);
      throw error;
    }
  };

  const {
    isLoading,
    isIdle,
    isSuccess,
    error,
    isError,
    data: filingStatuses,
    refetch,
  }: UseQueryResult<string[], unknown> = useQuery({
    queryKey: useGetTaxFilingStatusesKey,
    queryFn: getFilingStatuses,
    refetchOnWindowFocus: !!options?.refetchOnWindowFocus,
    staleTime: 1 * minutes,
    refetchInterval: 5 * minutes
  });

  return { isLoading, isIdle, error, filingStatuses, refetch };
};
