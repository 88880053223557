import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

export const CoinbaseOAuthBox = ({
  selectedExchange,
  oauthUrl,
  dismissCoinbaseOAuthBox,
}) => {
  return (
    <div className="mt-10 bg-gray-100 p-6 rounded-md shadow">
      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex-1 space-y-4">
          <p className="text-lg font-semibold text-gray-900">
            Connect your Coinbase using{" "}
            <span className="text-blue-500">Signin with Coinbase</span>
          </p>
          {/* <p className="text-sm text-gray-600">
            Click to login or enter your API keys above.
          </p> */}
          <a
            className="inline-flex items-center text-sm text-blue-600 hover:text-blue-700 focus:text-blue-700 focus:outline-none focus:underline"
            href="https://www.coinbase.com/en-gb/cloud/products/sign-in-with-coinbase"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
            <ArrowTopRightOnSquareIcon
              className="ml-1 h-4 w-4"
              aria-hidden="true"
            />
          </a>
        </div>
        <div className="flex flex-col items-end space-y-3 mt-4 md:mt-0">
          <a href={oauthUrl} rel="noopener noreferrer">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Connect to Coinbase
            </button>
          </a>
          <button
            onClick={(e) => {
              e.preventDefault();
              dismissCoinbaseOAuthBox();
            }}
            className="text-xs text-blue-600 hover:text-blue-700 focus:text-blue-700 focus:outline-none focus:underline"
          >
            No thanks, I'll enter API keys
          </button>
        </div>
      </div>
    </div>
  );
};
