import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Link, useHistory } from "react-router-dom";

export const Alert = () => {
  return (
    <div className="rounded-sm bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            You're ready to connect an exchange!
          </h3>
          <div className="flex flex-row mt-2 text-sm text-yellow-700">
            <p>
              Start importing your data to see all your crypto activity in one
              place.
            </p>
            <Link to="/settings/exchange" className="hyperlink pl-2">
              Connect exchange
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
