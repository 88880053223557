import React, { useEffect, useState, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";

import { axios } from "../axios";

import { AccessContext } from "@/contexts";
import { config } from "../constants";
import {
  LogoutButton,
  MobileNavigation,
  Screen,
  LoginWelcome,
} from "@/components";

export const Profile = () => {
  const accessToken = useContext(AccessContext);
  const [response, setResponse] = useState("");
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  // TODO: set the callback url on login screen
  // save this down to our database
  const [userEmail, setUserEmail] = useState("email");
  const [userSub, setUserSub] = useState("sub");

  useEffect(() => {
    console.log("[profile] useEffect accessToken", accessToken);
    if (isAuthenticated) {
      console.log("[profile] user useEffect", user);
      setUserEmail(user?.email);
      setUserSub(user.sub);
    }
  }, [isAuthenticated, user, accessToken]);

  useEffect(() => {
    console.log("[profile] profile accessToken", accessToken);
  }, [accessToken]);

  const getPositionData = () => {
    //
  };

  const {
    isLoading: isQueryLoading,
    isSuccess,
    error,
    isError,
    data: positionData,
  } = useQuery({
    queryKey: ['user'],
    queryFn: getPositionData
  });

  // fetch positions
  useEffect(() => {
    // console.log("[profile] token", token);
    console.log("[profile] accessToken", accessToken);
    async function getData() {
      console.log(
        "[profile] fetching positions with access token",
        accessToken,
        isLoading
      );
      try {
        // used to get the access token here but haven't
        console.log("[profile] getData is there an accessToken", accessToken);
        const resp = await axios.get(`/positions`);

        console.log("[profile] response from server:", resp.data);

        // TODO: so there's no access token here.
        setResponse(resp.data.message);
      } catch (e) {
        if (e instanceof Error) {
          console.log("[profile] exception:", e);
          console.log("[profile] Error", e.name);
          console.log("[profile] Error", e.message);

          if (e.message === "Consent required") {
            setResponse("Please verify your account by checking your email.");
          }
        }
      }
    }

    if (!isLoading) {
      getData();
    }
  }, [accessToken, isAuthenticated, isLoading]);

  // TODO: add proper loading to wait for all data to be there before we render so you don't get jitter

  if (isLoading) {
    return (
      <Screen>
        <div>Loading auth...</div>
      </Screen>
    );
  }

  return isAuthenticated ? (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="min-h-screen bg-gray-100">
        <MobileNavigation />
        <div className="py-10">
          <main>
            <div className="max-w-4xl mx-auto sm:px-6 lg:px-8">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    User Profile
                  </h3>
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Picture
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <img
                          src={user?.picture}
                          alt={user?.name}
                          className="h-32 w-32 rounded-full mx-auto"
                        />
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Name
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {user?.name}
                      </dd>
                    </div>

                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Email
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {user?.email}
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Subscription ID
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {user?.sub}
                      </dd>
                    </div>
                    {response && (
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Response
                        </dt>
                        <dd
                          className="mt-1 text-sm text-gray-900 sm
:mt-0 sm:col-span-2"
                        >
                          {response}
                        </dd>
                      </div>
                    )}
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Version
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        24.06.30.0
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="mt-5">
                <LogoutButton />
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  ) : (
    <Screen>
      <LoginWelcome />
    </Screen>
  );
};
