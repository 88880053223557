// Constants.js
const dev = {
  url: {
    // API_URL: "http://localhost:8080", // local
    API_URL: "https://moonscape.local/api", // staging
  },
}

const prod = {
  url: {
    API_URL: "https://moonscapex.getmoon.io",
  },
}

export const config = process.env.NODE_ENV === "dev" ? dev : prod

// used for Auth0
export const redirectUri = "https://moonscape.local"

console.log("node", process.env.NODE_ENV)
