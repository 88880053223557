import React, { useContext, useEffect, useState } from "react";

import { Screen } from "@/components";
import { USTaxContext, USTaxContextProvider } from "@/contexts";

import TaxReportProgress from "@/components/TaxReportProgress";
import {  useTaxOperationStatus } from "@/hooks";
import { useCapitalGainsData } from "@moonscape/shared";

import { USTaxEvents } from "@/screens/TaxScreen/USTax/USTaxEvents";
import clsx from "clsx";
import dayjs from "dayjs";
import { TaxStats } from "../TaxStats";
import { useTriggerTaxReport } from "../useTriggerTaxReport";

// Define an array that mirrors the TaxYear type
const START_SUPPORT_YEAR = 2009;
const current_year = new Date().getFullYear();
export const taxYears: string[] = Array(current_year - START_SUPPORT_YEAR + 1)
  .fill(0)
  .map((item, index: number) => (START_SUPPORT_YEAR + index).toString())
  .reverse();
const USTaxHeadingAndSelector = () => {
  const [isLoadingTaxCalcButton, setIsLoadingTaxCalcButton] = useState(false);
  const { setSelectedTaxYear, selectedTaxYear } = useContext(USTaxContext);
  const { recalculateTax } = useTriggerTaxReport();

  // // get the last updated data
  const { cgt_report: cgt, refetch } = useCapitalGainsData({
    taxYear: selectedTaxYear,
  });

  const {
    isQueryLoading: isLoadingTaxOpqueryStatus,
    isIdle,
    error,
    operationStatus,
    email,
    refetch: refetchTaxOperationStatus,
  } = useTaxOperationStatus();
  const lastUpdatedTaxReport = dayjs(dayjs(cgt?.updated_at)).fromNow();

  // Poll for tax operation status every 15 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchTaxOperationStatus();
      console.log("refetchTaxOperationStatus Interval running...");
    }, 15000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Trigger tax calculation button actio
  useEffect(() => {
    if (isLoadingTaxCalcButton) taxCalcButton();
  }, [isLoadingTaxCalcButton]);

  // Handle completion of tax operation
  useEffect(() => {
    if (["completed", "failed"].includes(operationStatus?.status))
      setIsLoadingTaxCalcButton(false);
  }, [operationStatus]);

  const taxCalcButton = async () => {
    await recalculateTax();
    refetchTaxOperationStatus();
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col justify-between items-stretch bg-white p-6 px-8 rounded-lg  gap-5">
        <div className="flex justify-between items-start">
          <h2 className="text-xl font-bold text-gray-900">Tax Year</h2>
          <select
            id="taxyear"
            name="taxyear"
            className="form-select block w-1/3 mt-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            value={selectedTaxYear}
            onChange={(e) => setSelectedTaxYear(e.target.value)}
          >
            {taxYears.map((taxYear) => (
              <option key={taxYear} value={taxYear}>
                {taxYear === current_year.toString()
                  ? `${taxYear} (current)`
                  : taxYear}
              </option>
            ))}
          </select>
        </div>
        <TaxReportProgress
          selectedTaxYear={selectedTaxYear}
          current_year={current_year}
          operationStatus={operationStatus}
          isLoadingTaxCalcButton={isLoadingTaxCalcButton}
          setIsLoadingTaxCalcButton={setIsLoadingTaxCalcButton}
          lastUpdatedTaxReport={lastUpdatedTaxReport}
          refetch={refetch}
        />
      </div>
    </div>
  );
};

export const TaxYearTokens = () => {
  const { setSelectedTaxYear, selectedTaxYear } = useContext(USTaxContext);

  return (
    <div className=" p-6 mt-4">
      {taxYears.map((taxYear) => {
        console.log("value === selectedTaxYear ", taxYear, selectedTaxYear);

        const bgColor = taxYear === selectedTaxYear && "bg-blue-300";
        console.log("bgColor", bgColor);
        return (
          <button key={taxYear} onClick={() => setSelectedTaxYear(taxYear)}>
            <span
              key={taxYear}
              className={clsx(
                bgColor,
                "rounded-full px-4 py-1 mr-2 text-md font-medium text-rose-700 hover:bg-rose-100"
              )}
            >
              {taxYear}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export const USTaxScreen = (props) => {
  const { selectedTaxYear } = useContext(USTaxContext);
  return (
    <Screen heading="Tax" className="bg-gray-50">
      <main className="mx-auto p-8 flex flex-col gap-11">
        <USTaxHeadingAndSelector />
        <TaxStats
          selectedTaxYear={selectedTaxYear}
          showStats={undefined}
          setShowStats={undefined}
          variant="TaxScreen"
          invertColors={undefined}
        />
        <main className="mx-auto p-8 flex">
          <div className="w-2/3">
            <USTaxEvents taxYear={selectedTaxYear} />
          </div>
          <div className=" mr-4"></div>
          <div className="w-1/3">
            {/* spacer div */}
            <div className=" ml-4"></div>

            {/* Add content for the second column here */}
            {/* <div className="flex flex-col justify-between  bg-white p-6 px-8 rounded-lg ">
              <h2 className="text-xl font-bold text-gray-900">Overview</h2>

              <div className="flex justify-between items-start">
                <div className="flex flex-col">
                  <p className="text-gray-700">Total gains</p>
                  <p className="text-gray-700">Total losses</p>
                  <p className="text-gray-700">Total disposals</p>
                  <p className="text-gray-700">Total proceeds</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-gray-700">£0</p>
                  <p className="text-gray-700">£0</p>
                  <p className="text-gray-700">0</p>
                  <p className="text-gray-700">£0</p>
                </div>
              </div>
            </div> */}
          </div>
        </main>
      </main>
    </Screen>
  );
};

export const USTaxScreenWithProvider = ({ isQueryLoading }) => {
  return (
    <USTaxContextProvider>
      <USTaxScreen isQueryLoading={isQueryLoading} />
    </USTaxContextProvider>
  );
};
