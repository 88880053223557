import { useState, useRef } from "react";
import { useTaxEventsData } from "./useTaxEventsData";

export const useTaxEventsPagination = ({
  // isSearching,
  // searchedValue,
  taxYear,
  onLoading,
}) => {
  console.log("shared] tax year ", taxYear);
  // cursor for pagination
  const [page, setPage] = useState(0);
  // keep a stack of cursors and the just pop them

  /**
   * handles trade search to return trade data
   *
   * either calls the /search endpoint
   * if there is a search value or just gets all trade data (paginated)
   */
  let { isQueryLoading, isIdle, error, taxEvents, isPreviousData, isFetching } =
    useTaxEventsData({ page, taxYear, onLoading });

  const cursorStack = useRef<number[]>([]);

  const { items, cursor, remaining } = taxEvents || {};
  const hasMore = taxEvents?.remaining > 0;

  const resetPagination = () => {
    setPage(0);
    // update stack
    cursorStack.current = [0];
  };

  /**
   * keeping track of cursors so that can move between them
   */
  const nextPagination = () => {
    console.log("[cursor] cursorStack add", cursorStack.current);

    console.log(
      "[cursor] click next",
      !isPreviousData,
      taxEvents.hasMore,
      taxEvents.remaining,
      cursor
    );
    if (!isPreviousData && hasMore) {
      setPage(cursor);
      cursorStack.current = [...cursorStack.current, cursor];
    }
  };

  const previousPagination = () => {
    if (cursorStack.current.length <= 1) {
      setPage(0);
      cursorStack.current = [0]; // update stack
    } else {
      const temp = cursorStack.current;
      console.log("[cursor] temp", temp);
      // temp.pop() // remove current
      const lastCursor = temp.pop(); // store prev
      setPage(temp[temp.length - 1]); // set page

      cursorStack.current = [...temp]; // update stack
    }
  };

  return {

    taxEvents: taxEvents?.items || [],
    isQueryLoading,
    error,
    items,
    // pagination handlers
    previousPagination,
    nextPagination,
    resetPagination,
    // pagination data
    hasMore,
    remaining,
    page,
    isPreviousData,
  };
};
