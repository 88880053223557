import React from "react";
import { Link } from "react-router-dom";

/** handles error scenarios for TradesList */
export const TradeListError = ({
  isSearching,
  error,
  setIsError,
  trades,
  items,
}) => {
  if (error) {
    setIsError(true);
    return <p>query error {error}</p>;
  }

  if (trades?.length === 0 && !isSearching) {
    setIsError(true);

    return (
      <div className="flex justify-end ">
        <Link
          to="/import"
          className="flex items-center p-4 rounded-lg text-gray-200 hover:bg-green-400"
        >
          {/* <item.icon className="h-6 w-6" aria-hidden="true" /> */}
          <span className="sr-only">import</span>
        </Link>
      </div>
    );
  } else if (items?.length === 0 && isSearching) {
    setIsError(true);

    return (
      <div className="flex justify-end ">
        <Link
          to="/import"
          className="flex items-center p-4 rounded-lg text-gray-200 hover:bg-green-400"
        >
          <span className="sr-only">import</span>
        </Link>
      </div>
    );
  } else {
    return null;
  }
};
