import {
  CogIcon,
  HomeIcon,
  CalculatorIcon,
  BoltIcon,
  ChatBubbleLeftRightIcon,
  CalendarIcon,
  ChartPieIcon,
  ChartBarIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  UsersIcon,
  UserCircleIcon,
  QueueListIcon,
  LightBulbIcon,
  LockClosedIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";

const navigation = [
  { name: "Dashboard", href: "/", icon: HomeIcon },

  // { name: "Transactions", href: "/transactions", icon: QueueListIcon },
  { name: "Portfolio", href: "/portfolio", icon: ChartBarIcon },
  { name: "Tax Center", href: "/tax/cgt", icon: ChartPieIcon },
  // { name: "Tax Pro", href: "/tax/pro", icon: BanknotesIcon },
  // { name: "Settings", href: "/settings/exchange", icon: CogIcon },
  // { name: "Upgrade", href: "/upgrade", icon: BoltIcon },
  // { name: "CSV Upload", href: "/import", icon: ArrowUpTrayIcon },

  // { name: "Feedback", href: "/feedback", icon: ChatBubbleLeftRightIcon },
  // { name: "Logout", href: "/logout", icon: LogoutIcon },
];

export default navigation;
