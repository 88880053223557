import { useAuth0 } from "@auth0/auth0-react";

const useAuth = () => {
  console.log("[cc] web useAuth");

  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      return token;
    } catch (error) {
      console.error("Error getting access token", error);
      // Handle or throw error based on your error handling policy
    }
  };

  return {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getToken,
  };
};

export { useAuth };
