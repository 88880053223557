import React from "react"
// https://stackoverflow.com/questions/58626594/importing-multiple-svg-via-name-in-a-react-native-component

/**
run generate_imports function } fromterminal 

# used for crypto icons
# run } from/Users/adrianpiot/code/mnscpe-monorepo/app/js/views/containers/alerts/components/CoinIcon/svg
generate_imports() {
# import { ReactComponent as Logo } from"cryptocurrency-icons/svg/color/xmr.svg";

generate_imports() {
# import { ReactComponent as Logo } from"cryptocurrency-icons/svg/color/xmr.svg";
for F in $(ls -1 | sed -e 's/\.svg$//') ; do
  echo 'import { ReactComponent as '${(C)F}' } from"cryptocurrency-icons/svg/color/'$F'.svg";' # sets first char uppercase ${(C)F}
done
}

generate_switch() {
# import { ReactComponent as Logo } from"cryptocurrency-icons/svg/color/xmr.svg";
for F in $(ls -1 | sed -e 's/\.svg$//') ; do
  echo 'case("'$F'"):'
  echo 'return <'${(C)F}' {...props} />;' 
  # echo 'break;'
done
}

generate_imports | pbcopy
generate_switch | pbcopy

then change $pac 0x 2give and other weird ones to match uppercase and no numbers 

get icons } from
https://github.com/spothq/cryptocurrency-icons


 */
import assetsManifest from "cryptocurrency-icons/manifest.json"

// TODO: add exchange SVGs here
import { ReactComponent as Coinbase } from "./svg/coinbase.svg"
import { ReactComponent as Kraken } from "./svg/kraken.svg"
import { ReactComponent as Binance } from "./svg/binance.svg"

// add generated imports below
import { ReactComponent as Pac } from "cryptocurrency-icons/svg/color/$pac.svg"
import { ReactComponent as ZeroXbtc } from "cryptocurrency-icons/svg/color/0xbtc.svg"
import { ReactComponent as Twogive } from "cryptocurrency-icons/svg/color/2give.svg"
import { ReactComponent as Abt } from "cryptocurrency-icons/svg/color/abt.svg"
import { ReactComponent as Act } from "cryptocurrency-icons/svg/color/act.svg"
import { ReactComponent as Actn } from "cryptocurrency-icons/svg/color/actn.svg"
import { ReactComponent as Ada } from "cryptocurrency-icons/svg/color/ada.svg"
import { ReactComponent as Add } from "cryptocurrency-icons/svg/color/add.svg"
import { ReactComponent as Adx } from "cryptocurrency-icons/svg/color/adx.svg"
import { ReactComponent as Ae } from "cryptocurrency-icons/svg/color/ae.svg"
import { ReactComponent as Aeon } from "cryptocurrency-icons/svg/color/aeon.svg"
import { ReactComponent as Aeur } from "cryptocurrency-icons/svg/color/aeur.svg"
import { ReactComponent as Agi } from "cryptocurrency-icons/svg/color/agi.svg"
import { ReactComponent as Agrs } from "cryptocurrency-icons/svg/color/agrs.svg"
import { ReactComponent as Aion } from "cryptocurrency-icons/svg/color/aion.svg"
import { ReactComponent as Amb } from "cryptocurrency-icons/svg/color/amb.svg"
import { ReactComponent as Amp } from "cryptocurrency-icons/svg/color/amp.svg"
import { ReactComponent as Ampl } from "cryptocurrency-icons/svg/color/ampl.svg"
import { ReactComponent as Ant } from "cryptocurrency-icons/svg/color/ant.svg"
import { ReactComponent as Apex } from "cryptocurrency-icons/svg/color/apex.svg"
import { ReactComponent as Appc } from "cryptocurrency-icons/svg/color/appc.svg"
import { ReactComponent as Ardr } from "cryptocurrency-icons/svg/color/ardr.svg"
import { ReactComponent as Arg } from "cryptocurrency-icons/svg/color/arg.svg"
import { ReactComponent as Ark } from "cryptocurrency-icons/svg/color/ark.svg"
import { ReactComponent as Arn } from "cryptocurrency-icons/svg/color/arn.svg"
import { ReactComponent as Ary } from "cryptocurrency-icons/svg/color/ary.svg"
import { ReactComponent as Ast } from "cryptocurrency-icons/svg/color/ast.svg"
import { ReactComponent as Atm } from "cryptocurrency-icons/svg/color/atm.svg"
import { ReactComponent as Atom } from "cryptocurrency-icons/svg/color/atom.svg"
import { ReactComponent as Audr } from "cryptocurrency-icons/svg/color/audr.svg"
import { ReactComponent as Auto } from "cryptocurrency-icons/svg/color/auto.svg"
import { ReactComponent as Aywa } from "cryptocurrency-icons/svg/color/aywa.svg"
import { ReactComponent as Bab } from "cryptocurrency-icons/svg/color/bab.svg"
import { ReactComponent as Bat } from "cryptocurrency-icons/svg/color/bat.svg"
import { ReactComponent as Bay } from "cryptocurrency-icons/svg/color/bay.svg"
import { ReactComponent as Bcbc } from "cryptocurrency-icons/svg/color/bcbc.svg"
import { ReactComponent as Bcc } from "cryptocurrency-icons/svg/color/bcc.svg"
import { ReactComponent as Bcd } from "cryptocurrency-icons/svg/color/bcd.svg"
import { ReactComponent as Bch } from "cryptocurrency-icons/svg/color/bch.svg"
import { ReactComponent as Bcio } from "cryptocurrency-icons/svg/color/bcio.svg"
import { ReactComponent as Bcn } from "cryptocurrency-icons/svg/color/bcn.svg"
import { ReactComponent as Bco } from "cryptocurrency-icons/svg/color/bco.svg"
import { ReactComponent as Bcpt } from "cryptocurrency-icons/svg/color/bcpt.svg"
import { ReactComponent as Bdl } from "cryptocurrency-icons/svg/color/bdl.svg"
import { ReactComponent as Beam } from "cryptocurrency-icons/svg/color/beam.svg"
import { ReactComponent as Bela } from "cryptocurrency-icons/svg/color/bela.svg"
import { ReactComponent as Bix } from "cryptocurrency-icons/svg/color/bix.svg"
import { ReactComponent as Blcn } from "cryptocurrency-icons/svg/color/blcn.svg"
import { ReactComponent as Blk } from "cryptocurrency-icons/svg/color/blk.svg"
import { ReactComponent as Block } from "cryptocurrency-icons/svg/color/block.svg"
import { ReactComponent as Blz } from "cryptocurrency-icons/svg/color/blz.svg"
import { ReactComponent as Bnb } from "cryptocurrency-icons/svg/color/bnb.svg"
import { ReactComponent as Bnt } from "cryptocurrency-icons/svg/color/bnt.svg"
import { ReactComponent as Bnty } from "cryptocurrency-icons/svg/color/bnty.svg"
import { ReactComponent as Booty } from "cryptocurrency-icons/svg/color/booty.svg"
import { ReactComponent as Bos } from "cryptocurrency-icons/svg/color/bos.svg"
import { ReactComponent as Bpt } from "cryptocurrency-icons/svg/color/bpt.svg"
import { ReactComponent as Bq } from "cryptocurrency-icons/svg/color/bq.svg"
import { ReactComponent as Brd } from "cryptocurrency-icons/svg/color/brd.svg"
import { ReactComponent as Bsd } from "cryptocurrency-icons/svg/color/bsd.svg"
import { ReactComponent as Bsv } from "cryptocurrency-icons/svg/color/bsv.svg"
import { ReactComponent as Btc } from "cryptocurrency-icons/svg/color/btc.svg"
import { ReactComponent as Btcd } from "cryptocurrency-icons/svg/color/btcd.svg"
import { ReactComponent as Btch } from "cryptocurrency-icons/svg/color/btch.svg"
import { ReactComponent as Btcp } from "cryptocurrency-icons/svg/color/btcp.svg"
import { ReactComponent as Btcz } from "cryptocurrency-icons/svg/color/btcz.svg"
import { ReactComponent as Btdx } from "cryptocurrency-icons/svg/color/btdx.svg"
import { ReactComponent as Btg } from "cryptocurrency-icons/svg/color/btg.svg"
import { ReactComponent as Btm } from "cryptocurrency-icons/svg/color/btm.svg"
import { ReactComponent as Bts } from "cryptocurrency-icons/svg/color/bts.svg"
import { ReactComponent as Btt } from "cryptocurrency-icons/svg/color/btt.svg"
import { ReactComponent as Btx } from "cryptocurrency-icons/svg/color/btx.svg"
import { ReactComponent as Burst } from "cryptocurrency-icons/svg/color/burst.svg"
import { ReactComponent as Bze } from "cryptocurrency-icons/svg/color/bze.svg"
import { ReactComponent as Call } from "cryptocurrency-icons/svg/color/call.svg"
import { ReactComponent as Cc } from "cryptocurrency-icons/svg/color/cc.svg"
import { ReactComponent as Cdn } from "cryptocurrency-icons/svg/color/cdn.svg"
import { ReactComponent as Cdt } from "cryptocurrency-icons/svg/color/cdt.svg"
import { ReactComponent as Cenz } from "cryptocurrency-icons/svg/color/cenz.svg"
import { ReactComponent as Chain } from "cryptocurrency-icons/svg/color/chain.svg"
import { ReactComponent as Chat } from "cryptocurrency-icons/svg/color/chat.svg"
import { ReactComponent as Chips } from "cryptocurrency-icons/svg/color/chips.svg"
import { ReactComponent as Cix } from "cryptocurrency-icons/svg/color/cix.svg"
import { ReactComponent as Clam } from "cryptocurrency-icons/svg/color/clam.svg"
import { ReactComponent as Cloak } from "cryptocurrency-icons/svg/color/cloak.svg"
import { ReactComponent as Cmm } from "cryptocurrency-icons/svg/color/cmm.svg"
import { ReactComponent as Cmt } from "cryptocurrency-icons/svg/color/cmt.svg"
import { ReactComponent as Cnd } from "cryptocurrency-icons/svg/color/cnd.svg"
import { ReactComponent as Cnx } from "cryptocurrency-icons/svg/color/cnx.svg"
import { ReactComponent as Cny } from "cryptocurrency-icons/svg/color/cny.svg"
import { ReactComponent as Cob } from "cryptocurrency-icons/svg/color/cob.svg"
import { ReactComponent as Colx } from "cryptocurrency-icons/svg/color/colx.svg"
import { ReactComponent as Coqui } from "cryptocurrency-icons/svg/color/coqui.svg"
import { ReactComponent as Cred } from "cryptocurrency-icons/svg/color/cred.svg"
import { ReactComponent as Crpt } from "cryptocurrency-icons/svg/color/crpt.svg"
import { ReactComponent as Crw } from "cryptocurrency-icons/svg/color/crw.svg"
import { ReactComponent as Cs } from "cryptocurrency-icons/svg/color/cs.svg"
import { ReactComponent as Ctr } from "cryptocurrency-icons/svg/color/ctr.svg"
import { ReactComponent as Ctxc } from "cryptocurrency-icons/svg/color/ctxc.svg"
import { ReactComponent as Cvc } from "cryptocurrency-icons/svg/color/cvc.svg"
import { ReactComponent as D } from "cryptocurrency-icons/svg/color/d.svg"
import { ReactComponent as Dai } from "cryptocurrency-icons/svg/color/dai.svg"
import { ReactComponent as Dash } from "cryptocurrency-icons/svg/color/dash.svg"
import { ReactComponent as Dat } from "cryptocurrency-icons/svg/color/dat.svg"
import { ReactComponent as Data } from "cryptocurrency-icons/svg/color/data.svg"
import { ReactComponent as Dbc } from "cryptocurrency-icons/svg/color/dbc.svg"
import { ReactComponent as Dcn } from "cryptocurrency-icons/svg/color/dcn.svg"
import { ReactComponent as Dcr } from "cryptocurrency-icons/svg/color/dcr.svg"
import { ReactComponent as Deez } from "cryptocurrency-icons/svg/color/deez.svg"
import { ReactComponent as Dent } from "cryptocurrency-icons/svg/color/dent.svg"
import { ReactComponent as Dew } from "cryptocurrency-icons/svg/color/dew.svg"
import { ReactComponent as Dgb } from "cryptocurrency-icons/svg/color/dgb.svg"
import { ReactComponent as Dgd } from "cryptocurrency-icons/svg/color/dgd.svg"
import { ReactComponent as Dlt } from "cryptocurrency-icons/svg/color/dlt.svg"
import { ReactComponent as Dnt } from "cryptocurrency-icons/svg/color/dnt.svg"
import { ReactComponent as Dock } from "cryptocurrency-icons/svg/color/dock.svg"
import { ReactComponent as Doge } from "cryptocurrency-icons/svg/color/doge.svg"
import { ReactComponent as Drgn } from "cryptocurrency-icons/svg/color/drgn.svg"
import { ReactComponent as Drop } from "cryptocurrency-icons/svg/color/drop.svg"
import { ReactComponent as Dta } from "cryptocurrency-icons/svg/color/dta.svg"
import { ReactComponent as Dth } from "cryptocurrency-icons/svg/color/dth.svg"
import { ReactComponent as Dtr } from "cryptocurrency-icons/svg/color/dtr.svg"
import { ReactComponent as Ebst } from "cryptocurrency-icons/svg/color/ebst.svg"
import { ReactComponent as Eca } from "cryptocurrency-icons/svg/color/eca.svg"
import { ReactComponent as Edg } from "cryptocurrency-icons/svg/color/edg.svg"
import { ReactComponent as Edo } from "cryptocurrency-icons/svg/color/edo.svg"
import { ReactComponent as Edoge } from "cryptocurrency-icons/svg/color/edoge.svg"
import { ReactComponent as Ela } from "cryptocurrency-icons/svg/color/ela.svg"
import { ReactComponent as Elec } from "cryptocurrency-icons/svg/color/elec.svg"
import { ReactComponent as Elf } from "cryptocurrency-icons/svg/color/elf.svg"
import { ReactComponent as Elix } from "cryptocurrency-icons/svg/color/elix.svg"
import { ReactComponent as Ella } from "cryptocurrency-icons/svg/color/ella.svg"
import { ReactComponent as Emc } from "cryptocurrency-icons/svg/color/emc.svg"
import { ReactComponent as Emc2 } from "cryptocurrency-icons/svg/color/emc2.svg"
import { ReactComponent as Eng } from "cryptocurrency-icons/svg/color/eng.svg"
import { ReactComponent as Enj } from "cryptocurrency-icons/svg/color/enj.svg"
import { ReactComponent as Entrp } from "cryptocurrency-icons/svg/color/entrp.svg"
import { ReactComponent as Eon } from "cryptocurrency-icons/svg/color/eon.svg"
import { ReactComponent as Eop } from "cryptocurrency-icons/svg/color/eop.svg"
import { ReactComponent as Eos } from "cryptocurrency-icons/svg/color/eos.svg"
import { ReactComponent as Eqli } from "cryptocurrency-icons/svg/color/eqli.svg"
import { ReactComponent as Equa } from "cryptocurrency-icons/svg/color/equa.svg"
import { ReactComponent as Etc } from "cryptocurrency-icons/svg/color/etc.svg"
import { ReactComponent as Eth } from "cryptocurrency-icons/svg/color/eth.svg"
import { ReactComponent as Ethos } from "cryptocurrency-icons/svg/color/ethos.svg"
import { ReactComponent as Etn } from "cryptocurrency-icons/svg/color/etn.svg"
import { ReactComponent as Etp } from "cryptocurrency-icons/svg/color/etp.svg"
import { ReactComponent as Eur } from "cryptocurrency-icons/svg/color/eur.svg"
import { ReactComponent as Evx } from "cryptocurrency-icons/svg/color/evx.svg"
import { ReactComponent as Exmo } from "cryptocurrency-icons/svg/color/exmo.svg"
import { ReactComponent as Exp } from "cryptocurrency-icons/svg/color/exp.svg"
import { ReactComponent as Fair } from "cryptocurrency-icons/svg/color/fair.svg"
import { ReactComponent as Fct } from "cryptocurrency-icons/svg/color/fct.svg"
import { ReactComponent as Fil } from "cryptocurrency-icons/svg/color/fil.svg"
import { ReactComponent as Fjc } from "cryptocurrency-icons/svg/color/fjc.svg"
import { ReactComponent as Fldc } from "cryptocurrency-icons/svg/color/fldc.svg"
import { ReactComponent as Flo } from "cryptocurrency-icons/svg/color/flo.svg"
import { ReactComponent as Fsn } from "cryptocurrency-icons/svg/color/fsn.svg"
import { ReactComponent as Ftc } from "cryptocurrency-icons/svg/color/ftc.svg"
import { ReactComponent as Fuel } from "cryptocurrency-icons/svg/color/fuel.svg"
import { ReactComponent as Fun } from "cryptocurrency-icons/svg/color/fun.svg"
import { ReactComponent as Game } from "cryptocurrency-icons/svg/color/game.svg"
import { ReactComponent as Gas } from "cryptocurrency-icons/svg/color/gas.svg"
import { ReactComponent as Gbp } from "cryptocurrency-icons/svg/color/gbp.svg"
import { ReactComponent as Gbx } from "cryptocurrency-icons/svg/color/gbx.svg"
import { ReactComponent as Gbyte } from "cryptocurrency-icons/svg/color/gbyte.svg"
import { ReactComponent as Generic } from "cryptocurrency-icons/svg/color/generic.svg"
import { ReactComponent as Gin } from "cryptocurrency-icons/svg/color/gin.svg"
import { ReactComponent as Glxt } from "cryptocurrency-icons/svg/color/glxt.svg"
import { ReactComponent as Gmr } from "cryptocurrency-icons/svg/color/gmr.svg"
import { ReactComponent as Gno } from "cryptocurrency-icons/svg/color/gno.svg"
import { ReactComponent as Gnt } from "cryptocurrency-icons/svg/color/gnt.svg"
import { ReactComponent as Gold } from "cryptocurrency-icons/svg/color/gold.svg"
import { ReactComponent as Grc } from "cryptocurrency-icons/svg/color/grc.svg"
import { ReactComponent as Grin } from "cryptocurrency-icons/svg/color/grin.svg"
import { ReactComponent as Grs } from "cryptocurrency-icons/svg/color/grs.svg"
import { ReactComponent as Gsc } from "cryptocurrency-icons/svg/color/gsc.svg"
import { ReactComponent as Gto } from "cryptocurrency-icons/svg/color/gto.svg"
import { ReactComponent as Gup } from "cryptocurrency-icons/svg/color/gup.svg"
import { ReactComponent as Gusd } from "cryptocurrency-icons/svg/color/gusd.svg"
import { ReactComponent as Gvt } from "cryptocurrency-icons/svg/color/gvt.svg"
import { ReactComponent as Gxs } from "cryptocurrency-icons/svg/color/gxs.svg"
import { ReactComponent as Gzr } from "cryptocurrency-icons/svg/color/gzr.svg"
import { ReactComponent as Hight } from "cryptocurrency-icons/svg/color/hight.svg"
import { ReactComponent as Hodl } from "cryptocurrency-icons/svg/color/hodl.svg"
import { ReactComponent as Hot } from "cryptocurrency-icons/svg/color/hot.svg"
import { ReactComponent as Hpb } from "cryptocurrency-icons/svg/color/hpb.svg"
import { ReactComponent as Hsr } from "cryptocurrency-icons/svg/color/hsr.svg"
import { ReactComponent as Ht } from "cryptocurrency-icons/svg/color/ht.svg"
import { ReactComponent as Html } from "cryptocurrency-icons/svg/color/html.svg"
import { ReactComponent as Huc } from "cryptocurrency-icons/svg/color/huc.svg"
import { ReactComponent as Hush } from "cryptocurrency-icons/svg/color/hush.svg"
import { ReactComponent as Icn } from "cryptocurrency-icons/svg/color/icn.svg"
import { ReactComponent as Icx } from "cryptocurrency-icons/svg/color/icx.svg"
import { ReactComponent as Ignis } from "cryptocurrency-icons/svg/color/ignis.svg"
import { ReactComponent as Ilk } from "cryptocurrency-icons/svg/color/ilk.svg"
import { ReactComponent as Ink } from "cryptocurrency-icons/svg/color/ink.svg"
import { ReactComponent as Ins } from "cryptocurrency-icons/svg/color/ins.svg"
import { ReactComponent as Ion } from "cryptocurrency-icons/svg/color/ion.svg"
import { ReactComponent as Iop } from "cryptocurrency-icons/svg/color/iop.svg"
import { ReactComponent as Iost } from "cryptocurrency-icons/svg/color/iost.svg"
import { ReactComponent as Iotx } from "cryptocurrency-icons/svg/color/iotx.svg"
import { ReactComponent as Iq } from "cryptocurrency-icons/svg/color/iq.svg"
import { ReactComponent as Itc } from "cryptocurrency-icons/svg/color/itc.svg"
import { ReactComponent as Jnt } from "cryptocurrency-icons/svg/color/jnt.svg"
import { ReactComponent as Jpy } from "cryptocurrency-icons/svg/color/jpy.svg"
import { ReactComponent as Kcs } from "cryptocurrency-icons/svg/color/kcs.svg"
import { ReactComponent as Kin } from "cryptocurrency-icons/svg/color/kin.svg"
import { ReactComponent as Klown } from "cryptocurrency-icons/svg/color/klown.svg"
import { ReactComponent as Kmd } from "cryptocurrency-icons/svg/color/kmd.svg"
import { ReactComponent as Knc } from "cryptocurrency-icons/svg/color/knc.svg"
import { ReactComponent as Krb } from "cryptocurrency-icons/svg/color/krb.svg"
import { ReactComponent as Lbc } from "cryptocurrency-icons/svg/color/lbc.svg"
import { ReactComponent as Lend } from "cryptocurrency-icons/svg/color/lend.svg"
import { ReactComponent as Leo } from "cryptocurrency-icons/svg/color/leo.svg"
import { ReactComponent as Link } from "cryptocurrency-icons/svg/color/link.svg"
import { ReactComponent as Lkk } from "cryptocurrency-icons/svg/color/lkk.svg"
import { ReactComponent as Loom } from "cryptocurrency-icons/svg/color/loom.svg"
import { ReactComponent as Lpt } from "cryptocurrency-icons/svg/color/lpt.svg"
import { ReactComponent as Lrc } from "cryptocurrency-icons/svg/color/lrc.svg"
import { ReactComponent as Lsk } from "cryptocurrency-icons/svg/color/lsk.svg"
import { ReactComponent as Ltc } from "cryptocurrency-icons/svg/color/ltc.svg"
import { ReactComponent as Lun } from "cryptocurrency-icons/svg/color/lun.svg"
import { ReactComponent as Maid } from "cryptocurrency-icons/svg/color/maid.svg"
import { ReactComponent as Mana } from "cryptocurrency-icons/svg/color/mana.svg"
import { ReactComponent as Matic } from "cryptocurrency-icons/svg/color/matic.svg"
import { ReactComponent as Mcap } from "cryptocurrency-icons/svg/color/mcap.svg"
import { ReactComponent as Mco } from "cryptocurrency-icons/svg/color/mco.svg"
import { ReactComponent as Mda } from "cryptocurrency-icons/svg/color/mda.svg"
import { ReactComponent as Mds } from "cryptocurrency-icons/svg/color/mds.svg"
import { ReactComponent as Med } from "cryptocurrency-icons/svg/color/med.svg"
import { ReactComponent as Meetone } from "cryptocurrency-icons/svg/color/meetone.svg"
import { ReactComponent as Mft } from "cryptocurrency-icons/svg/color/mft.svg"
import { ReactComponent as Miota } from "cryptocurrency-icons/svg/color/miota.svg"
import { ReactComponent as Mith } from "cryptocurrency-icons/svg/color/mith.svg"
import { ReactComponent as Mkr } from "cryptocurrency-icons/svg/color/mkr.svg"
import { ReactComponent as Mln } from "cryptocurrency-icons/svg/color/mln.svg"
import { ReactComponent as Mnx } from "cryptocurrency-icons/svg/color/mnx.svg"
import { ReactComponent as Mnz } from "cryptocurrency-icons/svg/color/mnz.svg"
import { ReactComponent as Moac } from "cryptocurrency-icons/svg/color/moac.svg"
import { ReactComponent as Mod } from "cryptocurrency-icons/svg/color/mod.svg"
import { ReactComponent as Mona } from "cryptocurrency-icons/svg/color/mona.svg"
import { ReactComponent as Msr } from "cryptocurrency-icons/svg/color/msr.svg"
import { ReactComponent as Mth } from "cryptocurrency-icons/svg/color/mth.svg"
import { ReactComponent as Mtl } from "cryptocurrency-icons/svg/color/mtl.svg"
import { ReactComponent as Music } from "cryptocurrency-icons/svg/color/music.svg"
import { ReactComponent as Mzc } from "cryptocurrency-icons/svg/color/mzc.svg"
import { ReactComponent as Nano } from "cryptocurrency-icons/svg/color/nano.svg"
import { ReactComponent as Nas } from "cryptocurrency-icons/svg/color/nas.svg"
import { ReactComponent as Nav } from "cryptocurrency-icons/svg/color/nav.svg"
import { ReactComponent as Ncash } from "cryptocurrency-icons/svg/color/ncash.svg"
import { ReactComponent as Ndz } from "cryptocurrency-icons/svg/color/ndz.svg"
import { ReactComponent as Nebl } from "cryptocurrency-icons/svg/color/nebl.svg"
import { ReactComponent as Neo } from "cryptocurrency-icons/svg/color/neo.svg"
import { ReactComponent as Neos } from "cryptocurrency-icons/svg/color/neos.svg"
import { ReactComponent as Neu } from "cryptocurrency-icons/svg/color/neu.svg"
import { ReactComponent as Nexo } from "cryptocurrency-icons/svg/color/nexo.svg"
import { ReactComponent as Ngc } from "cryptocurrency-icons/svg/color/ngc.svg"
import { ReactComponent as Nio } from "cryptocurrency-icons/svg/color/nio.svg"
import { ReactComponent as Nlc2 } from "cryptocurrency-icons/svg/color/nlc2.svg"
import { ReactComponent as Nlg } from "cryptocurrency-icons/svg/color/nlg.svg"
import { ReactComponent as Nmc } from "cryptocurrency-icons/svg/color/nmc.svg"
import { ReactComponent as Npxs } from "cryptocurrency-icons/svg/color/npxs.svg"
import { ReactComponent as Nuls } from "cryptocurrency-icons/svg/color/nuls.svg"
import { ReactComponent as Nxs } from "cryptocurrency-icons/svg/color/nxs.svg"
import { ReactComponent as Nxt } from "cryptocurrency-icons/svg/color/nxt.svg"
import { ReactComponent as Oax } from "cryptocurrency-icons/svg/color/oax.svg"
import { ReactComponent as Ok } from "cryptocurrency-icons/svg/color/ok.svg"
import { ReactComponent as Omg } from "cryptocurrency-icons/svg/color/omg.svg"
import { ReactComponent as Omni } from "cryptocurrency-icons/svg/color/omni.svg"
import { ReactComponent as Ong } from "cryptocurrency-icons/svg/color/ong.svg"
import { ReactComponent as Ont } from "cryptocurrency-icons/svg/color/ont.svg"
import { ReactComponent as Oot } from "cryptocurrency-icons/svg/color/oot.svg"
import { ReactComponent as Ost } from "cryptocurrency-icons/svg/color/ost.svg"
import { ReactComponent as Ox } from "cryptocurrency-icons/svg/color/ox.svg"
import { ReactComponent as Part } from "cryptocurrency-icons/svg/color/part.svg"
import { ReactComponent as Pasc } from "cryptocurrency-icons/svg/color/pasc.svg"
import { ReactComponent as Pasl } from "cryptocurrency-icons/svg/color/pasl.svg"
import { ReactComponent as Pax } from "cryptocurrency-icons/svg/color/pax.svg"
import { ReactComponent as Pay } from "cryptocurrency-icons/svg/color/pay.svg"
import { ReactComponent as Payx } from "cryptocurrency-icons/svg/color/payx.svg"
import { ReactComponent as Pink } from "cryptocurrency-icons/svg/color/pink.svg"
import { ReactComponent as Pirl } from "cryptocurrency-icons/svg/color/pirl.svg"
import { ReactComponent as Pivx } from "cryptocurrency-icons/svg/color/pivx.svg"
import { ReactComponent as Plr } from "cryptocurrency-icons/svg/color/plr.svg"
import { ReactComponent as Poa } from "cryptocurrency-icons/svg/color/poa.svg"
import { ReactComponent as Poe } from "cryptocurrency-icons/svg/color/poe.svg"
import { ReactComponent as Polis } from "cryptocurrency-icons/svg/color/polis.svg"
import { ReactComponent as Poly } from "cryptocurrency-icons/svg/color/poly.svg"
import { ReactComponent as Pot } from "cryptocurrency-icons/svg/color/pot.svg"
import { ReactComponent as Powr } from "cryptocurrency-icons/svg/color/powr.svg"
import { ReactComponent as Ppc } from "cryptocurrency-icons/svg/color/ppc.svg"
import { ReactComponent as Ppp } from "cryptocurrency-icons/svg/color/ppp.svg"
import { ReactComponent as Ppt } from "cryptocurrency-icons/svg/color/ppt.svg"
import { ReactComponent as Pre } from "cryptocurrency-icons/svg/color/pre.svg"
import { ReactComponent as Prl } from "cryptocurrency-icons/svg/color/prl.svg"
import { ReactComponent as Pungo } from "cryptocurrency-icons/svg/color/pungo.svg"
import { ReactComponent as Pura } from "cryptocurrency-icons/svg/color/pura.svg"
import { ReactComponent as Qash } from "cryptocurrency-icons/svg/color/qash.svg"
import { ReactComponent as Qiwi } from "cryptocurrency-icons/svg/color/qiwi.svg"
import { ReactComponent as Qlc } from "cryptocurrency-icons/svg/color/qlc.svg"
import { ReactComponent as Qrl } from "cryptocurrency-icons/svg/color/qrl.svg"
import { ReactComponent as Qsp } from "cryptocurrency-icons/svg/color/qsp.svg"
import { ReactComponent as Qtum } from "cryptocurrency-icons/svg/color/qtum.svg"
import { ReactComponent as R } from "cryptocurrency-icons/svg/color/r.svg"
import { ReactComponent as Rads } from "cryptocurrency-icons/svg/color/rads.svg"
import { ReactComponent as Rap } from "cryptocurrency-icons/svg/color/rap.svg"
import { ReactComponent as Rcn } from "cryptocurrency-icons/svg/color/rcn.svg"
import { ReactComponent as Rdd } from "cryptocurrency-icons/svg/color/rdd.svg"
import { ReactComponent as Rdn } from "cryptocurrency-icons/svg/color/rdn.svg"
import { ReactComponent as Ren } from "cryptocurrency-icons/svg/color/ren.svg"
import { ReactComponent as Rep } from "cryptocurrency-icons/svg/color/rep.svg"
import { ReactComponent as Req } from "cryptocurrency-icons/svg/color/req.svg"
import { ReactComponent as Rhoc } from "cryptocurrency-icons/svg/color/rhoc.svg"
import { ReactComponent as Ric } from "cryptocurrency-icons/svg/color/ric.svg"
import { ReactComponent as Rise } from "cryptocurrency-icons/svg/color/rise.svg"
import { ReactComponent as Rlc } from "cryptocurrency-icons/svg/color/rlc.svg"
import { ReactComponent as Rpx } from "cryptocurrency-icons/svg/color/rpx.svg"
import { ReactComponent as Rub } from "cryptocurrency-icons/svg/color/rub.svg"
import { ReactComponent as Rvn } from "cryptocurrency-icons/svg/color/rvn.svg"
import { ReactComponent as Ryo } from "cryptocurrency-icons/svg/color/ryo.svg"
import { ReactComponent as Safe } from "cryptocurrency-icons/svg/color/safe.svg"
import { ReactComponent as Salt } from "cryptocurrency-icons/svg/color/salt.svg"
import { ReactComponent as San } from "cryptocurrency-icons/svg/color/san.svg"
import { ReactComponent as Sbd } from "cryptocurrency-icons/svg/color/sbd.svg"
import { ReactComponent as Sberbank } from "cryptocurrency-icons/svg/color/sberbank.svg"
import { ReactComponent as Sc } from "cryptocurrency-icons/svg/color/sc.svg"
import { ReactComponent as Shift } from "cryptocurrency-icons/svg/color/shift.svg"
import { ReactComponent as Sib } from "cryptocurrency-icons/svg/color/sib.svg"
import { ReactComponent as Sin } from "cryptocurrency-icons/svg/color/sin.svg"
import { ReactComponent as Sky } from "cryptocurrency-icons/svg/color/sky.svg"
import { ReactComponent as Slr } from "cryptocurrency-icons/svg/color/slr.svg"
import { ReactComponent as Sls } from "cryptocurrency-icons/svg/color/sls.svg"
import { ReactComponent as Smart } from "cryptocurrency-icons/svg/color/smart.svg"
import { ReactComponent as Sngls } from "cryptocurrency-icons/svg/color/sngls.svg"
import { ReactComponent as Snm } from "cryptocurrency-icons/svg/color/snm.svg"
import { ReactComponent as Snt } from "cryptocurrency-icons/svg/color/snt.svg"
import { ReactComponent as Soc } from "cryptocurrency-icons/svg/color/soc.svg"
import { ReactComponent as Spank } from "cryptocurrency-icons/svg/color/spank.svg"
import { ReactComponent as Sphtx } from "cryptocurrency-icons/svg/color/sphtx.svg"
import { ReactComponent as Srn } from "cryptocurrency-icons/svg/color/srn.svg"
import { ReactComponent as Stak } from "cryptocurrency-icons/svg/color/stak.svg"
import { ReactComponent as Start } from "cryptocurrency-icons/svg/color/start.svg"
import { ReactComponent as Steem } from "cryptocurrency-icons/svg/color/steem.svg"
import { ReactComponent as Storj } from "cryptocurrency-icons/svg/color/storj.svg"
import { ReactComponent as Storm } from "cryptocurrency-icons/svg/color/storm.svg"
import { ReactComponent as Stq } from "cryptocurrency-icons/svg/color/stq.svg"
import { ReactComponent as Strat } from "cryptocurrency-icons/svg/color/strat.svg"
import { ReactComponent as Sub } from "cryptocurrency-icons/svg/color/sub.svg"
import { ReactComponent as Sumo } from "cryptocurrency-icons/svg/color/sumo.svg"
import { ReactComponent as Sys } from "cryptocurrency-icons/svg/color/sys.svg"
import { ReactComponent as Taas } from "cryptocurrency-icons/svg/color/taas.svg"
import { ReactComponent as Tau } from "cryptocurrency-icons/svg/color/tau.svg"
import { ReactComponent as Tbx } from "cryptocurrency-icons/svg/color/tbx.svg"
import { ReactComponent as Tel } from "cryptocurrency-icons/svg/color/tel.svg"
import { ReactComponent as Ten } from "cryptocurrency-icons/svg/color/ten.svg"
import { ReactComponent as Tern } from "cryptocurrency-icons/svg/color/tern.svg"
import { ReactComponent as Tgch } from "cryptocurrency-icons/svg/color/tgch.svg"
import { ReactComponent as Theta } from "cryptocurrency-icons/svg/color/theta.svg"
import { ReactComponent as Tix } from "cryptocurrency-icons/svg/color/tix.svg"
import { ReactComponent as Tkn } from "cryptocurrency-icons/svg/color/tkn.svg"
import { ReactComponent as Tks } from "cryptocurrency-icons/svg/color/tks.svg"
import { ReactComponent as Tnb } from "cryptocurrency-icons/svg/color/tnb.svg"
import { ReactComponent as Tnc } from "cryptocurrency-icons/svg/color/tnc.svg"
import { ReactComponent as Tnt } from "cryptocurrency-icons/svg/color/tnt.svg"
import { ReactComponent as Tomo } from "cryptocurrency-icons/svg/color/tomo.svg"
import { ReactComponent as Tpay } from "cryptocurrency-icons/svg/color/tpay.svg"
import { ReactComponent as Trig } from "cryptocurrency-icons/svg/color/trig.svg"
import { ReactComponent as Trtl } from "cryptocurrency-icons/svg/color/trtl.svg"
import { ReactComponent as Trx } from "cryptocurrency-icons/svg/color/trx.svg"
import { ReactComponent as Tusd } from "cryptocurrency-icons/svg/color/tusd.svg"
import { ReactComponent as Tzc } from "cryptocurrency-icons/svg/color/tzc.svg"
import { ReactComponent as Ubq } from "cryptocurrency-icons/svg/color/ubq.svg"
import { ReactComponent as Unity } from "cryptocurrency-icons/svg/color/unity.svg"
import { ReactComponent as Usd } from "cryptocurrency-icons/svg/color/usd.svg"
import { ReactComponent as Usdc } from "cryptocurrency-icons/svg/color/usdc.svg"
import { ReactComponent as Usdt } from "cryptocurrency-icons/svg/color/usdt.svg"
import { ReactComponent as Utk } from "cryptocurrency-icons/svg/color/utk.svg"
import { ReactComponent as Veri } from "cryptocurrency-icons/svg/color/veri.svg"
import { ReactComponent as Vet } from "cryptocurrency-icons/svg/color/vet.svg"
import { ReactComponent as Via } from "cryptocurrency-icons/svg/color/via.svg"
import { ReactComponent as Vib } from "cryptocurrency-icons/svg/color/vib.svg"
import { ReactComponent as Vibe } from "cryptocurrency-icons/svg/color/vibe.svg"
import { ReactComponent as Vivo } from "cryptocurrency-icons/svg/color/vivo.svg"
import { ReactComponent as Vrc } from "cryptocurrency-icons/svg/color/vrc.svg"
import { ReactComponent as Vrsc } from "cryptocurrency-icons/svg/color/vrsc.svg"
import { ReactComponent as Vtc } from "cryptocurrency-icons/svg/color/vtc.svg"
import { ReactComponent as Vtho } from "cryptocurrency-icons/svg/color/vtho.svg"
import { ReactComponent as Wabi } from "cryptocurrency-icons/svg/color/wabi.svg"
import { ReactComponent as Wan } from "cryptocurrency-icons/svg/color/wan.svg"
import { ReactComponent as Waves } from "cryptocurrency-icons/svg/color/waves.svg"
import { ReactComponent as Wax } from "cryptocurrency-icons/svg/color/wax.svg"
import { ReactComponent as Wgr } from "cryptocurrency-icons/svg/color/wgr.svg"
import { ReactComponent as Wicc } from "cryptocurrency-icons/svg/color/wicc.svg"
import { ReactComponent as Wings } from "cryptocurrency-icons/svg/color/wings.svg"
import { ReactComponent as Wpr } from "cryptocurrency-icons/svg/color/wpr.svg"
import { ReactComponent as Wtc } from "cryptocurrency-icons/svg/color/wtc.svg"
import { ReactComponent as X } from "cryptocurrency-icons/svg/color/x.svg"
import { ReactComponent as Xas } from "cryptocurrency-icons/svg/color/xas.svg"
import { ReactComponent as Xbc } from "cryptocurrency-icons/svg/color/xbc.svg"
import { ReactComponent as Xbp } from "cryptocurrency-icons/svg/color/xbp.svg"
import { ReactComponent as Xby } from "cryptocurrency-icons/svg/color/xby.svg"
import { ReactComponent as Xcp } from "cryptocurrency-icons/svg/color/xcp.svg"
import { ReactComponent as Xdn } from "cryptocurrency-icons/svg/color/xdn.svg"
import { ReactComponent as Xem } from "cryptocurrency-icons/svg/color/xem.svg"
import { ReactComponent as Xin } from "cryptocurrency-icons/svg/color/xin.svg"
import { ReactComponent as Xlm } from "cryptocurrency-icons/svg/color/xlm.svg"
import { ReactComponent as Xmcc } from "cryptocurrency-icons/svg/color/xmcc.svg"
import { ReactComponent as Xmg } from "cryptocurrency-icons/svg/color/xmg.svg"
import { ReactComponent as Xmo } from "cryptocurrency-icons/svg/color/xmo.svg"
import { ReactComponent as Xmr } from "cryptocurrency-icons/svg/color/xmr.svg"
import { ReactComponent as Xmy } from "cryptocurrency-icons/svg/color/xmy.svg"
import { ReactComponent as Xp } from "cryptocurrency-icons/svg/color/xp.svg"
import { ReactComponent as Xpa } from "cryptocurrency-icons/svg/color/xpa.svg"
import { ReactComponent as Xpm } from "cryptocurrency-icons/svg/color/xpm.svg"
import { ReactComponent as Xrp } from "cryptocurrency-icons/svg/color/xrp.svg"
import { ReactComponent as Xsg } from "cryptocurrency-icons/svg/color/xsg.svg"
import { ReactComponent as Xtz } from "cryptocurrency-icons/svg/color/xtz.svg"
import { ReactComponent as Xuc } from "cryptocurrency-icons/svg/color/xuc.svg"
import { ReactComponent as Xvc } from "cryptocurrency-icons/svg/color/xvc.svg"
import { ReactComponent as Xvg } from "cryptocurrency-icons/svg/color/xvg.svg"
import { ReactComponent as Xzc } from "cryptocurrency-icons/svg/color/xzc.svg"
import { ReactComponent as Yoyow } from "cryptocurrency-icons/svg/color/yoyow.svg"
import { ReactComponent as Zcl } from "cryptocurrency-icons/svg/color/zcl.svg"
import { ReactComponent as Zec } from "cryptocurrency-icons/svg/color/zec.svg"
import { ReactComponent as Zel } from "cryptocurrency-icons/svg/color/zel.svg"
import { ReactComponent as Zen } from "cryptocurrency-icons/svg/color/zen.svg"
import { ReactComponent as Zest } from "cryptocurrency-icons/svg/color/zest.svg"
import { ReactComponent as Zil } from "cryptocurrency-icons/svg/color/zil.svg"
import { ReactComponent as Zilla } from "cryptocurrency-icons/svg/color/zilla.svg"
import { ReactComponent as Zrx } from "cryptocurrency-icons/svg/color/zrx.svg"

export const CoinIcon = (props) => {

  switch (props.coinSymbol) {
    // exchanges
    case "coinbase":
      return <Coinbase {...props} />
    case "kraken":
      return <Kraken {...props} />
    case "binance":
      return <Binance {...props} />

    // coins
    case "$pac":
      return <Pac {...props} />
    case "0xbtc":
      return <ZeroXbtc {...props} />
    case "2give":
      return <Twogive {...props} />
    case "abt":
      return <Abt {...props} />
    case "act":
      return <Act {...props} />
    case "actn":
      return <Actn {...props} />
    case "ada":
      return <Ada {...props} />
    case "add":
      return <Add {...props} />
    case "adx":
      return <Adx {...props} />
    case "ae":
      return <Ae {...props} />
    case "aeon":
      return <Aeon {...props} />
    case "aeur":
      return <Aeur {...props} />
    case "agi":
      return <Agi {...props} />
    case "agrs":
      return <Agrs {...props} />
    case "aion":
      return <Aion {...props} />
    case "amb":
      return <Amb {...props} />
    case "amp":
      return <Amp {...props} />
    case "ampl":
      return <Ampl {...props} />
    case "ant":
      return <Ant {...props} />
    case "apex":
      return <Apex {...props} />
    case "appc":
      return <Appc {...props} />
    case "ardr":
      return <Ardr {...props} />
    case "arg":
      return <Arg {...props} />
    case "ark":
      return <Ark {...props} />
    case "arn":
      return <Arn {...props} />
    case "ary":
      return <Ary {...props} />
    case "ast":
      return <Ast {...props} />
    case "atm":
      return <Atm {...props} />
    case "atom":
      return <Atom {...props} />
    case "audr":
      return <Audr {...props} />
    case "auto":
      return <Auto {...props} />
    case "aywa":
      return <Aywa {...props} />
    case "bab":
      return <Bab {...props} />
    case "bat":
      return <Bat {...props} />
    case "bay":
      return <Bay {...props} />
    case "bcbc":
      return <Bcbc {...props} />
    case "bcc":
      return <Bcc {...props} />
    case "bcd":
      return <Bcd {...props} />
    case "bch":
      return <Bch {...props} />
    case "bcio":
      return <Bcio {...props} />
    case "bcn":
      return <Bcn {...props} />
    case "bco":
      return <Bco {...props} />
    case "bcpt":
      return <Bcpt {...props} />
    case "bdl":
      return <Bdl {...props} />
    case "beam":
      return <Beam {...props} />
    case "bela":
      return <Bela {...props} />
    case "bix":
      return <Bix {...props} />
    case "blcn":
      return <Blcn {...props} />
    case "blk":
      return <Blk {...props} />
    case "block":
      return <Block {...props} />
    case "blz":
      return <Blz {...props} />
    case "bnb":
      return <Bnb {...props} />
    case "bnt":
      return <Bnt {...props} />
    case "bnty":
      return <Bnty {...props} />
    case "booty":
      return <Booty {...props} />
    case "bos":
      return <Bos {...props} />
    case "bpt":
      return <Bpt {...props} />
    case "bq":
      return <Bq {...props} />
    case "brd":
      return <Brd {...props} />
    case "bsd":
      return <Bsd {...props} />
    case "bsv":
      return <Bsv {...props} />
    case "btc":
      return <Btc {...props} />
    case "btcd":
      return <Btcd {...props} />
    case "btch":
      return <Btch {...props} />
    case "btcp":
      return <Btcp {...props} />
    case "btcz":
      return <Btcz {...props} />
    case "btdx":
      return <Btdx {...props} />
    case "btg":
      return <Btg {...props} />
    case "btm":
      return <Btm {...props} />
    case "bts":
      return <Bts {...props} />
    case "btt":
      return <Btt {...props} />
    case "btx":
      return <Btx {...props} />
    case "burst":
      return <Burst {...props} />
    case "bze":
      return <Bze {...props} />
    case "call":
      return <Call {...props} />
    case "cc":
      return <Cc {...props} />
    case "cdn":
      return <Cdn {...props} />
    case "cdt":
      return <Cdt {...props} />
    case "cenz":
      return <Cenz {...props} />
    case "chain":
      return <Chain {...props} />
    case "chat":
      return <Chat {...props} />
    case "chips":
      return <Chips {...props} />
    case "cix":
      return <Cix {...props} />
    case "clam":
      return <Clam {...props} />
    case "cloak":
      return <Cloak {...props} />
    case "cmm":
      return <Cmm {...props} />
    case "cmt":
      return <Cmt {...props} />
    case "cnd":
      return <Cnd {...props} />
    case "cnx":
      return <Cnx {...props} />
    case "cny":
      return <Cny {...props} />
    case "cob":
      return <Cob {...props} />
    case "colx":
      return <Colx {...props} />
    case "coqui":
      return <Coqui {...props} />
    case "cred":
      return <Cred {...props} />
    case "crpt":
      return <Crpt {...props} />
    case "crw":
      return <Crw {...props} />
    case "cs":
      return <Cs {...props} />
    case "ctr":
      return <Ctr {...props} />
    case "ctxc":
      return <Ctxc {...props} />
    case "cvc":
      return <Cvc {...props} />
    case "d":
      return <D {...props} />
    case "dai":
      return <Dai {...props} />
    case "dash":
      return <Dash {...props} />
    case "dat":
      return <Dat {...props} />
    case "data":
      return <Data {...props} />
    case "dbc":
      return <Dbc {...props} />
    case "dcn":
      return <Dcn {...props} />
    case "dcr":
      return <Dcr {...props} />
    case "deez":
      return <Deez {...props} />
    case "dent":
      return <Dent {...props} />
    case "dew":
      return <Dew {...props} />
    case "dgb":
      return <Dgb {...props} />
    case "dgd":
      return <Dgd {...props} />
    case "dlt":
      return <Dlt {...props} />
    case "dnt":
      return <Dnt {...props} />
    case "dock":
      return <Dock {...props} />
    case "doge":
      return <Doge {...props} />
    case "drgn":
      return <Drgn {...props} />
    case "drop":
      return <Drop {...props} />
    case "dta":
      return <Dta {...props} />
    case "dth":
      return <Dth {...props} />
    case "dtr":
      return <Dtr {...props} />
    case "ebst":
      return <Ebst {...props} />
    case "eca":
      return <Eca {...props} />
    case "edg":
      return <Edg {...props} />
    case "edo":
      return <Edo {...props} />
    case "edoge":
      return <Edoge {...props} />
    case "ela":
      return <Ela {...props} />
    case "elec":
      return <Elec {...props} />
    case "elf":
      return <Elf {...props} />
    case "elix":
      return <Elix {...props} />
    case "ella":
      return <Ella {...props} />
    case "emc":
      return <Emc {...props} />
    case "emc2":
      return <Emc2 {...props} />
    case "eng":
      return <Eng {...props} />
    case "enj":
      return <Enj {...props} />
    case "entrp":
      return <Entrp {...props} />
    case "eon":
      return <Eon {...props} />
    case "eop":
      return <Eop {...props} />
    case "eos":
      return <Eos {...props} />
    case "eqli":
      return <Eqli {...props} />
    case "equa":
      return <Equa {...props} />
    case "etc":
      return <Etc {...props} />
    case "eth":
      return <Eth {...props} />
    case "ethos":
      return <Ethos {...props} />
    case "etn":
      return <Etn {...props} />
    case "etp":
      return <Etp {...props} />
    case "eur":
      return <Eur {...props} />
    case "evx":
      return <Evx {...props} />
    case "exmo":
      return <Exmo {...props} />
    case "exp":
      return <Exp {...props} />
    case "fair":
      return <Fair {...props} />
    case "fct":
      return <Fct {...props} />
    case "fil":
      return <Fil {...props} />
    case "fjc":
      return <Fjc {...props} />
    case "fldc":
      return <Fldc {...props} />
    case "flo":
      return <Flo {...props} />
    case "fsn":
      return <Fsn {...props} />
    case "ftc":
      return <Ftc {...props} />
    case "fuel":
      return <Fuel {...props} />
    case "fun":
      return <Fun {...props} />
    case "game":
      return <Game {...props} />
    case "gas":
      return <Gas {...props} />
    case "gbp":
      return <Gbp {...props} />
    case "gbx":
      return <Gbx {...props} />
    case "gbyte":
      return <Gbyte {...props} />
    case "generic":
      return <Generic {...props} />
    case "gin":
      return <Gin {...props} />
    case "glxt":
      return <Glxt {...props} />
    case "gmr":
      return <Gmr {...props} />
    case "gno":
      return <Gno {...props} />
    case "gnt":
      return <Gnt {...props} />
    case "gold":
      return <Gold {...props} />
    case "grc":
      return <Grc {...props} />
    case "grin":
      return <Grin {...props} />
    case "grs":
      return <Grs {...props} />
    case "gsc":
      return <Gsc {...props} />
    case "gto":
      return <Gto {...props} />
    case "gup":
      return <Gup {...props} />
    case "gusd":
      return <Gusd {...props} />
    case "gvt":
      return <Gvt {...props} />
    case "gxs":
      return <Gxs {...props} />
    case "gzr":
      return <Gzr {...props} />
    case "hight":
      return <Hight {...props} />
    case "hodl":
      return <Hodl {...props} />
    case "hot":
      return <Hot {...props} />
    case "hpb":
      return <Hpb {...props} />
    case "hsr":
      return <Hsr {...props} />
    case "ht":
      return <Ht {...props} />
    case "html":
      return <Html {...props} />
    case "huc":
      return <Huc {...props} />
    case "hush":
      return <Hush {...props} />
    case "icn":
      return <Icn {...props} />
    case "icx":
      return <Icx {...props} />
    case "ignis":
      return <Ignis {...props} />
    case "ilk":
      return <Ilk {...props} />
    case "ink":
      return <Ink {...props} />
    case "ins":
      return <Ins {...props} />
    case "ion":
      return <Ion {...props} />
    case "iop":
      return <Iop {...props} />
    case "iost":
      return <Iost {...props} />
    case "iotx":
      return <Iotx {...props} />
    case "iq":
      return <Iq {...props} />
    case "itc":
      return <Itc {...props} />
    case "jnt":
      return <Jnt {...props} />
    case "jpy":
      return <Jpy {...props} />
    case "kcs":
      return <Kcs {...props} />
    case "kin":
      return <Kin {...props} />
    case "klown":
      return <Klown {...props} />
    case "kmd":
      return <Kmd {...props} />
    case "knc":
      return <Knc {...props} />
    case "krb":
      return <Krb {...props} />
    case "lbc":
      return <Lbc {...props} />
    case "lend":
      return <Lend {...props} />
    case "leo":
      return <Leo {...props} />
    case "link":
      return <Link {...props} />
    case "lkk":
      return <Lkk {...props} />
    case "loom":
      return <Loom {...props} />
    case "lpt":
      return <Lpt {...props} />
    case "lrc":
      return <Lrc {...props} />
    case "lsk":
      return <Lsk {...props} />
    case "ltc":
      return <Ltc {...props} />
    case "lun":
      return <Lun {...props} />
    case "maid":
      return <Maid {...props} />
    case "mana":
      return <Mana {...props} />
    case "matic":
      return <Matic {...props} />
    case "mcap":
      return <Mcap {...props} />
    case "mco":
      return <Mco {...props} />
    case "mda":
      return <Mda {...props} />
    case "mds":
      return <Mds {...props} />
    case "med":
      return <Med {...props} />
    case "meetone":
      return <Meetone {...props} />
    case "mft":
      return <Mft {...props} />
    case "miota":
      return <Miota {...props} />
    case "mith":
      return <Mith {...props} />
    case "mkr":
      return <Mkr {...props} />
    case "mln":
      return <Mln {...props} />
    case "mnx":
      return <Mnx {...props} />
    case "mnz":
      return <Mnz {...props} />
    case "moac":
      return <Moac {...props} />
    case "mod":
      return <Mod {...props} />
    case "mona":
      return <Mona {...props} />
    case "msr":
      return <Msr {...props} />
    case "mth":
      return <Mth {...props} />
    case "mtl":
      return <Mtl {...props} />
    case "music":
      return <Music {...props} />
    case "mzc":
      return <Mzc {...props} />
    case "nano":
      return <Nano {...props} />
    case "nas":
      return <Nas {...props} />
    case "nav":
      return <Nav {...props} />
    case "ncash":
      return <Ncash {...props} />
    case "ndz":
      return <Ndz {...props} />
    case "nebl":
      return <Nebl {...props} />
    case "neo":
      return <Neo {...props} />
    case "neos":
      return <Neos {...props} />
    case "neu":
      return <Neu {...props} />
    case "nexo":
      return <Nexo {...props} />
    case "ngc":
      return <Ngc {...props} />
    case "nio":
      return <Nio {...props} />
    case "nlc2":
      return <Nlc2 {...props} />
    case "nlg":
      return <Nlg {...props} />
    case "nmc":
      return <Nmc {...props} />
    case "npxs":
      return <Npxs {...props} />
    case "nuls":
      return <Nuls {...props} />
    case "nxs":
      return <Nxs {...props} />
    case "nxt":
      return <Nxt {...props} />
    case "oax":
      return <Oax {...props} />
    case "ok":
      return <Ok {...props} />
    case "omg":
      return <Omg {...props} />
    case "omni":
      return <Omni {...props} />
    case "ong":
      return <Ong {...props} />
    case "ont":
      return <Ont {...props} />
    case "oot":
      return <Oot {...props} />
    case "ost":
      return <Ost {...props} />
    case "ox":
      return <Ox {...props} />
    case "part":
      return <Part {...props} />
    case "pasc":
      return <Pasc {...props} />
    case "pasl":
      return <Pasl {...props} />
    case "pax":
      return <Pax {...props} />
    case "pay":
      return <Pay {...props} />
    case "payx":
      return <Payx {...props} />
    case "pink":
      return <Pink {...props} />
    case "pirl":
      return <Pirl {...props} />
    case "pivx":
      return <Pivx {...props} />
    case "plr":
      return <Plr {...props} />
    case "poa":
      return <Poa {...props} />
    case "poe":
      return <Poe {...props} />
    case "polis":
      return <Polis {...props} />
    case "poly":
      return <Poly {...props} />
    case "pot":
      return <Pot {...props} />
    case "powr":
      return <Powr {...props} />
    case "ppc":
      return <Ppc {...props} />
    case "ppp":
      return <Ppp {...props} />
    case "ppt":
      return <Ppt {...props} />
    case "pre":
      return <Pre {...props} />
    case "prl":
      return <Prl {...props} />
    case "pungo":
      return <Pungo {...props} />
    case "pura":
      return <Pura {...props} />
    case "qash":
      return <Qash {...props} />
    case "qiwi":
      return <Qiwi {...props} />
    case "qlc":
      return <Qlc {...props} />
    case "qrl":
      return <Qrl {...props} />
    case "qsp":
      return <Qsp {...props} />
    case "qtum":
      return <Qtum {...props} />
    case "r":
      return <R {...props} />
    case "rads":
      return <Rads {...props} />
    case "rap":
      return <Rap {...props} />
    case "rcn":
      return <Rcn {...props} />
    case "rdd":
      return <Rdd {...props} />
    case "rdn":
      return <Rdn {...props} />
    case "ren":
      return <Ren {...props} />
    case "rep":
      return <Rep {...props} />
    case "req":
      return <Req {...props} />
    case "rhoc":
      return <Rhoc {...props} />
    case "ric":
      return <Ric {...props} />
    case "rise":
      return <Rise {...props} />
    case "rlc":
      return <Rlc {...props} />
    case "rpx":
      return <Rpx {...props} />
    case "rub":
      return <Rub {...props} />
    case "rvn":
      return <Rvn {...props} />
    case "ryo":
      return <Ryo {...props} />
    case "safe":
      return <Safe {...props} />
    case "salt":
      return <Salt {...props} />
    case "san":
      return <San {...props} />
    case "sbd":
      return <Sbd {...props} />
    case "sberbank":
      return <Sberbank {...props} />
    case "sc":
      return <Sc {...props} />
    case "shift":
      return <Shift {...props} />
    case "sib":
      return <Sib {...props} />
    case "sin":
      return <Sin {...props} />
    case "sky":
      return <Sky {...props} />
    case "slr":
      return <Slr {...props} />
    case "sls":
      return <Sls {...props} />
    case "smart":
      return <Smart {...props} />
    case "sngls":
      return <Sngls {...props} />
    case "snm":
      return <Snm {...props} />
    case "snt":
      return <Snt {...props} />
    case "soc":
      return <Soc {...props} />
    case "spank":
      return <Spank {...props} />
    case "sphtx":
      return <Sphtx {...props} />
    case "srn":
      return <Srn {...props} />
    case "stak":
      return <Stak {...props} />
    case "start":
      return <Start {...props} />
    case "steem":
      return <Steem {...props} />
    case "storj":
      return <Storj {...props} />
    case "storm":
      return <Storm {...props} />
    case "stq":
      return <Stq {...props} />
    case "strat":
      return <Strat {...props} />
    case "sub":
      return <Sub {...props} />
    case "sumo":
      return <Sumo {...props} />
    case "sys":
      return <Sys {...props} />
    case "taas":
      return <Taas {...props} />
    case "tau":
      return <Tau {...props} />
    case "tbx":
      return <Tbx {...props} />
    case "tel":
      return <Tel {...props} />
    case "ten":
      return <Ten {...props} />
    case "tern":
      return <Tern {...props} />
    case "tgch":
      return <Tgch {...props} />
    case "theta":
      return <Theta {...props} />
    case "tix":
      return <Tix {...props} />
    case "tkn":
      return <Tkn {...props} />
    case "tks":
      return <Tks {...props} />
    case "tnb":
      return <Tnb {...props} />
    case "tnc":
      return <Tnc {...props} />
    case "tnt":
      return <Tnt {...props} />
    case "tomo":
      return <Tomo {...props} />
    case "tpay":
      return <Tpay {...props} />
    case "trig":
      return <Trig {...props} />
    case "trtl":
      return <Trtl {...props} />
    case "trx":
      return <Trx {...props} />
    case "tusd":
      return <Tusd {...props} />
    case "tzc":
      return <Tzc {...props} />
    case "ubq":
      return <Ubq {...props} />
    case "unity":
      return <Unity {...props} />
    case "usd":
      return <Usd {...props} />
    case "usdc":
      return <Usdc {...props} />
    case "usdt":
      return <Usdt {...props} />
    case "utk":
      return <Utk {...props} />
    case "veri":
      return <Veri {...props} />
    case "vet":
      return <Vet {...props} />
    case "via":
      return <Via {...props} />
    case "vib":
      return <Vib {...props} />
    case "vibe":
      return <Vibe {...props} />
    case "vivo":
      return <Vivo {...props} />
    case "vrc":
      return <Vrc {...props} />
    case "vrsc":
      return <Vrsc {...props} />
    case "vtc":
      return <Vtc {...props} />
    case "vtho":
      return <Vtho {...props} />
    case "wabi":
      return <Wabi {...props} />
    case "wan":
      return <Wan {...props} />
    case "waves":
      return <Waves {...props} />
    case "wax":
      return <Wax {...props} />
    case "wgr":
      return <Wgr {...props} />
    case "wicc":
      return <Wicc {...props} />
    case "wings":
      return <Wings {...props} />
    case "wpr":
      return <Wpr {...props} />
    case "wtc":
      return <Wtc {...props} />
    case "x":
      return <X {...props} />
    case "xas":
      return <Xas {...props} />
    case "xbc":
      return <Xbc {...props} />
    case "xbp":
      return <Xbp {...props} />
    case "xby":
      return <Xby {...props} />
    case "xcp":
      return <Xcp {...props} />
    case "xdn":
      return <Xdn {...props} />
    case "xem":
      return <Xem {...props} />
    case "xin":
      return <Xin {...props} />
    case "xlm":
      return <Xlm {...props} />
    case "xmcc":
      return <Xmcc {...props} />
    case "xmg":
      return <Xmg {...props} />
    case "xmo":
      return <Xmo {...props} />
    case "xmr":
      return <Xmr {...props} />
    case "xmy":
      return <Xmy {...props} />
    case "xp":
      return <Xp {...props} />
    case "xpa":
      return <Xpa {...props} />
    case "xpm":
      return <Xpm {...props} />
    case "xrp":
      return <Xrp {...props} />
    case "xsg":
      return <Xsg {...props} />
    case "xtz":
      return <Xtz {...props} />
    case "xuc":
      return <Xuc {...props} />
    case "xvc":
      return <Xvc {...props} />
    case "xvg":
      return <Xvg {...props} />
    case "xzc":
      return <Xzc {...props} />
    case "yoyow":
      return <Yoyow {...props} />
    case "zcl":
      return <Zcl {...props} />
    case "zec":
      return <Zec {...props} />
    case "zel":
      return <Zel {...props} />
    case "zen":
      return <Zen {...props} />
    case "zest":
      return <Zest {...props} />
    case "zil":
      return <Zil {...props} />
    case "zilla":
      return <Zilla {...props} />
    case "zrx":
      return <Zrx {...props} />

    default:
      return <Generic {...props} /> // TODO: return a default shape
  }
}
