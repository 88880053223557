import React, { Fragment, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import {
  CheckCircleIcon,
  InformationCircleIcon,
  ExclamationTriangleIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { Alert, SuccessAlert } from "@/components/Alert";
import { formatAmount } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import axios from "@/axios";

export const TransactionDetail = ({ transaction, keysToDisplay, page }) => {
  const [editedValues, setEditedValues] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});
  const [firstInputRendered, setFirstInputRendered] = useState(false);
  // Add this line to your existing state variables
  const [mutationError, setMutationError] = useState<string | null>(null);
  // Add this line to your existing state variables
  const [mutationSuccess, setMutationSuccess] = useState(false);
  const updateTransaction = ({ updatedValues, extTxnId }) => {
    console.log("updatedValues: ", updatedValues);
    return axios.put(`/transaction/${extTxnId}`, updatedValues);
  };
  // Modify the mutation to set the mutationError state on error
  // Modify the mutation to set the mutationSuccess state on success
  const mutation = useMutation({
    mutationFn: updateTransaction,

    onError: (error: any) => {
      console.log("errors ", error);
      // If the mutation fails, you can set the mutationError to the state
      setMutationError(error?.response?.data?.error);
    },

    onSuccess: () => {
      // On success, you can clear the mutationError and set mutationSuccess to true
      setMutationSuccess(true);
      setMutationError(null);
    }
  });

  // Reset mutationSuccess when editing starts
  const handleEditStart = () => {
    setIsEditing(true);
    setMutationSuccess(false);
    setMutationError(null);
  };

  const getFiatValueMessage = (transaction) => {
    console.log("[audit] type", transaction);

    // Get the list of missing keys from the transaction
    const missingKeys = transaction.getMissingKeyProperties();

    if (transaction.isTransferType()) {
      return "Transfer types don't need fiat values";
    }

    // Check if 'final_value' is missing
    const isFinalValueMissing = transaction.missing_final_value;

    // If 'final_value' is missing and it's not in the list of missing keys,
    // it means that the transaction type doesn't require a fiat value
    if (isFinalValueMissing && !missingKeys.includes("final_value")) {
      return "Fiat value missing";
    }

    // If 'final_value' is missing and it's in the list of missing keys,
    // it means that 'final_value' is indeed missing
    if (isFinalValueMissing && missingKeys.includes("final_value")) {
      return "Missing";
    }

    // If 'final_value' is not missing, format it and return it along with the asset
    return `${formatAmount(transaction.final_value)} ${
      transaction.final_valueasset
    }`;
  };

  // Create a ref for the first input element
  const firstInputRef = useRef(null);

  useEffect(() => {
    // If the form is in editing mode, set the focus to the first input element
    if (isEditing && firstInputRef.current) {
      setTimeout(() => {
        firstInputRef?.current?.focus();
      }, 100);
    }
  }, [isEditing]);

  // Initialize the rowValues state with an empty array
  const [rowValues, setRowValues] = useState<
    { label: string; value: string; icon?: React.ReactNode; key: string }[]
  >([]);

  useEffect(() => {
    const missingKeys = transaction.getMissingKeyProperties();
    // Update the rowValues state when the transaction prop changes
    setRowValues([
      { label: "Type", key: "type", value: transaction.type },
      { label: "Exchange", key: "exchange", value: transaction.exchange },
      {
        label: transaction.isDeposit() ? "Amount" : "Buy (Amount)",
        key: "buyqty",
        value:
          transaction.buyqty &&
          `${formatAmount(transaction.buyqty)} ${transaction.buyasset}`,
      },
      {
        label: transaction.isWithdrawal() ? "Amount" : "Sell (Amount)",
        key: "sellqty",
        value:
          transaction.sellqty &&
          `${formatAmount(transaction.sellqty)} ${transaction.sellasset}`,
      },
      {
        // TODO: remove this - we don't want fees to be editable
        label: "Fees",
        key: "feeqty",
        value: !transaction.feeqty
          ? "No fees found for this transaction"
          : `${formatAmount(transaction.feeqty)} ${transaction.feeasset}`,
        // remove icon bc don't want fee to be editable
      },
      {
        label: "Fiat value",
        key: "final_value",
        value: getFiatValueMessage(transaction),

        icon:
          transaction.missing_final_value &&
          !missingKeys.includes("final_value") ? (
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          ) : transaction.missing_final_value &&
            transaction.final_feevalue !== 0 ? (
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          ) : (
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          ),
      },
      {
        label: "Fiat fee value",
        key: "final_feevalue",
        value:
          transaction.missing_final_feevalue &&
          missingKeys.includes("final_feevalue")
            ? "Missing"
            : `${formatAmount(transaction.final_feevalue)} ${
                transaction.final_valueasset
              }`,
        icon:
          transaction.missing_final_feevalue &&
          transaction.final_feevalue !== 0 ? (
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          ) : (
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          ),
      },

      {
        label: "Note",
        key: "final_valuenote",
        value:
          transaction.final_valuenote === "USED_FIAT_LEG"
            ? "We used the fiat leg to calculate the value of this"
            : null,
      },
      { label: "Date", key: "date", value: transaction.formattedTimestamp() },
      { label: "Exchange ID", key: "exchangeId", value: transaction.tradeid },
    ]);
  }, [transaction]);

  /**
   * In this code, the regular expression regex now allows decimal values up to 2 decimal places, including 0. The condition in the if statement checks if value is an empty string, 0, or a number that matches the regular expression and is less than or equal to 10000. If it does, it updates the editedValues and rowValues states. If it doesn't, it sets an error message.
   */
  const handleValueChange = (e, key) => {
    const value = e.target.value;
    const regex = /^(0|0\.[0-9]{1,2}|[1-9][0-9]*\.[0-9]{1,2}|[1-9][0-9]*)$/;

    if (
      !value.startsWith("-") &&
      (value === "" || (regex.test(value) && parseFloat(value) <= 10000))
    ) {
      // Update the editedValues state
      setEditedValues({ ...editedValues, [key]: value });
      setErrors({ ...errors, [key]: "" });

      // Update the rowValues state
      setRowValues(
        rowValues.map((row) =>
          row.key === key ? { ...row, value: value || row.value } : row
        )
      );
    } else {
      setErrors({
        ...errors,
        [key]:
          "Invalid input. Only numbers up to 2 decimal places are allowed, and the maximum value is 10000.",
      });
    }
  };

  const handleSubmit = () => {
    // Check if any of the edited values are empty
    const isEmpty = Object.values(editedValues).some(
      (x) => x === null || x === ""
    );

    if (isEmpty) {
      setMutationError(
        "Please fill out all fields before submitting." as string
      );
      return;
    }

    // If the values are valid, handle the submit action, e.g., updating the state or sending data to an API
    console.log("Submitted values:", editedValues, transaction.ext_txn_id);
    // Reset editing state or perform further actions as needed
    setIsEditing(false);

    // Call the mutate function with the updated values
    mutation.mutate({
      updatedValues: editedValues,
      extTxnId: transaction.ext_txn_id,
    });

    // After submitting the form, invalidate the react-query cache for the transaction query after a 250ms delay
    setTimeout(() => {
      queryClient.invalidateQueries({
        queryKey: [`transaction/${transaction.ext_txn_id}`]
      });
      console.log("invalidate page: ", page);
      // TODO: store the auditType in context
      queryClient.invalidateQueries({
        queryKey: [`audit-${page}`]
      });
    }, 250);
  };

  if (!transaction) {
    return null;
  }

  return (
    <div className="bg-white shadow-lg rounded-lg p-4 my-4">
      <div className="overflow-x-auto">
        <table className="min-w-full text-left">
          <tbody className="divide-y divide-gray-200">
            {/* ... other rows ... */}
            {/* what's the issue with the data - i.e. what fields are missing  */}
            <tr className="w-full">
              <td className="py-4 text-sm text-gray-500" colSpan={2}>
                {transaction?.getMissingFiatValueProperties() && (
                  <Alert text={transaction?.getMissingFiatValueProperties()} />
                )}

                {mutationSuccess && (
                  <SuccessAlert text="Successfully updated transaction." />
                )}
              </td>
            </tr>
            {/* iterate rows and icons and edit inputs where needed  */}
            {/* // Add the ref to the first input element in your form */}
            {rowValues.map(
              (row, index) =>
                row.value && (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="py-2 px-4 text-sm font-medium text-blue-600 whitespace-nowrap">
                      {row.label}
                    </td>
                    <td className="py-2 px-4 text-sm text-gray-900 flex items-center">
                      {row.icon && <span className="mr-2">{row.icon}</span>}
                      {isEditing &&
                      transaction
                        .getMissingKeyProperties()
                        .includes(row.key) ? (
                        <Fragment>
                          <div className="flex flex-col">
                            <input
                              type="number"
                              value={editedValues[row.key] || ""}
                              onChange={(e) => handleValueChange(e, row.key)}
                              placeholder="Enter your value here"
                              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                              ref={!firstInputRendered ? firstInputRef : null}
                              onRendered={() => setFirstInputRendered(true)}
                            />
                            {errors[row.label] && (
                              <p className="pl-2 text-red-500 text-xs mt-1">
                                {errors[row.label]}
                              </p>
                            )}
                          </div>
                        </Fragment>
                      ) : (
                        row.value
                      )}
                    </td>
                  </tr>
                )
            )}

            {/* ... other rows ... */}
          </tbody>
        </table>
        {/* form handling for submitting the edits  */}
        <div className="flex justify-end my-4">
          {isEditing && (
            <button
              type="submit"
              className=" inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onClick={handleSubmit}
            >
              Submit
            </button>
          )}
          {transaction?.getMissingFiatValueProperties() && (
            <button
              type="button"
              className="ml-2 inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={handleEditStart}
            >
              <span className="">Edit</span>
            </button>
          )}
        </div>
        {mutationError && (
          <div className="text-red-500">
            There was a problem updating. Please try again later.{" "}
          </div>
        )}
      </div>
    </div>
  );
};
