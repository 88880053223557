import autoprefixer from "autoprefixer";
import React from "react";

import "./styles/progress-bar.css"

export const ProgressBar = (props) => {
  const { bgcolor, completed } = props;

  const percentage = completed()

  const containerStyles = {
    height: "10px",
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    // margin: 8,
    marginTop: "auto",
    marginBottom: "auto",
    justifyContent: "center",
  };

  const fillerStyles = {
    height: "16px",
    width: `${percentage}%`,
    background: `repeating-linear-gradient(-45deg, ${bgcolor} 0 30px, #0000 0 40px) right/200% 100%`,
    transition: "width 1s ease-in-out",
    borderRadius: "inherit",
    textAlign: "center",
    display: 'flex',
    animation: percentage === 100 ? "" : `i3 ${2*Number(percentage/10)}s infinite linear`,
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles} className="relative">
        {
        percentage=== 100? <></>:
        <span className="flex font-bold text-[12px] items-center absolute right-[-30px]">{`${percentage}%`}</span>
        }
      </div>
    </div>
  );
};
