import React, { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  BadgeCheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CollectionIcon,
  MagnifyingGlassIcon,
  SortAscendingIcon,
  StarIcon,
} from "@heroicons/react/24/solid";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { classNames } from "@/utils";
import { NotificationButton, ProfileDropdown } from "@/components";

import moonscapelogo from "@/moonscape.svg";
import { Link } from "react-router-dom";
import navigation from "@/navigation";

const userNavigation = [
  { name: "Profile", href: "/profile" },
  { name: "Settings", href: "/settings/exchange" },
];

export const MobileNavigation = () => {
  return (
    <Disclosure as="nav" className="flex-shrink-0 bg-gray-800">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8  md:hidden lg:hidden">
            <div className="relative flex items-center justify-between h-16">
              {/* Logo section */}
              <div className="flex items-center px-2 lg:px-0 xl:w-64">
                <div className="flex-shrink-0">
                  <img
                    className="h-8 w-auto"
                    src={moonscapelogo}
                    // src="https://tailwindui.com/img/logos/workflow-mark-green-300.svg"
                    alt="Moonscape"
                  />
                </div>
              </div>

              <div className="flex md:hidden lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-sm text-green-500 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-700 focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              {/* Links section */}
              <div className="hidden lg:block lg:w-80">
                <div className="flex items-center justify-end">
                  <div className="flex">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="px-3 py-2 rounded-sm text-sm font-medium text-green-200 hover:text-white"
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  {/* Profile dropdown */}

                  <div className="relative z-10 flex-shrink-0 flex h-16  ">
                    {/* here you have all the break points */}
                    <div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
                      <div className="ml-4 flex items-center md:ml-6">
                        {/* {notifications} */}
                        {/* <NotificationButton /> */}
                        {/* Profile dropdown */}
                        {/* <ProfileDropdown /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                // <Disclosure.Button
                //   key={item.name}
                //   as="a"
                //   href={item.href}
                //   className={classNames(
                //     item.current
                //       ? "text-white bg-green-800"
                //       : "text-green-200 hover:text-green-100 hover:bg-gray-800",
                //     "block px-3 py-2 rounded-sm text-base font-medium"
                //   )}
                //   aria-current={item.current ? "page" : undefined}
                // >
                //   {item.name}
                // </Disclosure.Button>
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? "text-white bg-green-800"
                      : "text-green-200 hover:text-green-100 hover:bg-gray-800",
                    "block px-3 py-2 rounded-sm text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-green-800">
              <div className="px-2 space-y-1">
                {userNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="block px-3 py-2 rounded-sm text-base font-medium text-green-200 hover:text-green-100 hover:bg-gray-800"
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
