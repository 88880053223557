import React from "react";
import { useHistory } from "react-router-dom";
import { clsx } from "clsx";

import { Formik } from "formik";
import { AxiosError } from "axios";
import { ErrorView } from "../Error";
import { SuccessView } from "../Success";
import { useAPIKeysData } from "@moonscape/shared";
import { Fragment, useState } from "react";
import { APIKeyForm } from "./APIKeyForm";
import { Link, useRouteMatch } from "react-router-dom";

import _ from "lodash";
import { handleAPIKeySubmit } from "@moonscape/shared";

import { InformationCircleIcon } from "@heroicons/react/24/solid";

let initialValues = {
  exchange: "",
  apiKey: "",
  apiSecret: "",
};

export const CantFindExchangeInfo = () => {
  return (
    <div className="rounded-md bg-blue-50 p-4 mt-4 shadow-sm">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 text-sm text-blue-700">
          <p>Can't find your exchange on our list? We're here to help!</p>
          <div className="mt-2 flex space-x-3">
            <a
              href="mailto:hello@moonscape.app?subject=[Integration Request]&body=Hi! Please add [insert exchange/wallet name] to Moonscape.%0D%0A%0D%0AThanks!"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-blue-700 bg-white hover:bg-blue-50"
            >
              Request Integration
            </a>
            <a
              href="mailto:hello@moonscape.app?subject=Please%20upload%20this%20CSV%20from%20[enter%20exchange%20name]&body=Hi%20-%20my%20Moonscape%20login%20email%20is%20[insert%20email].%0D%0A%0D%0APlease%20upload%20this%20CSV%20to%20my%20account%20so%20that%20it's%20visible%20in%20my%20Dashboard.%0D%0A%0D%0AThis%20CSV%20was%20exported%20from%20[insert%20exchange%20name].%0D%0A%0D%0AI'm%20happy%20to%20answer%20any%20questions related to the import.%0D%0A%0D%0AThanks"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-blue-700 bg-white hover:bg-blue-50"
            >
              Upload a CSV
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const APIKeyGuideInfo = () => {
  return <div></div>;
};

export const NewExchange = () => {
  let history = useHistory();

  const [formState, setFormState] = useState({
    ...initialValues,
  });
  const { path, url } = useRouteMatch();

  const {
    isQueryLoading,
    error: queryError,
    apikeys,
    email,
    refetch,
    linkedExchanges,
  } = useAPIKeysData();

  console.log("[linkedExchanges] linked exchanges", linkedExchanges);
  const [error, setError] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState<Set<AxiosError>>(new Set());
  const [addKeySuccess, setAddKeySuccess] = useState<boolean>(false);

  /** handleSubmit (formik) handle form submit - send keys to server
   *
   * should return `shouldSync` to help trigger a sync after adding api keys
   */

  return (
    <section>
      <div className="space-y-2 mt-2">
        {errorMsgs.size || queryError ? (
          <div className="bg-white pt-6 px-4 sm:p-6">
            {errorMsgs && <ErrorView errors={errorMsgs} />}
          </div>
        ) : null}
        {addKeySuccess ? (
          <div className="bg-white pt-6 px-4 sm:p-6">
            <SuccessView />
          </div>
        ) : null}
      </div>
      {/* API KEY AND API SECRET FORM  */}
      <div className="min-h-fit min-w-fit px-6 sm:rounded-sm sm:overflow-hidden">
        {/* <BackButton /> */}
        {/* form */}
        <Formik
          // innerRef={forwardedRef}
          validateOnChange
          // validateOnBlur
          // wrapper that gives utility methods for deadling with <form>
          // Set enableReinitialize to true. So Form gets re-populated when props changes.
          enableReinitialize={true}
          initialValues={{
            // TODO: set initial values from the useAPIKeysData hook
            ...formState,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            handleAPIKeySubmit({
              values,
              setSubmitting,
              setErrorMsgs,
              errorMsgs,
              refetch,
              email,
              setAddKeySuccess,
              setError,
              setIsSubmitting: setSubmitting,
              onError: (axiosError) => {
                console.log("form error: ", axiosError);
              },
            });
          }}
        >
          {/* formik render props */}
          {({
            values,
            handleBlur,
            errors,
            setErrors,
            setTouched,
            touched,
            isSubmitting,
            handleSubmit,
            setFieldValue,
          }) => {
            const shouldDisableButton =
              _.isEmpty(errors ?? {}) &&
              (touched?.apiKey || touched?.apiSecret || touched?.exchange);
            console.log("errors", { shouldDisableButton, errors, touched });

            return (
              <Fragment>
                <form
                  className="space-y-8 "
                  onSubmit={(e) => {
                    e.preventDefault(); // prevent page refresh
                    console.log("form submit");
                    handleSubmit();
                  }}
                >
                  <div className="bg-white  px-4 sm:p-6">
                    <div>
                      <h2 className="text-lg leading-6 font-medium text-gray-900">
                        Add an exchange connection
                      </h2>
                      <p className="mt-1 text-sm text-gray-500">
                        For your security, please provide read-only API keys. We
                        strictly require read-only access and will not accept
                        keys with broader permissions.
                        <br />
                      </p>

                      <div className="mt-4 flex items-start">
                        <span className="text-sm text-gray-400 text-md flex-shrink-0 ">
                          Need help creating API keys?{" "}
                          <a
                            className="text-gray-400 text-sm underline-offset-2 underline hover:text-gray-400 hover:no-underline"
                            href="https://moonscape.app/learn"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Read our help guide
                          </a>
                        </span>
                      </div>
                    </div>

                    <APIKeyForm
                      errors={errors}
                      setErrors={setErrors}
                      touched={touched}
                      setTouched={setTouched}
                      values={values}
                      isSubmitting={isSubmitting}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      apiKeys={apikeys}
                      linkedExchanges={linkedExchanges}
                    />
                    <CantFindExchangeInfo />

                    {/* <p className="mt-16 text-sm text-gray-500">
                  Radio group to confirm you've accepted that this is a
                  read-only key and therefore we don't have access write or take
                  away any funds
                </p> */}
                  </div>

                  <div className="px-4 mt-6 py-4 sm:px-6 sm:flex sm:flex-row-reverse sm:space-x-reverse sm:space-x-4">
                    <button
                      type="button"
                      className={`inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto sm:text-sm ${
                        shouldDisableButton
                          ? "bg-green-600 hover:bg-green-700 focus:ring-green-500"
                          : "bg-gray-300"
                      }`}
                      onClick={async () => {
                        // submit keys
                        console.log("submit keys ");
                        handleSubmit();
                      }}
                      disabled={!shouldDisableButton}
                    >
                      Add exchange connection and sync
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm"
                      onClick={() => {
                        // cancel handler
                        console.log("cancel adding keys");
                        history.goBack();
                      }}
                    >
                      Back
                    </button>
                  </div>
                </form>
              </Fragment>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};
