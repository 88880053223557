import { useQuery } from "@tanstack/react-query";
import { authorizedAxios } from "@/axios";
import { useAuth0 } from "@auth0/auth0-react";

import { JobStatus } from "@/types/moonscape";

export const useSyncJobStatus = ({ refetchInterval, showSyncProgressBar }) => {
  // get auth0 user details
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const email = user?.email; // userId

  // react-query fetch data function
  const getJobStatus = async (): Promise<JobStatus> => {
    console.log("calling getJobStatus");
    const url = `/sync/status`;
    try {
      const token = await getAccessTokenSilently();

      const res = await authorizedAxios(token).get(url);
      console.log("[useSyncJobStatus] res", res?.data?.totalProgress);

      return res.data;
    } catch (error) {
      console.log("[useSyncJobStatus] error", error);
      throw error;
    }
  };

  // do the useQuery
  // react-query - waiting on auth0 email field before triggering query
  const {
    isLoading: isQueryLoading,
    isIdle,
    isSuccess,
    error,
    isError,
    data: jobStatus,
    refetch,
  } = useQuery({
    queryKey: ["jobStatus"],
    queryFn: getJobStatus,

    // The query will not execute until the userId exists
    enabled: !!email && showSyncProgressBar,

    refetchOnWindowFocus: true,

    // When staleTime elapses, your data is marked as stale - but that doesn't mean it will be instantly refetched.
    staleTime: 10000,

    // refetch every x ms
    refetchInterval: refetchInterval
  });

  return { isQueryLoading, isIdle, error, jobStatus, email, refetch };
};
