import React from "react";
import { LoginButton } from "./Login";

export const VerifyEmail = () => {
  return (
    //   page container
    <div className="flex-1  flex flex-col overflow-hidden">
      <div className="min-h-screen bg-white">
        <div className="py-10">
          <main>
            <div className="max-w-full mx-auto sm:px-6 lg:px-8">
              {/* Replace with your content */}

              <p>
                Please verify your email by clicking the link in your email.
                <br /> <br /> Please also check your Spam folder.
              </p>
              <br />
              <LoginButton />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
