import React from "react";

import { Link } from "react-router-dom";
import { CloudIcon } from "@heroicons/react/24/outline";

export const LinkExchangeAccountPrompt = () => {
  return (
    <div className="h-64   p-10 flex items-center  justify-center bg-white ">
      <CloudIcon className="h-28 w-28 text-green-400" aria-hidden="true" />

      <Link to="/settings" className="hyperlink">
        Connect your crypto exchanges to view your crypto positions and trades
        in one place.
      </Link>
      {/* 
        <p>Learn Moonscape Basics or explore Example Dashboards</p> */}
    </div>
  );
};
