import React, { useEffect, useState } from "react";

import { Screen } from "@/components";

import {
  RecentActivity,
  RecentActivityPagination,
} from "@/screens/TradesScreen";

import { AuditTradeList } from "@/components/TradeList/AuditTradeList";
import { TransactionAuditModal } from "@/screens/TransactionAuditScreen/TransactionAuditModal";
import { TransactionAuditContext } from "@/contexts";
import { clsx } from "clsx";
import { AuditType, useAuditPagination } from "@moonscape/shared";

export const TransactionAuditScreen = () => {
  const [auditType, setAuditType] = useState(AuditType.All);

  const {
    auditTransactions,
    isQueryLoading,
    error,
    items,
    // pagination handlers
    previousPagination,
    nextPagination,
    resetPagination,
    // pagination data
    hasMore,
    remaining,
    page,
    setPage,
    isPreviousData,
  } = useAuditPagination({
    exchange: null,
    asset: null,
    auditType: auditType,
    isSearching: false,
    searchedValue: null,
    onLoading: () => {
      // onLoadingHandler();
      // console.log("onLoadingHandler");
    },
  });

  const [isModalOpen, setModalOpen] = useState(false);
  const [extTxnId, setExtnTxnId] = useState(null);

  console.log("transaction: extTxnId", extTxnId);

  useEffect(() => {
    if (extTxnId) {
      setModalOpen(true);
    }
  }, [extTxnId]);

  console.log("auditTransactions", auditTransactions);

  return (
    <TransactionAuditContext.Provider value={{ setModalOpen, setExtnTxnId }}>
      <div className=" min-h-screen flex-1 flex flex-col overflow-hidden">
        <Screen heading="Transaction Audit">
          <main className="pb-8 z-0 overflow-y-auto">
            <div
              className="mt-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-5"
              role="alert"
            >
              <p className="font-bold">Almost there!</p>
              <p>
                Quick heads-up: some of your transactions are missing a few tax
                details. 🧐 Quick fix? Just zip through your account and add
                what’s missing. Thanks for being on top of it!
              </p>
            </div>

            <h2 className=" mx-auto mt-6 px-4 sm:px-6 lg:px-8 text-lg leading-6 mb-2 font-medium text-gray-900">
              {/* Missing transaction values */}
            </h2>

            {/* <div className="max-w-7xl mx-auto py-4  px-4 sm:px-6 lg:px-8"> */}

            <div className="flex flex-row">
              <RecentActivity
                isSearching={false}
                searchedValue={null}
                trades={auditTransactions}
                previousPagination={previousPagination}
                nextPagination={nextPagination}
                resetPagination={resetPagination}
                hasMore={hasMore}
                remaining={remaining}
                page={page}
                isPreviousData={isPreviousData}
                isQueryLoading={isQueryLoading}
                error={error}
                exchange={null}
                asset={null}
              >
                {/* RecentActivity feeds the children the data */}
                <AuditTradeList
                  isQueryLoading={isQueryLoading}
                  // exchange={props?.exchange}
                  // asset={props?.asset}
                  // isSearching={props?.isSearching}
                  // searchedValue={props?.searchedValue}

                  onLoading={() => {
                    console.log("TradesScreen onLoadingHandler");
                  }}
                  // hasLinkedExchangeAccount={props?.hasLinkedExchangeAccount}
                  // trades={props?.trades}
                  // previousPagination={props?.previousPagination}
                  // nextPagination={props?.nextPagination}
                  // resetPagination={props?.resetPagination}
                  // hasMore={props?.hasMore}
                  // remaining={props?.remaining}
                  // page={props?.page}
                  // isPreviousData={props?.isPreviousData}
                  // isQueryLoading={props?.isQueryLoading}
                  // error={props?.error}
                />
              </RecentActivity>
            </div>
          </main>
        </Screen>
        <div className="border-t border-gray-200 sticky top-[100vh] pr-24">
          <RecentActivityPagination
            next={nextPagination}
            prev={previousPagination}
            isPreviousData={isPreviousData}
            hasMore={hasMore}
            remaining={remaining}
            trades={auditTransactions}
            resetPagination={resetPagination}
            page={page}
            isQueryLoading={isQueryLoading}
          />
        </div>
        {isModalOpen && (
          <TransactionAuditModal
            extTxnId={extTxnId}
            setOpen={setModalOpen}
            open={isModalOpen}
            page={page}
          />
        )}
      </div>
    </TransactionAuditContext.Provider>
  );
};
