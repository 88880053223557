import React, { useState } from "react";
import { Alert } from "@/components";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Link, useRouteMatch } from "react-router-dom";
import { useErrorLogs } from "@/hooks/useErrorLogs";
import { TaxErrorModal } from "./TaxErrorModal";

export function getCustomErrorMessage(error) {
  // Define a mapping of error messages to custom copy
  const errorMessageMap = {
    "Mismatch - selling more than you have":
      "Missing some transactions? It appears you're selling an asset you don't have a matching buy or deposit for.",
    // Add more error message mappings here as needed
  };

  // Check if the error message exists in the map and return the custom message
  return (
    errorMessageMap[error.error_msg] ||
    "An unexpected error occurred. Please review your transactions."
  );
}

export const TaxAlert = ({ showAlert }) => {
  const { path, url } = useRouteMatch();
  console.log("url path ", url, path);

  // get error log
  const { data } = useErrorLogs();

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedError, setSelectedError] = useState(null);

  console.log("[error-log] data", data);

  // TODO: check audit queries here to see if audit is needed or somehow combine both

  // return (
  //   showAlert && (
  //     <div className="flex items-center p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
  //       <div className="flex-shrink-0">
  //         <ExclamationTriangleIcon
  //           className="h-5 w-5 text-yellow-500"
  //           aria-hidden="true"
  //         />
  //       </div>
  //       <div className="ml-3">
  //         <p className="text-sm font-medium">
  //           Some transaction data may be missing or incomplete.
  //         </p>
  //         <div className="mt-2 text-sm">
  //           <Link
  //             to={`/audit`}
  //             className="font-medium underline hover:text-yellow-600 transition duration-150 ease-in-out"
  //           >
  //             Review and complete your transactions.
  //           </Link>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // );
  return (
    showAlert && (
      <>
        <div className="flex items-center p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-500"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-row ">
            {data?.map((error, index) => {
              return (
                <div className="ml-3 flex-row mb-6">
                  <p className="text-sm font-medium">
                    {index + 1}). {getCustomErrorMessage(error)}
                  </p>
                  <div className=" pl-6 text-sm">
                    <button
                      onClick={() => {
                        setSelectedError(error);
                        console.log("[error-log] onPress");
                        setModalOpen(true);
                      }}
                      className="font-medium underline hover:text-yellow-600 transition duration-150 ease-in-out"
                    >
                      View details
                    </button>
                  </div>
                </div>
              );
            })}
            <p className="text-sm font-medium">
              Note: Unpaired transactions can throw off your cost or gain
              calculations. Matching them up is a quick fix for peace of mind
              come tax season. <br /> <br /> Ready to check them off? Add any
              missing transactions, connect more exchanges or send us a message
              and we'll be happy to help.
            </p>
          </div>
        </div>
        {isModalOpen && (
          <TaxErrorModal
            taxEvent={selectedError?.error_data}
            error={selectedError}
            setOpen={setModalOpen}
            open={isModalOpen}
          />
        )}
      </>
    )
  );
};
