import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { authorizedAxios } from "@/axios";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "./Button";
import { ButtonLink } from "../Button";

export const StripeSuccess = () => {
  // this is where i need to pull out the key from the success page
  const { user, getAccessTokenSilently } = useAuth0();

  const userId = user?.email; // userId

  const location = useLocation();
  const values = queryString.parse(location?.search);

  console.log("location ", location);
  const session_id = values.session_id;

  console.log("values ", session_id);

  // get stripe order information
  // for GET /order/success/<userrId>?session_id

  useEffect(() => {
    async function getStripeOrderDetails() {
      try {
        const token = await getAccessTokenSilently();

        const res = await authorizedAxios(token).get(
          `order/success/${userId}?session_id=${session_id}`
        );

        // read from the res.data
        /** 
         *   status: 'complete',
  submit_type: null,
  subscription: 'sub_1M5creK0wR3V9vWWqF9dk7Mf',

         */
        console.log("res.data", res.data);
      } catch (e) {
        console.log("error getStripeOrderDetails", e);
      }
    }
    if (session_id) getStripeOrderDetails();
  }, [getAccessTokenSilently, userId, session_id]);

  return (
    <div className="max-w-7xl mx-auto  px-4 sm:px-6 lg:px-8">
      <div className="bg-white mt-12">
        <div className="mx-auto max-w-7xl px-6 py-12  sm:py-16  lg:px-8">
          <div className="flex-row justify-center">
            <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
              You're upgraded 🎉
            </h2>
            <p className="text-lg mt-4 font-normal tracking-tight text-gray-500 sm:text-xl">
              Successful checkout!
            </p>
            <div className="mt-6 flex justify-center">
              <ButtonLink href="/" buttonText="Go to Dashboard" />
            </div>
          </div>
          <div className="mt-10 flex  gap-x-6 lg:mt-0 lg:flex-shrink-0"></div>
        </div>
      </div>
    </div>
  );
};
export const StripeCancel = () => {
  return <h1>Checkout cancelled!</h1>;
};
