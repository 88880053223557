// TODO: fix module imports to just set where it is
import React, { useState, useEffect, useContext } from "react";

import {
  MobileMenu,
  MobileNavigation,
  Screen,
  HomePageHeader,
  SearchBar,
} from "@/components";
import { Link, useRouteMatch } from "react-router-dom";

import { FeedbackContent } from "./FeedbackContent";
import { useProfile } from "@moonscape/shared";

export const FeedbackScreen = (props) => {
  const { path, url } = useRouteMatch();
  const {
    isQueryLoading: isProfileQueryLoading,
    error: profileQueryError,
    profile,
  } = useProfile();
  return (
    <Screen heading="Feedback">
      <div
        className="bg-gray-100 min-h-screen flex flex-col items-center px-4 sm:px-6 lg:px-8"
        style={{ paddingTop: "15vh" }}
      >
        <div className="max-w-md w-full space-y-8 p-6 rounded-md shadow-sm bg-white">
          <div>
            <h2 className="text-center text-3xl font-semibold text-gray-900">
              We Value Your Feedback
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Your insights help us make Moonscape even better! Share your
              thoughts, feature requests, bug reports, or general feedback with
              us.
            </p>
          </div>

          <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
            <div className="flex-grow">
              <a
                href="mailto:hello@moonscape.app?subject=Feedback on Moonscape"
                className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Send Us Your Feedback
              </a>
            </div>

            <div className="flex-grow">
              <a
                href="https://discord.gg/zeRH9TDhmd"
                target="_blank"
                rel="noopener noreferrer"
                className="w-full flex justify-center py-2 px-4 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
              >
                Join Our Discord
              </a>
            </div>
          </div>
        </div>
      </div>
    </Screen>
  );
};
