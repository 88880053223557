import React, { useState, useEffect, useContext } from "react";
import { useProfile } from "@moonscape/shared";
import { USTaxScreenWithProvider } from "./USTax/USTaxScreen";
import { UKTaxScreen } from "./UKTax/UKTaxScreen";

export const TaxScreen = (props) => {
  const {
    isQueryLoading: isProfileQueryLoading,
    error: profileQueryError,
    profile,
  } = useProfile();

  console.log("[TaxScreen] profile: ", profile);
  console.log("[TaxScreen] tax jurisdiction: ", profile?.taxJurisdiction);

  return <UKTaxScreen isQueryLoading={isProfileQueryLoading} />;

  if (profile?.taxJurisdiction === "United States") {
    return <USTaxScreenWithProvider isQueryLoading={isProfileQueryLoading} />;
  } else if (profile?.taxJurisdiction === "United Kingdom") {
    return <UKTaxScreen isQueryLoading={isProfileQueryLoading} />;
  } else {
    // find th way to serve loading in UK
    return <USTaxScreenWithProvider isQueryLoading={isProfileQueryLoading} />;
  }
};
