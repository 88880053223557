export function logAxiosError(axiosError) {
  console.error("Axios request failed!");

  // Log the HTTP status code and status text
  if (axiosError.response) {
    console.error(`Status: ${axiosError.response.status}`);
    console.error(`Status Text: ${axiosError.response.statusText}`);
  }

  // Log the URL and method of the request
  if (axiosError.config) {
    console.error(`Method: ${axiosError.config.method.toUpperCase()}`);
    console.error(`URL: ${axiosError.config.url}`);
  }

  // Log the error message from the API if available
  if (axiosError.response && axiosError.response.data) {
    console.error("Error response data:", axiosError.response.data);
  }

  // Log the request data if available and it's safe to log
  if (
    axiosError.config &&
    axiosError.config.data &&
    process.env.NODE_ENV !== "production"
  ) {
    console.error("Request data:", axiosError.config.data);
  }

  // Add any additional logging that makes sense for your context, for example:
  // - The time the error occurred
  // - The user or session ID (if applicable)
  // - Any relevant headers
  // - A correlation ID or request ID (if available)

  // It's often useful to stringify the error object to see if it has additional properties of interest
  if (process.env.NODE_ENV === "development") {
    console.error("Axios error object:", JSON.stringify(axiosError, null, 2));
  }
}
