import React from "react";
import { createRoot } from "react-dom/client";
import toast, { Toaster } from "react-hot-toast";

import {
  QueryClient,
  QueryClientProvider,
  HydrationBoundary,
} from "@tanstack/react-query";

import "./index.css";
import { Auth0ProviderWithHistory } from "@/auth";
import { KBarProvider, KBarContent, KBarSearch, KBarResults } from "kbar";

import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Create a react-query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchInterval: 60 * 1000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  },
  // This function is called when the client is created
  onHydrate: (data) => {
    // Load the cache from localStorage
    const cachedData = localStorage.getItem("queryCache");
    if (cachedData) {
      const queries = JSON.parse(cachedData);
      queries.forEach((query) => {
        queryClient.setQueryData(query.queryKey, query.state.data);
      });
    }
  },
});
// This function is called whenever a query is successful
/** 
 * React Query's queryClient.getQueryCache().getAll() returns an object that
   contains circular references, which can't be serialized to JSON directly.

To solve this, you need to create a custom replacer function that can handle
circular references. However, it's important to note that React Query's cache
contains complex objects (like functions) that can't be serialized to JSON at
all. So, it's generally not recommended to serialize the entire cache.
 */
queryClient.getQueryCache().subscribe(() => {
  const queries = queryClient.getQueryCache().getAll();

  // In this example, we're only persisting the queryKey, data, and status of
  // each query. You might need to adjust this to fit your needs.
  const simplifiedQueries = queries.map((query) => ({
    queryKey: query.queryKey,
    state: {
      data: query.state.data,
      status: query.state.status,
    },
  }));

  localStorage.setItem("queryCache", JSON.stringify(simplifiedQueries));
});

// KBar actions
const actions = [
  {
    id: "home",
    name: "Home",
    shortcut: ["i"],
    keywords: "trades home positions",
    perform: () => (window.location.pathname = "/"),
  },
  {
    id: "import",
    name: "Import",
    shortcut: ["i"],
    keywords: "import exchange api",
    perform: () => (window.location.pathname = "import"),
  },
  {
    id: "settings",
    name: "Settings",
    shortcut: ["s"],
    keywords: "settings",
    perform: () => (window.location.pathname = "settings"),
  },
  {
    id: "apikeys",
    name: "Exchange API Keys",
    shortcut: ["k"],
    keywords: "exchange keys api connection",
    perform: () => (window.location.pathname = "settings"),
  },
];

const searchStyles = {
  padding: "12px 16px",
  fontSize: "16px",
  width: "100%",
  boxSizing: "border-box",
  outline: "none",
  border: "none",
  background: "rgba(229, 231, 235, var(--tw-border-opacity))",
  color: "var(--foreground)",
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <Auth0ProviderWithHistory>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={queryClient.getQueryCache().getAll()}>
          {/* <KBarProvider
            actions={actions}
            options={{
              animations: {
                enterMs: 200,
                exitMs: 100,
                maxContentHeight: 400,
              },
            }}
          >
            <KBarContent
              contentStyle={{
                maxWidth: "400px",
                width: "100%",
                background: "var(--background)",
                color: "var(--foreground)",
                borderRadius: "8px",
                overflow: "hidden",
                boxShadow: "var(--shadow)",
              }}
            >
              <KBarSearch
                style={searchStyles}
                placeholder="Type a command or search…"
              />
              <KBarResults />
            </KBarContent> */}
          <Toaster />
          <Router>
            <App />
          </Router>
          {/* </KBarProvider> */}
        </HydrationBoundary>
      </QueryClientProvider>
    </Auth0ProviderWithHistory>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
