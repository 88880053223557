import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect, useParams } from "react-router-dom";
import { Button, ButtonLink, GreenButton } from "@/components";
import Cookies from "js-cookie";
import { trackEvent } from "@/analytics";
export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      buttonText="Log In"
      onClick={() => {
        // TODO: track "login CTA from /login"
        trackEvent("login redirect via login button", {
          action: "login redirect",
          category: "login",
          label: "login redirect",
          value: 1,
        });
        console.log("[analytics] login redirect via login button");
        loginWithRedirect();
      }}
    />
  );
};
export const SignupButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <GreenButton
      buttonText="Signup"
      onClick={() => {
        trackEvent("login redirect via signup button", {
          action: "login redirect",
          category: "login",
          label: "login redirect",
          value: 1,
        });

        loginWithRedirect();
      }}
    />
  );
};

/** checks /:plan params in the url and sets a cookie and timestamp */
const checkPlanParam = (params) => {
  if (params?.plan) {
    const { plan } = params;
    console.log("[checkUpgrade] plan selected: ", plan);
    // set cookie
    Cookies.set("entrypoint_selected_plan", plan);
    // unix time
    const timestamp = Math.floor(Date.now() / 1000);
    Cookies.set("entrypoint_selected_plan_timestamp", timestamp);
    // now when we go to the homepage, we can read the cookie and know what plan they selected
    // write a cookie for the last time you checked for an upgrade and then check that cookie on the homepage
    // i.e. every 24 hours, check if they have upgraded
  } else {
    console.log("[checkUpgrade] no plan selected");
  }
  const selectedPlan = Cookies.get("entrypoint_selected_plan");
  if (selectedPlan) {
    console.log("[checkUpgrade] reading plan from cookie", selectedPlan);
  }
};

export const Login = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  // read the params from the url
  // if there is a plan param, then we want to redirect to the signup page with the plan param
  const params = useParams();

  // checkPlanParam(params);

  if (isLoading) {
    return <p>loading</p>;
  }

  return isAuthenticated ? (
    <Redirect
      to={{
        pathname: "/",
        // state: { referrer: currentLocation },
      }}
    />
  ) : (
    //   page container
    <div className="flex-1  flex flex-col overflow-hidden">
      <div className="min-h-screen bg-white">
        <div className="py-10">
          <main>
            <div className="max-w-full mx-auto sm:px-6 lg:px-8">
              <LoginWelcome />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export const LoginWelcome = () => {
  return (
    <div className="max-w-7xl mx-auto  px-4 sm:px-6 lg:px-8">
      <div className="bg-white mt-12">
        <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:flex lg:items-center lg:justify-between lg:px-8">
          <div className="flex-row">
            <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
              Welcome!
            </h2>
            <p className="text-lg font-normal tracking-tight text-gray-500 sm:text-xl">
              Ready to tackle those crypto taxes? We're here to help.
            </p>
            <div className="mt-12 flex flex-row ">
              <LoginButton />

              <div className="pl-4">
                <SignupButton />
              </div>
            </div>
          </div>
          <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0"></div>
        </div>
      </div>
    </div>
  );
};
