import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";

import { useKeyboardShortcut } from "@/hooks";

import { config } from "../constants";

export const PriceList = () => {
  // keyboard shortcut
  const [showImage, setShowImage] = useState(false);
  const keys = ["Shift", "E"];
  const handleKeyboardShortcut = useCallback(
    (keys) => {
      setShowImage((currentShowImage) => !currentShowImage);
    },
    [setShowImage]
  );
  useKeyboardShortcut(keys, handleKeyboardShortcut);

  const [markets, setMarkets] = useState({});

  useEffect(() => {
    axios
      .get(`${config.url.API_URL}/api/latest`)
      .then((resp) => {
        console.log("response from server:", resp.data);
        setMarkets(resp.data);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }, []);

  console.log("markets", markets);
  return (
    <ul>
      {"hello, do you see kraken prices?"}

      {showImage && <p>shortcut activated</p>}

      <li key={markets.ccypair}>
        {markets.ccypair} {markets.price}
        <p>{markets.exchange}</p>
      </li>
    </ul>
  );
};
