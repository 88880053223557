import React from "react";
import { classNames } from "@/utils";

import { Link, useHistory } from "react-router-dom";

export const SettingsTabs = ({ pathname, tabs, activeTab, setActiveTab }) => {
  const history = useHistory();
  console.log("activeTab", activeTab?.[0]?.name);
  return (
    <div>
      <div className="lg:hidden">
        <label htmlFor="selected-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="selected-tab"
          name="selected-tab"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-sm"
          value={activeTab?.[0]?.name}
          onChange={(e) => {
            const selectedTab = e?.target?.value;
            console.log("change tab ", e.target.value);
            // setActiveTab(selectedTab);

            const tab = tabs.find((tab) => {
              console.log("res: pathname", pathname, tab);
              return selectedTab === tab.name;
            });
            console.log("res: ", tab.name, e.target.value);
            history.push(tab.href);
          }}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden lg:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <Link
                onClick={() => {
                  setActiveTab(tab);
                }}
                key={tab.name}
                to={tab.href}
                className={classNames(
                  // here is where we decide to higlight a tab!
                  pathname.includes(tab?.href)
                    ? "border-purple-500 text-purple-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

{
  /* <div className="bg-white rounded-lg shadow-md p-4 flex space-x-4">
  {tabs.map((tab) => (
    <button
      key={tab.name}
      onClick={() => setActiveTab(tab)}
      className={`px-4 py-2 rounded-lg font-semibold ${
        tab.current
          ? "bg-blue-500 text-white"
          : "text-gray-500 hover:bg-gray-100"
      }`}
    >
      {tab.name}
    </button>
  ))}
</div>; */
}
