import React, { useState, useEffect } from "react";
import OneSchemaImporter from "@oneschema/react";

import { axios, authorizedAxios } from "@/axios";
import { useAuth0 } from "@auth0/auth0-react";

import {
  Screen,
  HomePageHeader,
  SearchBar,
  CSVUpload,
  MobileNavigation,
} from "@/components";

export const ImportScreen = () => {
  const [csv, setCSV] = useState(null);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  // for the oneschema importer
  const [isOpen, setIsOpen] = useState(false);
  const onLaunched = (data) => {
    // TODO handle launch
  };

  const onSuccess = (data) => {
    // TODO handle success

    console.log("onSuccess data onecsv: ", data);
    handleCSV(data?.records, data?.fileInfo);
    postCSV(data?.records);
  };

  const onCancel = () => {
    // TODO handle cancel
  };

  const onError = (error) => {
    // TODO handle error
  };

  const email = user?.email; // userId

  const [theInputKey, setTheInputKey] = useState(null);

  const [transactions, setTransactions] = useState([]);
  const [CSVParsed, setCSVParsed] = useState(false);
  const [CSVParseError, setCSVParseError] = useState(false);
  const [CSVUploadSuccess, setCSVUploadSuccess] = useState(false);
  const [CSVErrors, setCSVErrors] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [uploaded, setUploaded] = useState(false);

  useEffect(() => {
    // clear state
    console.log("resetting state");
    console.log("state is before", {
      CSVParsed,
      CSVErrors,
      CSVParseError,
      CSVUploadSuccess,
    });
    CSVParsed || setCSVParsed(false);

    // CSVUploadSuccess && setCSVUploadSuccess(false)
    // when the user selects a new CSV - reset the state
    CSVErrors?.length > 0 && setCSVErrors(null) && setCSVUploadSuccess(false);
    CSVParseError && setCSVParseError(false) && setCSVErrors(null);
    // setCSVUploadSuccess(false)

    console.log("state is after ", {
      CSVParsed,
      CSVErrors,
      CSVParseError,
      CSVUploadSuccess,
    });
  }, [theInputKey]);

  const postCSV = async (data) => {
    try {
      const token = await getAccessTokenSilently();

      console.log("posting data:", data);
      const res = await authorizedAxios(token).post(
        `http://localhost:8080/api/import/${email}`,
        data
      );

      console.log("/postCSV res", res);

      // processResponse(res)

      // TODO set state for success
      res.data.msg === true && setCSVUploadSuccess(true);
      console.log("upload success!");
      cancelUploadedFile();
      return res;
    } catch (error) {
      console.log("error from import", error);
      if (error.response) {
        if (error.response.status === 428) {
          // go through the data.msg to show the error
          console.log("error.response.data.msg", error.response.data.msg);
          setCSVErrors(error.response.data.msg);
          setCSVUploadSuccess(false);
        }
        // CONFLICT
        else if (error.response.status === 409) {
          // go through the data.msg to show the error
          console.log("error 409", error.response.data.msg);
          // setCSVErrors(error.response.data.msg)
          setCSVUploadSuccess(false);
          setUploadError(error.response.data.msg);
        } else if (error.response.status === 404) {
          console.log("error 404", error.response.msg);
        }
      }
      return { error };
    }
  };

  const handleCSV = (data, fileInfo) => {
    // do a check that it's correct and then post to endpoint
    console.log("data: ", data, fileInfo);
    const headerRow = data[0];
    console.log("header row", headerRow);
    setTransactions(data);
    // setUploaded(true)

    // now post the data
    // postCSV(data);
    setCSV(data);

    // show some feedback
    setCSVParsed(true);
    setCSVUploadSuccess(false);

    cancelUploadedFile();
  };
  const handleError = (error) => {
    //
    console.log("handleError", error);

    // show some feedback
    setCSVParseError(true);
  };

  // this is to reset the input to allow selecting a file again and running the handleCSV
  const cancelUploadedFile = () => {
    const randomString = Math.random().toString(36);
    setTheInputKey(randomString);
  };

  // show/hide tawk widget if import is open
  useEffect(() => {
    if (window.Tawk_API && isOpen) {
      window.Tawk_API.hideWidget();
    }
    return () => {
      if (window.Tawk_API) {
        window.Tawk_API.showWidget();
      }
    };
  }, [isOpen]);

  const validateCSV = () => {
    // validate csv format here
    // or push to remote api but not really
  };
  return (
    <Screen>
      <div className="h-screen flex overflow-hidden bg-white">
        <div className="flex-1 overflow-auto focus:outline-none">
          <MobileNavigation />

          <div className="mt-8">
            <main
              className="flex-1 overflow-y-auto focus:outline-none lg:border-t lg:border-gray-200"
              key={theInputKey || ""}
            >
              <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
                <div className="pt-10 pb-16">
                  <h2 className="text-lg leading-6 font-medium text-gray-900">
                    if you include the trade id in the csv, it will prevent
                    duplicates
                  </h2>
                  <div className="px-4 sm:px-6 md:px-0">
                    {/* <CSVUpload
                      handleCSV={handleCSV}
                      handleError={handleError}
                      postCSV={postCSV}
                      csv={csv}
                    /> */}

                    <button
                      className="max-w-md my-4 bg-blue-600 text-white p-2 rounded-lg"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      Open OneSchema Importer
                    </button>

                    <OneSchemaImporter
                      /* managing state from your application */
                      isOpen={isOpen}
                      onRequestClose={() => setIsOpen(false)}
                      /* required config values */
                      clientId="cf3937b8-3000-4eb6-87f6-15dc31626c8b"
                      userJwt="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiPFVTRVJfSUQ-IiwiaXNzIjoiY2YzOTM3YjgtMzAwMC00ZWI2LTg3ZjYtMTVkYzMxNjI2YzhiIn0.HCGYVF8GQGEWx8qcGDXODwvXwxSwtpXbwElGn2Jz3mg"
                      templateKey="upload_trade"
                      /* optional config values */
                      importConfig={{ type: "local" }}
                      devMode={true}
                      className="oneschema-importer"
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        right: "50px",
                        zIndex: 1000,
                      }}
                      /* handling results */
                      onLaunched={onLaunched}
                      onSuccess={onSuccess}
                      onCancel={onCancel}
                      onError={onError}
                    />
                    {uploaded ? <p>uploaded!</p> : null}
                  </div>
                  <div>
                    {CSVParsed ? (
                      <p className="mt-2 ml-2 text-sm text-yellow-600">
                        CSV is ready to upload. Click upload.
                      </p>
                    ) : null}
                    {CSVParseError ? (
                      <p className="mt-2 ml-2 text-sm text-red-600">
                        CSV is ready to upload. Click upload.
                      </p>
                    ) : null}

                    {CSVErrors?.length > 0 ? (
                      <p
                        className="mt-2 ml-2 text-sm text-red-600"
                        id="email-error"
                      >
                        There was a problem with processing the CSV file:
                      </p>
                    ) : null}

                    {CSVErrors &&
                      CSVErrors?.map((e, index) => {
                        return (
                          <p
                            className="mt-2 ml-2 text-sm text-red-600"
                            id="email-error"
                          >
                            {index + 1}. {e}
                          </p>
                        );
                      })}

                    {uploadError ? (
                      <p className="mt-2 ml-2 text-sm text-red-600">
                        {uploadError}
                      </p>
                    ) : null}
                    {CSVUploadSuccess ? (
                      <p className="mt-2 ml-2 text-sm text-green-600">
                        CSV file uploaded successfully. Go see your trades...
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </Screen>
  );
};
