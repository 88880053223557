import { useState, useRef, useEffect } from "react";
import { useRawTransactionsData } from "./useRawTransactionsData";
import { useHistory, useLocation } from "react-router-dom";
import { RawTransactionsScreen } from "@/screens/TransactionAuditScreen/RawTransactionsScreen";

export const useRawTransactionsPagination = ({
  exchange,
  asset,
  isSearching,
  searchedValue,
  onLoading,
}) => {
  // cursor for pagination
  const [page, setPage] = useState(0);
  const cursorStack = useRef<number[]>([]);

  const history = useHistory();
  const location = useLocation();

  let {
    isQueryLoading,
    isIdle,
    error,
    rawTransactions,
    isPreviousData,
    isFetching,
  } = useRawTransactionsData(
    page,
    exchange,
    asset,
    isSearching,
    searchedValue,
    onLoading
  );

  const { items, cursor, remaining } = rawTransactions || {};
  const hasMore = rawTransactions?.remaining > 0;

  const resetPagination = () => {
    setPage(0);
    cursorStack.current = [0];
  };

  const nextPagination = () => {
    if (!isPreviousData && hasMore) {
      const newPage = cursor;
      setPage(newPage);
      cursorStack.current = [...cursorStack.current, newPage];
    }
  };

  const previousPagination = () => {
    if (cursorStack.current.length <= 2) {
      resetPagination();
    } else {
      const temp = cursorStack.current.slice(0, -2);
      const newPage = temp[temp.length - 1];
      setPage(newPage);
      cursorStack.current = temp;
    }
  };

  return {
    rawTransactions,
    isQueryLoading,
    error,
    items,
    previousPagination,
    nextPagination,
    resetPagination,
    hasMore,
    remaining,
    page,
    isPreviousData,
  };
};
