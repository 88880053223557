import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, MobileNavigation } from "@/components";
import { reset } from "@/analytics";

export const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Button
      buttonText="Logout"
      onClick={() => {
        reset();

        logout({ returnTo: window.location.origin });
      }}
    />
  );
};

export const Logout = () => {
  return (
    //   page container
    <div className="flex-1  flex flex-col overflow-hidden">
      <div className="min-h-screen bg-gray-100">
        <MobileNavigation />
        <div className="py-10">
          <main>
            <div className="max-w-full mx-auto sm:px-6 lg:px-8">
              {/* Replace with your content */}

              <LogoutButton />
              {/* /End replace */}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
