import { QueryFunction, useQuery, UseQueryResult } from "@tanstack/react-query";
import { authorizedAxios } from "@/axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

export const useUploadFile = () => {
  const { user, isLoading, getAccessTokenSilently } = useAuth0();
  const [isLoadingUpload, setIdLoadingUpload] = useState(false);

  /** fetch function that react-query will use to fetch error logs data */
  const importFile = async (file) => {
    setIdLoadingUpload(true);
    if (!file) return;
    try {
      const token = await getAccessTokenSilently();
      let data = new FormData();

      data.append("file", file);
      const res = await authorizedAxios(token).post<any>(
        `/import/file/transactions`,
        data,
        {}
      );

      console.log("[Import File] res", res);
      return res.data;
    } catch (error) {
      // For React Query to determine a query has errored, the query function must throw. Any error that is thrown in the query function will be persisted on the error state of the query.
      // https://react-query-v3.tanstack.com/guides/query-functions#handling-and-throwing-errors
      throw error;
    }
    setIdLoadingUpload(false);
  };

  return {
    isLoading: isLoadingUpload,
    importFile,
  };
};
