

// TODO: blue links for the SA108 to go to download center
// https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/1063019/sa108-2022.pdf
interface HelpCenterProps {
  faqs: {
    question: string;
    answer: string;
  }[];
}

export const FAQs: React.FC<HelpCenterProps> = () => {

  const faqs = [
    {
      question: "What’s the difference between SA100 & SA108 form?",
      answer: "SA100 is the main form for your self-assessment tax return. Use supplementary pages SA108 to record capital gains and losses on your SA100 Tax Return."
    },
    {
      question: "How do I update my personal information?",
      answer: "You can update your personal information in the settings section of your account. If you need further assistance, please contact our customer support team."
    }
  ];


  return (
    <div className="bg-white p-2 rounded-lg  w-full mb-4">
      <h2 className="text-lg font-medium text-gray-900 mb-4">Frequently asked questions</h2>
      <p className="text-sm text-gray-500 mb-6">
        Can’t find the answer you’re looking for? Reach out to our <a href="/support" className="text-green-600 underline">customer support</a> team
      </p>
      {faqs.map((faq, index) => (
        <div key={index} className="mb-4">
          <h3 className="text-md font-semibold text-gray-900 mb-2">{faq.question}</h3>
          <p className="text-sm text-gray-700">{faq.answer}</p>
        </div>
      ))}
    </div>
  );
};