import React from "react";

interface ITransactionListContext {
  setModalOpen: (open: boolean) => void;
  setExtnTxnId: (id: string | null | any) => void;
  isRefresh: boolean;
  setIsRefresh: (refresh: boolean) => void;
}

export const TransactionListContext =
  React.createContext<ITransactionListContext>({} as ITransactionListContext);
