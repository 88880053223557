import { useQuery } from "@tanstack/react-query";
import { authorizedAxios } from "@/axios";
import { useAuth0 } from "@auth0/auth0-react";

export const useTaxOperationStatus = () => {
  // Get auth0 user details
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const email = user?.email; // Assuming the backend uses email to identify the user

  // React-query fetch data function
  const getTaxOperationStatus = async (): Promise<any> => {
    console.log("Calling getTaxOperationStatus");
    const url = `/tax/status`; // Adjust this URL to your API endpoint
    try {
      const token = await getAccessTokenSilently();

      // Assuming the API requires the user's email to fetch the tax operation status
      const res = await authorizedAxios(token).get(url, {
        params: { email: email },
      });
      console.log("[useTaxOperationStatus] res", res?.data);

      return res.data;
    } catch (error) {
      console.log("[useTaxOperationStatus] error", error);
      throw error;
    }
  };

  const useQueryKey = `tax_operation_status`;

  // Use the useQuery hook
  const {
    isLoading: isQueryLoading,
    isIdle,
    isSuccess,
    error,
    isError,
    data: operationStatus,
    refetch,
  } = useQuery({
    queryKey: [useQueryKey],
    queryFn: getTaxOperationStatus,

    // The query will not execute until the email exists
    enabled: !!email && isAuthenticated && !isLoading,

    refetchOnWindowFocus: false,
    keepPreviousData: true
  });

  return { isQueryLoading, isIdle, error, operationStatus, email, refetch };
};
