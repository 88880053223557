import { Skeleton } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export const RecentActivityPagination = ({
  remaining,
  next,
  prev,
  isPreviousData,
  hasMore,
  trades,
  resetPagination,
  page,
  isQueryLoading,
}) => {
  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200  px-4 py-3 sm:px-6 max-w-8xl mx-auto"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700 flex gap-2">
          Showing
          {isQueryLoading ? (
            <Skeleton.Button active={true} size={"small"} shape={"round"} />
          ) : (
            <span className="font-medium">{trades?.items?.length}</span>
          )}
          of
          <span className="font-medium">
            {isQueryLoading ? (
              <Skeleton.Button active={true} size={"small"} shape={"round"} />
            ) : (
              remaining
            )}
          </span>{" "}
          remaining
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        {/* don't show Previous button if on first page */}
        {page !== 0 && (
          <button
            onClick={resetPagination}
            className="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-100"
          >
            Back to first page
          </button>
        )}
        <div className="flex pr-10">
          <button
            onClick={prev}
            className="ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-100"
          >
            Previous
          </button>
          {/* Pagination buttons - next */}
          <button
            onClick={next}
            className={`ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-100 ${
              isPreviousData || !hasMore ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isPreviousData || !hasMore}
          >
            Next
          </button>
        </div>
      </div>
    </nav>
  );
};
