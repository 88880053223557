import React, { useContext, useEffect, useRef, useState } from "react"
import { clsx } from "clsx"
import toast, { Toaster } from "react-hot-toast"

import { MagnifyingGlassIcon } from "@heroicons/react/24/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import { useDownloadFile } from "./useDownloadFile"
import { useHistory } from "react-router-dom"

import { useAuth0 } from "@auth0/auth0-react"
import { authorizedAxios } from "@/axios"

import { Tooltip } from "flowbite-react"
import { useUploadFile } from "./useUploadFile"
import { TransactionListContext } from "@/contexts"

/** Recent activity search bar component  */
export const RecentActivitySearch = ({
  onChangeHandler,
  resetPagination,
  page,
}) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0()
  const history = useHistory()
  const [uploadFile, setUploadFile] = useState<any>()
  const { setIsRefresh: setIsRefreshTransactionsList } = useContext(
    TransactionListContext
  )
  const userId = user?.email

  const { ref, url, download, name } = useDownloadFile({
    apiDefinition: async () => {
      // download the file
      const token = await getAccessTokenSilently()
      const url = `/export/${userId}`
      const res = await authorizedAxios(token).get(url)
      return res
    },

    onError: (e) => {
      console.log("error downloading csv", e)
    },
    // getFileName,
  })

  const { importFile, isLoading: isLoadingUpload } = useUploadFile()

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files
    console.log("file", files)
    try {
      if (files) {
        await toast.promise(importFile(files[0]), {
          loading: "Importing...",
          success: <b>Transactions saved!</b>,
          error: <b>Could not save transactions.</b>,
        })
        console.log("Import complete")
      } else {
        console.log("[Upload Error] No file selected")
      }
    } catch (e) {
      console.log(`[Upload Error] ${e?.message}`)
    }
    console.log("set setIsRefreshTransactionsList true")
    setIsRefreshTransactionsList(true)
  }

  return (
    <div className="flex relative  mt-2 rounded-lg w-full h-14 md:w-full mb-5">
      {/* search icon */}
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <MagnifyingGlassIcon
          className="h-6 w-6 text-gray-400"
          aria-hidden="true"
        />
      </div>
      {/* search input  */}
      <input
        //@ts-ignore
        spellCheck="false"
        type="search"
        className={clsx(
          "px-4 py-2 pl-10 w-full font-mono text-sm  border-spacing-1 border focus:focus:ring-green-500 focus:border-none rounded-lg"
        )}
        placeholder="Search with keywords like: btc june 2024 coinbase deposit"
        onFocus={() => {
          // Clear all search parameters
          // history.push({
          //   pathname: history.location.pathname,
          //   search: "",
          // });
          if (page !== 0) {
            resetPagination()
          }
        }}
        onChange={onChangeHandler}
      ></input>
      {/* loading spinner icon  */}

      <Tooltip content="Import Transactions" trigger="hover" style="light">
        <label htmlFor="file-upload">
          <div className="rotate-180 p-3 ml-3 text-sm font-medium text-white bg-green-700 rounded-lg border border-green-700 hover:bg-green-800  focus:outline-none dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
            {getIcon(isLoading)}
          </div>
        </label>
        <input
          id="file-upload"
          className="hidden"
          type="file"
          accept=".csv, .xlsx"
          onChange={async (e: any) => await handleFileChange(e)}
        />
      </Tooltip>
      <Tooltip content="Export CSV" trigger="hover" style="light">
        <a href={url} download={name} className="hidden" ref={ref} />
        <button
          // type="submit"
          className="p-3 ml-3 text-sm font-medium text-white bg-green-700 rounded-lg border border-green-700 hover:bg-green-800  focus:outline-none dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          onClick={() => {
            // TODO: track analytics
            console.log("click download csv")

            // alert(
            //   "CSV download is coming soon. Reach out if you're interested. support@getmoonscape.io"
            // );
            toast.promise(download(), {
              loading: "Downloading...",
              success: <b>Transactions downloaded!</b>,
              error: <b>Could not download transactions.</b>,
            })
          }}
        >
          {getIcon(isLoading)}
          <span className="sr-only">Search</span>
        </button>
      </Tooltip>
    </div>
  )

  /* end of Search */
}

function getIcon(isLoading) {
  return isLoading ? (
    <svg
      role="status"
      className="inline  ml-1 mr-1 w-5 h-5 text-white animate-spin"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="#E5E7EB"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentColor"
      />
    </svg>
  ) : (
    <FontAwesomeIcon
      className="inline  ml-1 mr-1 w-5 h-5 text-white"
      aria-hidden="true"
      icon={faDownload}
    />
  )
}
