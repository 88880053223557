import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useHistory } from "react-router-dom";

interface BackButtonProps {
  buttonText?: string;
}

export const BackButton = ({ buttonText }: BackButtonProps) => {
  let history = useHistory();

  return (
    <button
      onClick={() => {
        history.goBack();
      }}
      className=" text-blue-500 background-transparent hyperlink px-3 py-1 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
      type="button"
    >
      <ChevronLeftIcon
        className={`h-4 w-3 mr-1 inline text-blue-400`}
        aria-hidden="true"
      />
      <span className="">{buttonText || "Back"}</span>
    </button>
  );
};
