import React, { useState } from "react";
import { useTaxEventsPagination } from "@moonscape/shared";

import { Button, Loading } from "@/components";
import { useTriggerTaxReport } from "../useTriggerTaxReport";
import { RecentActivityPagination } from "@/screens/TradesScreen";
import { useCapitalGainsData } from "@moonscape/shared";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { TaxEventItemClass } from "../TaxEventIteamClass";
import { formatAmount } from "@/utils";
import { Skeleton } from "antd";

dayjs.extend(relativeTime);

// render 5 empty rows
let items = [
  {
    datetime: "",
    disposal_type: "",
    asset: "",
    tax_year: "",
    proceeds: "",
    gain: "",
    cost: "",
    fees: "",
    quantity: "",
  },
];

const taxEventTableHeadings = [
  // render 5 empty rows
  "Disposal Type",
  "Asset",
  "Tax Year",
  "Proceeds",
  "Gain",
  "Cost",
  "Fees",
  "Quantity",
  "Date",
  "", // empty
];

export const TaxEventHeadings = () => (
  <thead className="bg-gray-100">
    <tr>
      {taxEventTableHeadings.map((heading, index) => (
        <th
          key={index}
          className="py-3 px-4 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider border-b border-gray-200"
        >
          {heading}
        </th>
      ))}
    </tr>
  </thead>
);

export const USTaxEventRow = (props) => {
  const t = new TaxEventItemClass(props);
  if (props.isEmpty)
    return (
      <tr key={t?.txn_id} className="hover:bg-gray-200">
        <td
          colSpan={9}
          className="px-3 py-2 whitespace-nowrap text-center border-b border-gray-200"
        >
          {props.text}
        </td>
      </tr>
    );
  const loading = props.isQueryLoading;
  return (
    <tr
      key={props.isQueryLoading ? props.index.toString() : t?.txn_id}
      className="hover:bg-gray-200"
    >
      {/* disposal_type */}
      <td className="px-3 py-2 whitespace-nowrap text-left border-b border-gray-200">
        <div className="flex items-center">
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-600">
              {loading ? (
                <Skeleton.Button active={true} size={"small"} shape={"round"} />
              ) : (
                t?.disposal_type
              )}
              {<br />}
              {loading ? (
                <Skeleton.Button active={true} size={"small"} shape={"round"} />
              ) : (
                t.truncate(t?.txn_id, 10)
              )}
            </div>
          </div>

          <div></div>
        </div>
      </td>
      {/* asset */}
      <td className="py-2 whitespace-nowrap text-center lg:px-4 border-b border-gray-200">
        <div className="text-sm text-gray-700">
          {loading ? (
            <Skeleton.Button active={true} size={"small"} shape={"round"} />
          ) : (
            t?.asset
          )}
        </div>
      </td>
      {/* tax_year */}
      <td className="px-4 py-2 whitespace-nowrap text-center border-b border-gray-200">
        <div className="text-sm text-gray-900">
          {loading ? (
            <Skeleton.Button active={true} size={"small"} shape={"round"} />
          ) : (
            t?.tax_year
          )}
        </div>
      </td>
      {/* proceeds */}
      <td className="px-4 py-2 whitespace-nowrap text-center border-b border-gray-200">
        <div className="text-sm text-gray-900">
          {formatAmount(
            loading ? (
              <Skeleton.Button active={true} size={"small"} shape={"round"} />
            ) : (
              t?.proceeds
            )
          )}
        </div>
      </td>
      {/* gain */}
      <td className="px-4 py-2 whitespace-nowrap text-center border-b border-gray-200">
        <div className="text-sm text-gray-900">
          {formatAmount(
            loading ? (
              <Skeleton.Button active={true} size={"small"} shape={"round"} />
            ) : (
              t?.gain
            )
          )}
        </div>
      </td>
      {/* cost */}
      <td className="px-4 py-2 whitespace-nowrap text-center border-b border-gray-200">
        <div className="text-sm text-gray-900">
          {formatAmount(
            loading ? (
              <Skeleton.Button active={true} size={"small"} shape={"round"} />
            ) : (
              t?.cost
            )
          )}
        </div>
      </td>
      {/* fees */}
      <td className="px-4 py-2 whitespace-nowrap text-center border-b border-gray-200">
        <div className="text-sm text-gray-900">
          {formatAmount(
            loading ? (
              <Skeleton.Button active={true} size={"small"} shape={"round"} />
            ) : (
              t?.fees
            )
          )}
        </div>
      </td>
      {/* quantity */}
      <td className="px-4 py-2 whitespace-nowrap text-center border-b border-gray-200">
        <div className="text-sm text-gray-900">
          {loading ? (
            <Skeleton.Button active={true} size={"small"} shape={"round"} />
          ) : (
            t?.getFormattedQuantity()
          )}
        </div>
      </td>
      {/* datetime */}
      <td className="py-2 whitespace-nowrap text-center lg:px-4 border-b border-gray-200">
        <div className="text-sm text-gray-700">
          {loading ? (
            <Skeleton.Button active={true} size={"small"} shape={"round"} />
          ) : (
            t?.formattedTimestamp()
          )}
        </div>
      </td>
      {/* see more */}
      <td className="px-4 py-2 whitespace-nowrap text-center border-b border-gray-200">
        <FontAwesomeIcon icon={faEllipsisV} className="text-gray-600" />
      </td>
    </tr>
  );
};

// copied from TradeList (TradeTable)
const TaxEventTable = ({ children }) => {
  return (
    <>
      {/* for sm screens only  */}
      <div className="mt-4 flex flex-col">
        <div className=" sm:block md:hidden">
          <div className="grid grid-cols-1 gap-4 ">
            {/* <TradeListError {...tradeListErrorProps} /> */}
            <p> error </p>
            <div className="px-4 rounded-lg shadow ">
              {items && items?.length ? (
                items?.map((txn, index) => (
                  <USTaxEventRow screen="sm" {...txn} key={index} />
                ))
              ) : (
                <USTaxEventRow screen="sm" isEmpty={true} text="No results" />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* md and lg screens */}
      <div className="items-center max-w-screen-2xl mx-auto mt-2  ">
        <div className=" hidden md:block lg:block">
          <div className=" align-middle inline-block min-w-full ">
            <div
              className="overflow-x-auto
              rounded-lg shadow  border-gray-200 sm:rounded-lg "
            >
              {/* <TradeListError {...tradeListErrorProps} /> */}
              {/* <p> error </p> */}

              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const USTaxEvents = ({ taxYear }) => {
  const {
    taxEvents,
    isQueryLoading,
    error,
    // items,
    // pagination handlers
    previousPagination,
    nextPagination,
    resetPagination,
    // pagination data
    hasMore,
    remaining,
    page,
    isPreviousData,
  } = useTaxEventsPagination({
    taxYear: taxYear,
    onLoading: () => {
      // console.log("onLoadingHandler");
    },
  });
  const { recalculateTax } = useTriggerTaxReport();

  console.log("[@moonscape/shared] TaxEvents", taxEvents);

  items = taxEvents?.items;

  // get the last updated data
  const { cgt_report: cgt } = useCapitalGainsData({
    taxYear: taxYear,
  });

  const lastUpdatedTaxReport = dayjs(dayjs(cgt?.updated_at)).fromNow();

  // items = Array(16).fill(items[0]);

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:fle<UKx-auto">
          <h2 className="font-semibold leading-6 text-gray-900">Tax Events</h2>
          <p className="mt-2 text-sm text-gray-700">
            Your taxable disposals in the selected tax year
          </p>
        </div>
        {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button
            onClick={async () => {
              await recalculateTax();
            }}
            buttonText={"Re-calculate tax"}
          />
          <p className="text-xs mt-1 text-gray-600">
            Last updated: {lastUpdatedTaxReport}
          </p>
        </div> */}
      </div>
      <TaxEventTable>
        <table className="w-full border border-collapse table-auto min-w-full">
          <TaxEventHeadings />

          <tbody className=" bg-white divide-y divide-gray-200">
            {isQueryLoading ? (
              Array(3)
                .fill(0)
                .map((item, index) => {
                  return (
                    <USTaxEventRow
                      index
                      screen="md"
                      isQueryLoading={isQueryLoading}
                      key={index}
                    />
                  );
                })
            ) : items && items?.length ? (
              items?.map((te, index) => {
                return <USTaxEventRow screen="md" {...te} key={index} />;
              })
            ) : (
              <USTaxEventRow
                screen="md"
                isEmpty={true}
                text={`No disposal in ${taxYear}`}
              />
            )}
          </tbody>
        </table>
      </TaxEventTable>
      {console.log("remaining", remaining)}

      <RecentActivityPagination
        next={nextPagination}
        prev={previousPagination}
        isPreviousData={isPreviousData}
        hasMore={hasMore}
        remaining={remaining}
        trades={taxEvents}
        resetPagination={resetPagination}
        page={page}
      />
      <div className="sm:flex sm:items-center pb-12">
        <div className="sm:flex-auto">
          {/* <p className="px-4 mt-2 text-sm text-gray-500">some footer text</p> */}
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
      </div>
    </>
  );
};
