import { useState, useRef, useEffect } from "react";
import { useTradesData } from "./useTradesData";


export const useTradesPagination = ({
  exchange,
  asset,
  isSearching,
  searchedValue,
  onLoading,
}) => {
  // cursor for pagination
  const [page, setPage] = useState(0);
  const cursorStack = useRef<number[]>([]);

  let { isQueryLoading, isIdle, error, trades, isPreviousData, isFetching, ref , refetch} =
    useTradesData(page, exchange, asset, isSearching, searchedValue, onLoading);

  const { items, cursor, remaining } = trades || {};
  const hasMore = trades?.remaining > 0;

  const resetPagination = (refresh?: boolean) => {
    setPage(0);
    cursorStack.current = [0];
    if(refresh)
      refetch()
  };

  const nextPagination = () => {
    if (!isPreviousData && hasMore) {
      const newPage = cursor;
      setPage(newPage);
      cursorStack.current = [...cursorStack.current, newPage];
    }
  };

  const previousPagination = () => {
    if (cursorStack.current.length <= 2) {
      resetPagination();
    } else {
      const temp = cursorStack.current.slice(0, -2);
      const newPage = temp[temp.length - 1];
      setPage(newPage);
      cursorStack.current = temp;
    }
  };

  return {
    trades,
    isQueryLoading,
    error,
    items,
    previousPagination,
    nextPagination,
    resetPagination,
    hasMore,
    remaining,
    page,
    isPreviousData,
  };
};
