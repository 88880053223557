import React, { useEffect, useState } from "react"

import { Screen } from "@/components"

import {
  RecentActivity,
  RecentActivityPagination,
} from "@/screens/TradesScreen"
import { useRawTransactionsPagination } from "@/hooks/useRawTransactionsPagination"
import { AuditTradeList } from "@/components/TradeList/AuditTradeList"

import { TransactionAuditContext } from "@/contexts"
import { clsx } from "clsx"
import { RawTradeList } from "@/components/TradeList/RawTradeList"
import { useParams } from "react-router-dom"

export const RawTransactionsScreen = () => {
  const { exchange } = useParams<{ exchange: string }>()

  const {
    rawTransactions,
    isQueryLoading,
    error,
    items,
    // pagination handlers
    previousPagination,
    nextPagination,
    resetPagination,
    // pagination data
    hasMore,
    remaining,
    page,
    isPreviousData,
  } = useRawTransactionsPagination({
    exchange: exchange,
    asset: null,
    isSearching: false,
    searchedValue: null,
    onLoading: () => {
      // onLoadingHandler();
      // console.log("onLoadingHandler");
    },
  })

  const [isModalOpen, setModalOpen] = useState(false)
  const [extTxnId, setExtnTxnId] = useState(null)
  console.log("transaction: extTxnId", extTxnId)

  useEffect(() => {
    if (extTxnId) {
      setModalOpen(true)
    }
  }, [extTxnId])

  console.log("rawTransactions", rawTransactions)

  const disabled = true

  return (
    <TransactionAuditContext.Provider value={{ setModalOpen, setExtnTxnId }}>
      <div className=" min-h-screen flex-1 flex flex-col overflow-hidden">
        <Screen heading="Raw Exchange Transactions">
          <main className="pb-8 z-0 overflow-y-auto">
            <div
              className="mt-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-5 space-y-2"
              role="alert"
            >
              <p className="font-bold text-lg mb-2">You're Almost Set!</p>
              <p>
                Here's a quick update: This section displays the{" "}
                <strong>
                  raw data imported directly from your exchange accounts
                </strong>
                . It's unprocessed and might occasionally miss some tax-related
                details. 🧐
              </p>
              <p>
                What's next? <strong>We're on it!</strong> Our system will
                process this data, filling in any missing historical rate
                information to ensure your taxes are calculated accurately.
              </p>
              <p>
                Meanwhile, feel free to <strong>review this section</strong>.
                It's here for your reference, giving you insight into the data
                we're using. If you notice anything amiss or if there's data we
                haven't processed yet, please update the necessary details.
              </p>
              <p>
                Your involvement is crucial to ensure everything is spot-on.{" "}
                <strong>Thanks for staying engaged and informed!</strong>
              </p>
            </div>

            <h2 className=" mx-auto mt-6 px-4 sm:px-6 lg:px-8 text-lg leading-6 mb-2 font-medium text-gray-900">
              Transaction data imported from {exchange}
            </h2>

            {/* pills for missing txns values etc  */}
            {/* <AuditFilterPills /> */}

            {/* <div className="max-w-7xl mx-auto py-4  px-4 sm:px-6 lg:px-8"> */}

            <div className="flex flex-row">
              <RecentActivity
                isSearching={false}
                searchedValue={null}
                trades={rawTransactions}
                previousPagination={previousPagination}
                nextPagination={nextPagination}
                resetPagination={resetPagination}
                hasMore={hasMore}
                remaining={remaining}
                page={page}
                isPreviousData={isPreviousData}
                isQueryLoading={isQueryLoading}
                error={error}
                exchange={null}
                asset={null}
              >
                <RawTradeList
                  // exchange={props?.exchange}
                  // asset={props?.asset}
                  // isSearching={props?.isSearching}
                  // searchedValue={props?.searchedValue}

                  onLoading={() => {
                    console.log("TradesScreen onLoadingHandler")
                  }}
                  // hasLinkedExchangeAccount={props?.hasLinkedExchangeAccount}
                  // trades={props?.trades}
                  // previousPagination={props?.previousPagination}
                  // nextPagination={props?.nextPagination}
                  // resetPagination={props?.resetPagination}
                  // hasMore={props?.hasMore}
                  // remaining={props?.remaining}
                  // page={props?.page}
                  // isPreviousData={props?.isPreviousData}
                  // isQueryLoading={props?.isQueryLoading}
                  // error={props?.error}
                />
              </RecentActivity>
            </div>
          </main>
        </Screen>
        <div className="border-t border-gray-200 sticky top-[100vh] pr-24">
          <RecentActivityPagination
            next={nextPagination}
            prev={previousPagination}
            isPreviousData={isPreviousData}
            hasMore={hasMore}
            remaining={remaining}
            trades={rawTransactions}
            resetPagination={resetPagination}
            page={page}
          />
        </div>
      </div>
    </TransactionAuditContext.Provider>
  )
}

const AuditFilterPills = ({ disabled }) => {
  return (
    <div className=" mx-auto py-4 px-4 sm:px-6 lg:px-8 flex gap-4">
      {/* container */}
      <button
        type="button"
        className="rounded-full bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-2"
      >
        Missing transaction values
      </button>
      <button
        type="button"
        disabled={true}
        className={clsx(
          "rounded-full px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-2",
          {
            "bg-indigo-600": !disabled,
            "bg-gray-400": disabled,
          }
        )}
      >
        Missing deposits or withdrawals
      </button>
      <button
        type="button"
        disabled={true}
        className={clsx(
          "rounded-full px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-2",
          {
            "bg-indigo-600": !disabled,
            "bg-gray-400": disabled,
          }
        )}
      >
        Sell and buy imbalance
      </button>
    </div>
  )
}
