import React, { useState } from "react";

// use our axios that has the interceptors
// import { axios } from "../axios";
// TODO: have the intercept either work for non authed routes or redirect maybe
import { TradeListError } from "@/components";
import {
  AuditTradeItem,
  AuditTradeHeadings,
} from "@/components/TradeList/AuditTradeRow";

export const AuditTradeList = ({
  exchange,
  asset,
  isSearching,
  trades,
  isQueryLoading,
  hasLinkedExchangeAccount,
  error,
}) => {
  console.log("[TradeList] trades useTradesData()", trades);
  // console.log("useTradesData exchange", exchange);
  // console.log("useTradesData asset", asset);
  // console.log("isQueryLoading", isQueryLoading);

  const [isError, setIsError] = useState(false);

  let items = trades?.items;

  console.log("audit items: ", items);

  const tradeListErrorProps = {
    error,
    isSearching,
    setIsError,
    trades,
    items,
  };

  const TradeTable = ({ children }) => {
    return (
      <>
        <div className="mt-4 flex flex-col">
          {/* for sm screens only  */}
          <div className=" sm:block md:hidden">
            <div className="grid grid-cols-1 gap-4 ">
              <TradeListError {...tradeListErrorProps} />
              <div className="px-4 rounded-lg shadow ">
                {isQueryLoading ? (
                  Array(5)
                    .fill(0)
                    .map((item) => (
                      <AuditTradeItem
                        screen="md"
                        isQueryLoading={isQueryLoading}
                      />
                    ))
                ) : items?.length > 0 ? (
                  items?.map((txn) => {
                    return (
                      <AuditTradeItem key={txn.id} screen="md" txn={txn} />
                    );
                  })
                ) : (
                  <AuditTradeItem
                    screen="md"
                    text="No results"
                    isEmpty={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* md and lg screens */}
        <div className="items-center  mx-auto mt-2 px-4 sm:px-6 lg:px-8">
          <div className=" hidden md:block lg:block">
            <div className=" align-middle inline-block min-w-full ">
              <div
                className="overflow-x-auto
              rounded-lg shadow border-b border-gray-200 sm:rounded-lg "
              >
                <TradeListError {...tradeListErrorProps} />

                {children}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <TradeTable>
      <table className="w-full border border-collapse table-auto min-w-full">
        <AuditTradeHeadings />
        <tbody className=" bg-white divide-y divide-gray-200">
          {isQueryLoading ? (
            Array(5)
              .fill(0)
              .map((item) => (
                <AuditTradeItem screen="md" isQueryLoading={isQueryLoading} />
              ))
          ) : items?.length > 0 ? (
            items?.map((txn) => {
              return (
                <AuditTradeItem
                  key={txn.id}
                  screen="md"
                  txn={txn}
                  isQueryLoading={isQueryLoading}
                />
              );
            })
          ) : (
            <AuditTradeItem
              screen="md"
              isEmpty={true}
              text="No results"
              isQueryLoading={isQueryLoading}
            />
          )}
        </tbody>
      </table>
    </TradeTable>
  );
};
