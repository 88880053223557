import React, { Fragment, useState, useRef, useCallback } from "react";
import {
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { APIKeySettingsPane } from "./APIKeySettingsPane";

import { Tabs } from "@/components";

import { classNames } from "@/utils";
import { AddExchange } from "./NewExchange";
import { TaxSettings } from "./TaxSettings";
import { SettingsTabs } from "./SettingsTabs";
import { ReferralSettings } from "./ReferralSettings";

export const SettingsContent = (props) => {
  // route match for path matching for Tabs
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();

  const tabs = [
    { name: "Exchange Integrations", href: `${path}/exchange`, current: true },
    { name: "Tax", href: `${path}/tax`, current: false },
    { name: "Refer a friend", href: `${path}/refer`, current: false },
  ];

  const [activeTab, setActiveTab] = useState(
    tabs.filter((tab) => tab.href === pathname)
  );

  console.log(
    "defaultValue ",
    pathname,
    path,
    url,
    tabs.find((tab) => pathname.includes(tab.href))?.href
  );

  console.log("active tab", activeTab);

  console.log("location", pathname);

  console.log("current active tab", activeTab);

  console.log("path", path);

  return (
    <div className="flex-1 flex flex-col  bg-white">
      <main className="flex-1 overflow-y-auto focus:outline-none lg:border-t lg:border-gray-200">
        <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
          <div className="pt-5 pb-16">
            <div className="px-4 sm:px-6 md:px-0">
              {/* <h1 className="text-3xl font-extrabold text-gray-900">
                Settings
              </h1> */}
            </div>
            <div className="px-4 sm:px-6 md:px-0">
              <div className="py-6">
                {/* Tabs */}

                <SettingsTabs
                  pathname={pathname}
                  tabs={tabs}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />

                {/* <Tabs> */}
                <Switch>
                  <Route path={`${path}`} exact>
                    <p>/settings</p>
                  </Route>
                  <Route path={`${path}/exchange`}>
                    <APIKeySettingsPane />
                  </Route>
                  <Route path={`${path}/tax`}>
                    <TaxSettings />
                  </Route>
                  <Route path={`${path}/refer`}>
                    <ReferralSettings />
                  </Route>

                  <Route render={() => <h1>Not found!</h1>} />
                </Switch>
                {/* </Tabs> */}
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </main>
    </div>
  );
};
