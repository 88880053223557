import React, { Fragment, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { useSingleTransactionData } from "@moonscape/shared";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { TransactionDetail } from "./TransactionDetail";
import { AuditTradeItemClass } from "@moonscape/shared";

export const TransactionAuditModal = ({ open, setOpen, extTxnId, page }) => {
  const history = useHistory();

  const { transaction, isQueryLoading } = useSingleTransactionData(
    extTxnId,
    () => console.log("loading transaction:")
  );
  // create an AuditItemClass instance
  const auditTransaction = new AuditTradeItemClass(transaction);

  if (isQueryLoading) {
    console.log("transaction modal loading ", isQueryLoading);
  }

  const keysToDisplay = {
    tradeid: "44529b98-62a8-5dde-9523-ec579923ca8c",

    datetime: "2017-07-10T07:20:52Z",
    type: "sell",
    buyasset: "EUR",
    buyqty: 987.29,
    sellasset: "BTC",
    sellqty: 0.45571,
    feeasset: "EUR",
    feeqty: 14.93,
    exchange: "coinbase",

    price: "1002.22",
    final_value: 873.19,
    final_feevalue: 13.2,
    final_valuenote: "USED_FIAT_API",
    final_feevaluenote: null,
    missing_final_value: false,
    missing_final_feevalue: false,
  };

  console.log("transaction: ", transaction);

  const ModalContent = () => {
    return (
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
          <InformationCircleIcon
            className="h-6 w-6 text-blue-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            Transaction details
          </Dialog.Title>
          {/* <TransactionModalContentTable
          transaction={transaction}
          keysToDisplay={keysToDisplay}
        /> */}
          {isQueryLoading ? (
            <div className="grid h-12 place-items-center">
              {/* TODO: add loading component */}
              <p>Loading...</p>
            </div>
          ) : (
            <TransactionDetail
              transaction={auditTransaction}
              keysToDisplay={keysToDisplay}
              page={page}
            />
          )}
          {/* <TransactionModalContent /> */}
          <div className="mt-5 sm:mt-6">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-white border border-gray-300 px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                setOpen(false);
                // history.goBack(); // navigate back to the previous URL
              }}
            >
              Go back
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-3xl w-full max-w-3xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <ModalContent />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
