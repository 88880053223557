import React from "react";
import { CoinIcon } from "@/components/CoinIcon";

export const TradeItemSmall = ({ t, isAuditRow = false, auditReason = "", isQueryLoading }) => {
  return (
    <div
      className="col-span-1 flex border-1 shadow-sm
      divide-y divide-slate-100 border-b border-gray-200"
    >
      <div className="flex-1 flex items-center justify-between bg-white truncate">
        <TradeItemLeftComponent t={t} />
        <TradeItemRightComponent t={t} />
      </div>
      {isAuditRow && (
        <div className="px-4 py-2 text-xs text-gray-500">
          Audit Reason: {auditReason}
        </div>
      )}
    </div>
  );
};

export const TradeItemLeftComponent = ({ t }) => {
  const asset = t.getAsset();
  const formattedType = t.formattedType();
  const exchangeName = t.getExchangeName();
  const formattedTimestamp = t.formattedTimestamp();

  return (
    <div className="flex-1 px-4 py-2 text-sm truncate">
      <p className="text-gray-900 font-medium hover:text-gray-600">
        {asset && formattedType && `${asset} (${formattedType})`}
      </p>

      <div className="text-xs text-gray-500">
        {exchangeName && exchangeName}
        {<br />}
      </div>

      <p className="text-gray-400 text-xs font-normal ">
        {formattedTimestamp && formattedTimestamp}
      </p>
    </div>
  );
};

export const TradeItemRightComponent = ({ t }) => {
  const isInterestType = t.isInterestType();
  const isTransferType = t.isTransferType();
  const isTradeType = t.isTradeType();
  const formattedBuyAmountWithCurrency = t.formattedBuyAmountWithCurrency();
  const formattedSellAmountWithCurrency = t.formattedSellAmountWithCurrency();
  const formattedAmountWithCurrency = t.getFormattedAmountWithCurrency();
  const type = t.type;

  return (
    <div className="flex-shrink-0 pr-2 ">
      <div className="flex-1 px-4 py-2 items-end text-right text-sm truncate">
        {isInterestType && (
          <div>
            <p className="text-base	text-gray-900 font-medium ">
              {formattedBuyAmountWithCurrency}
            </p>
          </div>
        )}

        {isTransferType && (
          <div>
            <p className="text-base	text-gray-900 font-medium ">
              {type === "deposit" && formattedAmountWithCurrency}
              {type === "withdrawal" && formattedAmountWithCurrency}
            </p>
          </div>
        )}
        {isTradeType && (
          <div>
            <a
              href="#"
              className="text-base	text-gray-900 font-medium  hover:text-gray-600"
            >
              {formattedBuyAmountWithCurrency}
            </a>

            <p className="text-gray-500">{formattedSellAmountWithCurrency}</p>
          </div>
        )}
      </div>
    </div>
  );
};
