import React, { useEffect, Fragment, useState } from "react";


import { useDebounce } from "./useDebounce";

export const useSearch = () => {
  const [searchedValue, setIsSearchingedValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setLoading] = useState(false);


  // console.log("searchValue is", searchedValue);

  const debounce = useDebounce();

  useEffect(() => {
    if (searchedValue.length === 0) {
      setIsSearching(false);
    }
  }, [searchedValue, isSearching, isLoading]);

  const onChangeHandler = (e) => {
    debounce(() => {
      setIsSearchingedValue(e.target.value);
      setIsSearching(true);
    });
  };

  const onLoadingHandler = (loading) => {
    setLoading(loading);
    console.log("loading value", loading);
  };

  return {
    isSearching,
    // setIsSearching,
    searchedValue,
    onLoadingHandler,
    onChangeHandler,
    isLoading,
  };
};
