import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { PrivacyModeContext } from "@/contexts";
import clsx from "clsx";

import {
  ArrowsRightLeftIcon,
  ReceiptPercentIcon,
} from "@heroicons/react/24/outline";
import { TransactionListContext } from "@/contexts";
import { Skeleton } from "antd";

export const TradeItemMedium = ({ t, isQueryLoading }) => {
  const history = useHistory();
  const { setModalOpen, setExtnTxnId } = useContext(TransactionListContext);
  const { privacyMode, setPrivacyMode } = useContext(PrivacyModeContext);

  const handleRowClick = () => {
    // const goTo = `transaction/${t.ext_txn_id}`;
    setExtnTxnId(t.ext_txn_id);
    setModalOpen(true);
    // console.log("transaction: should go to ", goTo);
    // history.push(goTo);
  };

  return (
    <tr key={t.id} className="hover:bg-gray-100" onClick={handleRowClick}>
      {/* tradeid */}
      <td className="px-2 py-4 whitespace-nowrap text-left">
        <div className="flex items-center">
          <div className="px-4">
            <div className="text-sm text-gray-500">
              {isQueryLoading ? <Skeleton.Button active={true} size={'small'} shape={'round'} />: t.getExchangeName()}
              {<br />}

              <span className={clsx({ "blur-md": privacyMode })}>
                {isQueryLoading ? <Skeleton.Button active={true} size={'small'} shape={'round'} />: ""}
              </span>
            </div>
          </div>
        </div>
      </td>
      {/* type */}
      <td className="py-4 whitespace-nowrap text-center lg:px-6">
        <div className="text-sm font-medium text-gray-700">
          {isQueryLoading ? <Skeleton.Button active={true} size={'small'} shape={'round'} /> : t.formattedType()}
        </div>
        <div className="text-sm text-gray-500">{t.id}</div>
      </td>
      {/* side */}
      <td className=" py-4 whitespace-nowrap lg:px-6">
        <span
          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-${t.getSideColor()}-100 text-${t.getSideColor()}-800`}
        >
          {
            isQueryLoading ? <Skeleton.Button active={true} size={'small'} shape={'round'} /> :
            <>
              {t.side}
              {t.isTransferType() ? (
                <div className="flex-shrink-0 align-middle ">
                  <ArrowsRightLeftIcon
                    className={`h-5 w-5 text-${t.getTransferColor()}-400`}
                    aria-hidden="true"
                  />
                </div>
              ) : null}
              {t.isInterestType() ? (
                <div className="flex-shrink-0 align-middle ">
                  <ReceiptPercentIcon
                    className={`h-5 w-5 text-${t.getTransferColor()}-400`}
                    aria-hidden="true"
                  />
                </div>
                ) 
                : null
              }
            </>
          }
        </span>
      </td>
      {/* ccy1 */}
      <td className="px-6 py-4 whitespace-nowrap text-center">
        <div className="text-sm text-gray-900">{isQueryLoading ? <Skeleton.Button active={true} size={'small'} shape={'round'} /> : t.getAsset()}</div>
      </td>
      {/* ccy 2 */}
      <td className="px-6 py-4 whitespace-nowrap text-center">
        <div className="text-sm text-gray-900">{isQueryLoading ? <Skeleton.Button active={true} size={'small'} shape={'round'} /> : t.getCounterAsset()}</div>
      </td>
      {/* Amount */}
      <td className="px-6 py-4 whitespace-nowrap text-center">
        <div
          className={clsx("text-sm text-gray-900", { "blur-md": privacyMode })}
        >
          {isQueryLoading ? <Skeleton.Button active={true} size={'small'} shape={'round'} /> : t.formattedBuyAmountWithCurrency()}
        </div>
      </td>
      {/* Amount 2 */}
      <td className="px-6 py-4 whitespace-nowrap text-center">
        <div
          className={clsx("text-sm text-gray-900", { "blur-md": privacyMode })}
        >
          {isQueryLoading ? <Skeleton.Button active={true} size={'small'} shape={'round'} /> : t.formattedSellAmountWithCurrency()}
        </div>
      </td>
      {/* timestamp  */}
      <td className="px-6 py-4  text-sm text-gray-500 text-center flex gap-2">
        <span className={clsx({ "blur-md": privacyMode })}>
          {
            isQueryLoading ? <Skeleton.Button active={true} size={'small'} shape={'round'} /> : t.formattedDate()
          }
        </span>

        <span>{isQueryLoading ? <Skeleton.Button active={true} size={'small'} shape={'round'} /> : t.formattedTime()}</span>
      </td>
      {/* see more  */}
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
        <FontAwesomeIcon icon={faEllipsisV} />
      </td>
    </tr>
  );
};
