import React, { useState, useEffect, useContext } from "react";

import { Button, ButtonLink, Screen } from "@/components";

export const ProTaxScreen = (props) => {
  return (
    <Screen heading="Tax">
      <main className="mx-auto max-w-screen-2xl pb-8 z-0 overflow-y-auto"></main>
    </Screen>
  );
};
