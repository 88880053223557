import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Alert, AlertWithComponent, ButtonLink, Screen } from "@/components";
import {  useTaxOperationStatus } from "@/hooks";
import { clsx } from "clsx";
import { useTaxEventsPagination, useProfile} from "@moonscape/shared";
import { useTriggerTaxReport } from "../useTriggerTaxReport";
import { SettingsTabs } from "../../SettingsScreen/SettingsTabs";
import { Alert as OnboardingAlert } from "@/components/OnboardingAlert";

import dayjs from "dayjs";
import { TaxEventsTable } from "../components/TaxEventsTable";
import { IndividualInformation, TaxSummary } from "../components";
import { FAQs } from "../components/FAQ";

const CURRRENT_TAX_YEAR = "2024";

export const taxYears = [
  { taxYear: "2024/25", value: "2025" },
  { taxYear: "2023/24", value: "2024" },
  { taxYear: "2022/23", value: "2023" },
  { taxYear: "2021/22", value: "2022" },
  { taxYear: "2020/21", value: "2021" },
  { taxYear: "2019/20", value: "2020" },
  { taxYear: "2018/19", value: "2019" },
  { taxYear: "2017/18", value: "2018" },
  { taxYear: "2016/17", value: "2017" },
];

export const getTaxYearByValue = (value) => {
  const taxYearObj = taxYears.find((year) => year.value === value);
  return taxYearObj ? taxYearObj.taxYear : null;
};

export const UKTaxScreen = (props) => {
  let history = useHistory();
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch();
  const { operationStatus } = useTaxOperationStatus();
  const isTaxOperationRunning = operationStatus?.status === "running";
  const taxStartedTime = dayjs(operationStatus?.last_updated).fromNow();

  const [selectedTaxYear, setSelectedTaxYear] = useState(CURRRENT_TAX_YEAR);
  const tabs = [
    { name: "Capital gains", href: `${path}/cgt`, current: true },
    { name: "Income", href: `${path}/income`, current: false },
    { name: "Holdings", href: `${path}/holdings`, current: false },
    { name: "HMRC", href: `${path}/hmrc`, current: false },
  ];

  const [activeTab, setActiveTab] = useState(
    tabs.filter((tab) => tab.href === pathname)
  );
  const { profile } = useProfile();
  const hasLinkedExchangeAccount = profile?.hasLinkExchangeAccounts;

  const [showReviewTxns, setShowReviewTxns] = useState(0);
  const [showStats, setShowStats] = useState(true);
  const [showAlert, setShowAlert] = useState(true);
  const { taxEvents } = useTaxEventsPagination({ taxYear: selectedTaxYear });
  const { recalculateTax } = useTriggerTaxReport();
  const hasTaxEvents = taxEvents?.items?.length > 0;

  return (
    <Screen heading="Tax" className="bg-gray-50">
      <main className="mx-auto p-8">
        {!hasLinkedExchangeAccount && <OnboardingAlert />}

        {isTaxOperationRunning && (
          <AlertWithComponent>
            <p>
              Calculating tax report for {selectedTaxYear} in progress. Started{" "}
              {taxStartedTime}.
            </p>
          </AlertWithComponent>
        )}

        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col justify-between items-stretch bg-white p-6 px-8 rounded-lg ">
            <div className="flex justify-between items-start">
              <h2 className="text-xl font-bold text-gray-900">Tax Year</h2>
              <select
                id="taxyear"
                name="taxyear"
                className="form-select p-2 block w-1/4 mt-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 outline outline-2 outline-gray-100 outline-offset-2"
                value={selectedTaxYear}
                onChange={(e) => setSelectedTaxYear(e.target.value)}
              >
                {taxYears.map(({ taxYear, value }) => (
                  <option key={value} value={value}>
                    {taxYear}
                  </option>
                ))}
              </select>
            </div>
            <p className="text-grey-700">April 5 2022 - 5 April 2023</p>
          </div>

          {/* <SettingsTabs pathname={pathname} tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} /> */}

          {/* main content with blocks */}

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 my-6">
            <div className="flex flex-col space-y-4 lg:col-span-2 ">
              <div className="bg-white p-6 rounded-lg ">
                <h2 className="text-lg font-medium mb-6">Reminders</h2>
                <Alert variant="warning">
                  <p>
                    You have not reviewed your transactions for the selected tax
                    year.{" "}
                    <a
                      href="/review-transactions"
                      style={{ textDecoration: "underline" }}
                    >
                      Review now
                    </a>
                  </p>
                </Alert>
                <Alert variant="info">
                  <p>
                    You have not reviewed your transactions for the selected tax
                    year.{" "}
                  </p>
                </Alert>
              </div>

              <div className="bg-white p-6 rounded-lg  ">
                <h2 className="text-lg font-medium">Tax Events</h2>
                <TaxEventsTable taxYear={selectedTaxYear} />
              </div>
            </div>

            <div className="flex flex-col space-y-4">
              <div className="bg-white p-6 rounded-lg min-w-80  ">
                <TaxSummary
                  data={{
                    taxYear: selectedTaxYear, 
                    disposals: 2550,
                    proceeds: 420348.0,
                    allowance: 1299.28,
                    estimateGain: 2550.12,
                    estimateLoss: 1120.0,
                    estimateTax: 8138.92,
                  }}
                />
              </div>

              <div className="bg-white p-6 rounded-lg  min-w-80 ">
                <IndividualInformation
                  data={
                    // Example usage
                    {
                      currentIncomeTaxRate: "Basic Rate",
                      currentCGTTaxRate: "10%",
                      baseCurrency: "GBP",
                      costBasisMethod: "FIFO",
                      disposals: 2000,
                      estimateGain: 2550.12,
                      proceeds: 420348.0,
                      estimateLoss: 398.37,
                      allowance: 2348.0,
                      estimateTax: 1348.0,
                    }
                  }
                />
              </div>
              <div className="bg-white p-6 rounded-lg  min-w-80 ">
                <FAQs />
              </div>
            </div>
          </div>

          <Switch>
            <Route path={`${path}/cgt`} exact>
              {/* Tax CGT Page Content */}
            </Route>
            <Route path={`${path}/income`}>
              {/* Tax Income Page Content */}
            </Route>
            <Route path={`${path}/hmrc`}>{/* Tax HMRC Page Content */}</Route>
            <Route path={`${path}/holdings`}>
              {/* Tax Holdings Page Content */}
            </Route>
          </Switch>
        </div>
      </main>
    </Screen>
  );
};
