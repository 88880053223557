// https://auth0.com/blog/complete-guide-to-react-user-authentication/

import React, { useEffect } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import {
  Route,
  useParams,
  useLocation,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

import queryString from "query-string";

/**
 * issue with infinite reloading on login
 * https://github.com/auth0/auth0-react/issues/177
 */

export const ProtectedRoute = ({ component, ...args }) => {
  // force email verification on signup
  const params = useParams();
  const location = useLocation();
  const routeMatch = useRouteMatch();

  const history = useHistory();

  // get the error and error_description
  const values = queryString.parse(location?.search);

  const error = values?.error;
  const errorDescription = values?.error_description;

  const needsVerifyEmail =
    error === "access_denied" && errorDescription === "needsVerifyEmail";

  useEffect(() => {
    console.log("show screen that they should verify email", {
      params,
      location,
      routeMatch,
    });

    needsVerifyEmail && history.push("/verify-email");
    // TODO: then have a button saying - OK i'm verified, try again
  }, [history, location, needsVerifyEmail, params, routeMatch]);

  console.log("ProtectedRoute args", args);
  return (
    <Route
      {...args}
      render={(props) => {
        // wrap the component inside the render function,
        // to stop withAuthenticationRequired getting recreated
        // on every render
        const Comp = withAuthenticationRequired(component, {
          onRedirecting: () => (
            <div className="grid h-screen place-items-center">
              {/* TODO: add loading component */}
              <p>Loading</p>
            </div>
          ),
        });
        return needsVerifyEmail ? null : <Comp {...props} />;
      }}
    />
  );
};
