// @ts-nocheck
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import { redirectUri } from "../constants";

export const Auth0ProviderWithHistory: React.FC<{}> = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const scope = "openid profile email read:positions";

  const history = useHistory();

  const onRedirectCallback = (appState) => {
    console.log("[oauth] react-router path:", window.location.pathname);
    // history.push(appState?.returnTo || window.location.pathname);
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  // check if running in cypress and set localstorage or memory
  let cache;
  // @ts-ignore
  if (window.Cypress) {
    cache = "localstorage";
  } else {
    cache = "memory";
  }
  // console.log("redirectUri", window.location.origin);
  console.log("port", window.location.port);

  const redirectUri = useMemo(() => {
    console.log(
      "[oauth] calling redirect uri",
      JSON.stringify(window.location)
    );
    if (process.env.REACT_APP_ENV === "dev") {
      return `${window.location.origin}`;
    } else {
      // prod
      window.gtag("event", "login", {});
      // track google event
      return `${window.location.origin}/`;
    }
  }, []);

  // console.log("redirectUri", redirectUri());

  return (
    // @ts-ignore
    <Auth0Provider
      domain={domain ?? "moonscape.app"}
      clientId={clientId}
      audience={audience}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: audience,
        scope: "openid profile email",
      }}
      /** By default, if the page url has code/state params, the SDK will treat them as Auth0's and attempt to exchange the code for a token. In some cases the code might be for something else (another OAuth SDK perhaps). In these instances you can instruct the client to ignore them eg

 */
      skipRedirectCallback={window.location.pathname === "/coinbase-auth"}
      onRedirectCallback={onRedirectCallback}
      // cacheLocation={cache}
    >
      {children}
    </Auth0Provider>
  );
};
