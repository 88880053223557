import React, { useEffect, useState, useContext, useCallback } from "react";

import { Field, useFormikContext } from "formik";

import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Link, useHistory } from "react-router-dom";
import { CoinbaseOAuthBox } from "../Exchange/CoinbaseOAuthBox";
import { CantFindExchangeInfo } from "./NewExchange";
import clsx from "clsx";
import { coinbaseAuthURL } from "@/utils";

const oauthAvailableExchanges = [
  { exchange: "coinbase", oauthUrl: coinbaseAuthURL },
];

/** this component return the <Form /> view components  */
export const APIKeyForm = ({
  errors,
  setErrors,
  setTouched,
  touched,
  setFieldValue,
  apiKeys,
  linkedExchanges,
}) => {
  const [isOauthAvailable, setIsOauthAvailable] = useState<boolean>(false);
  const [oauthUrl, setOauthUrl] = useState(null);
  const [selectedExchange, setSelectedExchange] = useState("");

  const history = useHistory();
  const { values } = useFormikContext();

  console.log("formik values", values);

  // State to control visibility of API key input fields
  const [showAPIKeyInput, setShowAPIKeyInput] = useState(true);

  const [showCoinbaseOAuthBox, setShowCoinbaseOAuthBox] = useState(true);

  const [hasUserAlreadySubmittedApiKeys, setHasUserAlreadySubmittedApiKeys] =
    useState(false);

  // Function to determine if an exchange is already linked with OAuth
  const isExchangeLinkedWithOAuth = useCallback(() => {
    return linkedExchanges?.oauth.some(
      (oauth) => oauth.exchange === selectedExchange
    );
  }, [linkedExchanges, selectedExchange]);

  // Form validation functions
  const validateKey = (value) =>
    value ? undefined : "API Key cannot be empty";
  const validateSecret = (value) =>
    value ? undefined : "API Secret cannot be empty";

  console.log("[APIKeyForm] formik errors", errors);
  console.log("[APIKeyForm] formik touched", touched);

  const inputBaseClasses =
    "mt-1 block w-full rounded-lg border border-gray-300 shadow-sm py-4 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm";

  const showSigninWithCoinbase =
    isOauthAvailable &&
    oauthUrl &&
    // !isExchangeLinkedWithOAuth(selectedExchange) &&
    showCoinbaseOAuthBox;

  const spacingClasses = showSigninWithCoinbase ? "py-4 mt-6" : "py-4";

  const secretString = "🔒 Secret secured and not displayed"
  // use for rerender apiSecret display
  useEffect(() => {
    const existingApiKey = apiKeys?.find(
      ({ exchange }) => exchange === selectedExchange
    );
    if (isEditAPIKeyStatus(existingApiKey)) {
      setFieldValue("apiSecret", "");
    } else {
      if (existingApiKey)
        setFieldValue("apiSecret", secretString);
    }
  }, [values?.apiKey]);

  function isEditAPIKeyStatus(existingApiKey) {
    existingApiKey = existingApiKey || apiKeys?.find(
      ({ exchange }) => exchange === selectedExchange
    );
    if (!existingApiKey) return false;
    return values?.apiKey !== existingApiKey?.key;
  }
  // use for rerender apiSecret display

  return (
    <div className="mt-6 mb-6">
      <div className={`${spacingClasses}`}>
        <Field
          as="select"
          id="exchange"
          name="exchange"
          autoComplete="exchange"
          className={`${inputBaseClasses}`}
          // onBlur={handleBlur}
          onChange={(e, selected) => {
            // check if there are `apikeys` for the selected exchange

            // clear errors from previous selected exchange
            setErrors({});
            console.log("setErrors {}");

            // clear oauth url
            setIsOauthAvailable(false);
            setOauthUrl(null);

            const selectedExchange = e.target.value;
            setSelectedExchange(selectedExchange);

            const existingApiKey = apiKeys?.find(
              ({ exchange }) => exchange === selectedExchange
            );

            const isOAuthLinked = isExchangeLinkedWithOAuth();
            // Hide API key inputs if the selected exchange is linked with OAuth
            setShowAPIKeyInput(!isOAuthLinked);

            if (existingApiKey) {
              // pre-populate form with existing keys
              console.log("[me] existingApiKey", existingApiKey);
              setHasUserAlreadySubmittedApiKeys(true);

              setFieldValue("exchange", e.target.value);
              setFieldValue("apiKey", existingApiKey?.key);
              setFieldValue("apiSecret", secretString);
              setTouched({});
            } else {
              // no pre-existing key
              // also check if oauth is supported for this exchange
              // check if exchange has oauth
              setHasUserAlreadySubmittedApiKeys(false);
              const exchangeOauthUrl = oauthAvailableExchanges?.find(
                ({ exchange }) => exchange === selectedExchange
              );

              if (exchangeOauthUrl) {
                setIsOauthAvailable(true);
                setShowCoinbaseOAuthBox(true);
                setSelectedExchange(selectedExchange);
                setOauthUrl(exchangeOauthUrl?.oauthUrl);
              }
              console.log("[existingApiKey] false ", existingApiKey);
              // set exchange to selected exchange
              setFieldValue("exchange", e.target.value);
              // and empty out api key and secret (so it doesn't keep from previous state i.e. diff exchange)
              setFieldValue("apiKey", "");
              setFieldValue("apiSecret", "");

              setShowAPIKeyInput(true);
            }
          }}
        >
          <option value="">Select exchange...</option>

          <option value="coinbase">Coinbase</option>
          <option value="binance">Binance</option>
          <option value="kraken">Kraken</option>
          <option value="crypto.com">Crypto.com</option>
        </Field>
      </div>

      {isExchangeLinkedWithOAuth() && (
        <p>
          ✅{" "}
          {selectedExchange.charAt(0).toUpperCase() + selectedExchange.slice(1)}{" "}
          already linked with OAuth.
        </p>
      )}
      {showSigninWithCoinbase && (
        <CoinbaseOAuthBox
          dismissCoinbaseOAuthBox={() => {
            console.log("dismiss coinbase oauth box");
            setShowCoinbaseOAuthBox(false);
          }}
          selectedExchange={selectedExchange}
          oauthUrl={oauthUrl}
        />
      )}

      {/* right-hand side of form taking api key and secret */}
      {/* api key */}
      {showAPIKeyInput && (
        <>
          <div className={`${spacingClasses}`}>
            <Field
              // onInput={() => setCurrentAPIKey(undefined)}
              type="text"
              name="apiKey"
              id="apiKey"
              placeholder="Enter API key"
              validate={validateKey}
              className={clsx(inputBaseClasses, {
                "font-mono ": hasUserAlreadySubmittedApiKeys,
              })}
              // onBlur={handleBlur}
            />
            {/* {/ Show error if there is an error and the field has been touched /} */}
            {errors.apiKey && touched.apiKey && (
              <p className="mt-2 text-sm text-red-600">{errors.apiKey}</p>
            )}
          </div>
          {/* api secret */}
          <div className={"flex relative py-1 pb-4"}>
            <Field
              type="text"
              name="apiSecret"
              id="apiSecret"
              placeholder="Enter API secret"
              validate={validateSecret}
              className={clsx(
                inputBaseClasses,
                isEditAPIKeyStatus(undefined)
                  ? { "font-mono ": hasUserAlreadySubmittedApiKeys }
                  : {
                      "font-mono ": hasUserAlreadySubmittedApiKeys,
                      "text-gray-400": hasUserAlreadySubmittedApiKeys,
                    }
              )}
              disabled={hasUserAlreadySubmittedApiKeys && !isEditAPIKeyStatus(undefined)}
              // onBlur={handleBlur}
            />
            {/* {/ Show error if there is an error and the field has been touched /} */}
            {errors.apiSecret && touched.apiSecret && (
              <p className="mt-2 text-sm text-red-600">{errors.apiSecret}</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};
