import React, { useState } from "react";

interface IPrivacyModeContext {
  setPrivacyMode: (year: boolean) => void;
  privacyMode: boolean;
}

export const PrivacyModeContext = React.createContext<IPrivacyModeContext>(
  {} as IPrivacyModeContext
);

export const PrivacyModeContextProvider = ({ children }) => {
  const [privacyMode, setPrivacyMode] = useState<boolean>(false);

  return (
    <PrivacyModeContext.Provider value={{ setPrivacyMode, privacyMode }}>
      {children}
    </PrivacyModeContext.Provider>
  );
};
