import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ArrowsRightLeftIcon,
  ReceiptPercentIcon,
} from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

export const AuditTradeItemSmall = ({
  t,
  isAuditRow = false,
  auditReason = "",
  isQueryLoading
}) => {
  const asset = t.getAsset();
  const formattedType = t.formattedType();
  const exchangeName = t.getExchangeName();
  const formattedTimestamp = t.formattedTimestamp();
  const isInterestType = t.isInterestType();
  const isTransferType = t.isTransferType();
  const isTradeType = t.isTradeType();
  const formattedBuyAmountWithCurrency = t.formattedBuyAmountWithCurrency();
  const formattedSellAmountWithCurrency = t.formattedSellAmountWithCurrency();
  const formattedAmountWithCurrency = t.getFormattedAmountWithCurrency();

  isAuditRow = true;
  auditReason = "this is the reason";
  const type = t.type;

  return (
    <div
      className="col-span-1 flex-row flex-end border-1 shadow-sm
      divide-y divide-slate-200  bg-white border-b border-gray-200 "
    >
      {/* <CoinIcon
        className="h-5 w-5"
        // viewBox="0 0 10 10"
        name={asset?.toLowerCase()}
      /> */}
      <div>
        <div className=" flex items-center justify-between  ">
          {/* left align  */}
          <div className="px-4 pt-2 text-sm ">
            <p className="text-gray-900 font-medium hover:text-gray-600">
              {asset && formattedType && `${asset} (${formattedType})`}
            </p>

            <div className="text-xs text-gray-500">
              {exchangeName && exchangeName}
              {<br />}
            </div>

            <p className="text-gray-400 text-xs font-normal ">
              {formattedTimestamp && formattedTimestamp}
            </p>
          </div>
          {/* ============================================================ */}
          {/* right align  */}
          <div className="flex-shrink-0 pr-2 ">
            <div className="flex-1 px-4 py-2 items-end text-right text-sm truncate">
              {isInterestType && (
                <div>
                  <p className="text-base	text-gray-900 font-medium ">
                    {formattedBuyAmountWithCurrency}
                  </p>
                </div>
              )}

              {isTransferType && (
                <div>
                  <p className="text-base	text-gray-900 font-medium ">
                    {type === "deposit" && formattedAmountWithCurrency}
                    {type === "withdrawal" && formattedAmountWithCurrency}
                  </p>
                </div>
              )}
              {isTradeType && (
                <div>
                  <a
                    href="#"
                    className="text-base	text-gray-900 font-medium  hover:text-gray-600"
                  >
                    {formattedBuyAmountWithCurrency}
                  </a>

                  <p className="text-gray-500">
                    {formattedSellAmountWithCurrency}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {isAuditRow && (
          <div className="flex items-center px-4 pb-2 text-xs text-red-500 justify-start space-x-2">
            <ExclamationTriangleIcon
              className="h-3 w-3 text-yellow-400"
              aria-hidden="true"
            />
            <span>Audit Reason: {auditReason}</span>
          </div>
        )}
      </div>
    </div>
  );
};
