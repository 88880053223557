import React from "react";
import { page as analyticsPage } from "@/analytics";

import "./RecentActivity.css";

interface Props {
  exchange?: string;
  asset?: string;
  isSearching: boolean;
  searchedValue: string;
  hasLinkedExchangeAccount: boolean;
  trades;
  // pagination handlers
  previousPagination;
  nextPagination;
  resetPagination;
  // pagination data
  hasMore;
  remaining;
  page;
  isPreviousData;
  isQueryLoading;
  error;
  // take child component as a render prop
  children;
}

export const RecentActivity = ({
  children,
  exchange,
  asset,
  isSearching,
  searchedValue,
  hasLinkedExchangeAccount,
  trades,
  // pagination handlers
  previousPagination,
  nextPagination,
  resetPagination,
  // pagination data
  hasMore,
  remaining,
  page,
  isPreviousData,
  isQueryLoading,
  error,
}: Props) => {
  // track analytics
  analyticsPage();

  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          exchange,
          asset,
          isSearching,
          searchedValue,
          hasLinkedExchangeAccount,
          trades,
          previousPagination,
          nextPagination,
          resetPagination,
          hasMore,
          remaining,
          page,
          isPreviousData,
          isQueryLoading,
          error,
        });
      })}
    </>
  );
};
