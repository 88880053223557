import { FaceFrownIcon } from "@heroicons/react/24/solid";
import numbro from "numbro";

export const formatPrice = (price) => {
  const numberFormat =
    price < 1
      ? numbro(price).format({
          trimMantissa: false,
          mantissa: 8,
        })
      : numbro(price).format({
          trimMantissa: false,
          mantissa: 2,
        });

  return numberFormat;
};

/** format amounts 0.00 and trunc 0.10000000 to 0.1 BTC e.g. */
export const formatAmount = (amount, ccy, isFiatCurrency = false) => {
  const parsedAmount = parseFloat(amount);
  if (Number.isNaN(parsedAmount)) {
    return amount;
  }
  let numberFormat;
  if (isFiatCurrency) {
    numberFormat = numbro(parsedAmount).format({
      thousandSeparated: true,
      mantissa: 2, // For fiat currencies, always use 2 decimal places
    });
  } else {
    numberFormat =
      amount < 1
        ? numbro(parsedAmount).format({
            trimMantissa: true,
            mantissa: 8,
          })
        : numbro(parsedAmount).format({
            thousandSeparated: true,
            trimMantissa: false, // Set trimMantissa to false
            mantissa: 2,
          });
  }

  return ccy ? `${ccy} ${numberFormat}` : numberFormat;
};

export const formatInteger = (amount, ccy) => {
  const parsedAmount = parseFloat(amount);
  if (Number.isNaN(parsedAmount)) {
    return amount;
  }
  const numberFormat = numbro(parsedAmount).format({
    trimMantissa: true,
    mantissa: 0,
  });

  return numberFormat;
};
export const formatCurrency = (amount, ccy = "£") => {
  const parsedAmount = parseFloat(amount);
  if (Number.isNaN(parsedAmount)) {
    return amount;
  }
  const numberFormat =
    amount < 1
      ? numbro(parsedAmount).format({
          trimMantissa: true,
          mantissa: 8,
        })
      : numbro(parsedAmount).format({
          thousandSeparated: true,
          trimMantissa: true,
          mantissa: 2,
        });

  return `${ccy}${numberFormat}`;
};

export const formatCurrencySummary = (amount, ccy = "£") => {
  const parsedAmount = parseFloat(amount);
  if (Number.isNaN(parsedAmount)) {
    return amount;
  }
  const abs_amount = Math.abs(parseInt(amount));
  let numberFormat;
  if (abs_amount >= 1e12) {
    numberFormat = (abs_amount / 1e12).toFixed(2) + "t";
  } else if (abs_amount >= 1e9) {
    numberFormat = (abs_amount / 1e9).toFixed(2) + "b";
  } else if (abs_amount >= 1e6) {
    numberFormat = (abs_amount / 1e6).toFixed(2) + "m";
  } else if (abs_amount >= 1e3) {
    numberFormat = numberFormat = (abs_amount / 1e3).toFixed(2) + "k";
  } else if (abs_amount >= 1e2) {
    numberFormat = numbro(parsedAmount).format({
      thousandSeparated: true,
      trimMantissa: true,
      mantissa: 1,
    });
  } else if (abs_amount >= 10) {
    numberFormat = numbro(parsedAmount).format({
      thousandSeparated: true,
      trimMantissa: true,
      mantissa: 2,
    });
  } else if (abs_amount > 1)
    numberFormat = numbro(parsedAmount).format({
      trimMantissa: true,
      mantissa: 3,
    });
  else
    numberFormat = numbro(parsedAmount).format({
      trimMantissa: true,
      mantissa: 3,
    });
  return `${ccy} ${numberFormat}`;
};

export const getCurrencySymbol = (baseCurrency) => {
  switch (baseCurrency) {
    case "GBP":
      return "£";
    case "USD":
      return "$";
    default:
      return "";
  }
};

export const formatNumberNanToZero = (value) => {
  return value | 0;
};
