import { ExchangeAPISettings } from "./Exchange";

import { NewExchange } from "./NewExchange";
import { Switch, Route, useRouteMatch } from "react-router-dom";

export const APIKeySettingsPane = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <ExchangeAPISettings />
      </Route>
      <Route exact path={`${path}/new`}>
        <NewExchange />
      </Route>
    </Switch>
  );
};
