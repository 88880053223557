import { assign, createMachine } from "xstate";
import { userOnboardingActions } from "@/machines/actions";
import { useHistory } from "react-router-dom";
/**
 *
 * user onboarding is for getting a user to link up an exchange and start using the app
 * states:
 * - first login
 * - navigate to settings
 * - add exchange (:exchange) param
 * - second login or visit
 * */

export const userOnboardingStates = {
  FIRST_LOGIN: "FIRST_LOGIN",
  NAVIGATE_TO_SETTINGS: "NAVIGATE_TO_SETTINGS",
  ADD_EXCHANGE: "ADD_EXCHANGE",
  SECOND_LOGIN_OR_VISIT: "SECOND_LOGIN_OR_VISIT",
};

export const getUserOnboardingMachine = (
  initialState = userOnboardingStates.FIRST_LOGIN
) =>
  createMachine(
    {
      // {https://xstate.js.org/docs/guides/actions.html#actions
      predictableActionArguments: true,

      // strict: true, // will throw errors for undefined states
      id: "userOnboarding",
      initial: initialState,
      context: {
        history: null, // We'll set this when we invoke the machine
      },
      states: {
        [userOnboardingStates.FIRST_LOGIN]: {
          entry: ["someAction"],
          on: {
            // this action is executed only on this transition
            // actions: (context, event) => {
            //   console.log("[xstate] activating...");
            // },

            // user logs in
            FIRST_LOGIN: userOnboardingStates.NAVIGATE_TO_SETTINGS,
            // adding everywhere since you should be able to add exchange from anywhere
            ADD_EXCHANGE: userOnboardingStates.ADD_EXCHANGE,
          },
        },
        [userOnboardingStates.NAVIGATE_TO_SETTINGS]: {
          entry: ["navigateToSettings", "updateStateOnBackend"],
          on: {
            // user navigates to settings
            NAVIGATE_TO_SETTINGS: userOnboardingStates.ADD_EXCHANGE,
            // adding everywhere since you should be able to add exchange from anywhere
            ADD_EXCHANGE: userOnboardingStates.ADD_EXCHANGE,
          },
        },
        [userOnboardingStates.ADD_EXCHANGE]: {
          // will get triggered each time state is entered
          entry: ["navigateToSettings"],
          on: {
            // user adds exchange

            // adding everywhere since you should be able to add exchange from anywhere
            ADD_EXCHANGE: userOnboardingStates.ADD_EXCHANGE,
            // ADD_EXCHANGE: userOnboardingStates.SECOND_LOGIN_OR_VISIT,
          },
        },
        [userOnboardingStates.SECOND_LOGIN_OR_VISIT]: {
          on: {
            // user logs in
            LOGIN: userOnboardingStates.SECOND_LOGIN_OR_VISIT,
            // user visits
            VISIT: userOnboardingStates.SECOND_LOGIN_OR_VISIT,
            // adding everywhere since you should be able to add exchange from anywhere
            ADD_EXCHANGE: userOnboardingStates.ADD_EXCHANGE,
          },
        },
      },
    },
    // machine actions
    {
      actions: userOnboardingActions as any,
    }
  );
