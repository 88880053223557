import React, { useContext } from "react";

import { TradesScreen } from "@/screens";

import { useProfile } from "@moonscape/shared";
import { Loading } from "@/components/Loading";

import { XstateContext } from "@/contexts";

export const LandingScreen = () => {
  // usePageTracking();
  /**
   * check if user has linked keys
   * display TradesScreen
   * else show OnboardingScreen
   */

  // use state machine to see where user is in the onboarding process
  // read last state from /profile hook or cookie for now
  /** globalServices hold a reference to all xstate services available */
  const globalServices = useContext(XstateContext);
  return <TradesScreen />;
}
