import { TaxEventRow } from "./TaxEventRow";
import { useTaxEventsPagination } from "@moonscape/shared";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import calender from "dayjs/plugin/calendar";

dayjs.extend(relativeTime);
dayjs.extend(calender);

export const TaxEventsTable = ({ taxYear }) => {
  const {
    taxEvents,
    isQueryLoading,
    error,
    previousPagination,
    nextPagination,
    resetPagination,
    hasMore,
    remaining,
    page,
    isPreviousData,
  } = useTaxEventsPagination({
    taxYear: taxYear,
    onLoading: () => {
      // console.log("onLoadingHandler");
    },
  });

  const items = taxEvents || [];

  console.log("[te] items", items );

  // Group tax events by date
  const groupedEvents = items.reduce((acc, event) => {
    const eventDate = dayjs(event.datetime);
    const formattedDate = eventDate.calendar(null, {
      sameDay: "[Today], DD MMM YYYY", // format for the same day
      nextDay: "[Tomorrow], DD MMM YYYY", // format for the next day
      nextWeek: "dddd, DD MMM YYYY", // format for the next week
      lastDay: "[Yesterday], DD MMM YYYY", // format for the previous day
      lastWeek: "[Last] dddd, DD MMM YYYY", // format for the previous week
      sameElse: "DD MMM YYYY", // format for any other day
    });

    const date = new Date(event.datetime).toLocaleDateString();
    console.log("date: ", formattedDate);
    if (!acc[formattedDate]) {
      acc[formattedDate] = [];
    }
    acc[formattedDate].push(event);
    return acc;
  }, {});

  if (Object.keys(groupedEvents).length === 0) {
    return <div className="text-center py-10">No tax events to show</div>;
  }

  return (
    <div>
      {Object.keys(groupedEvents).map((date) => {


        return (
          <div key={date}>
            <h3 className="text-sm font-medium my-2 text-gray-500">{date}</h3>
            {groupedEvents[date].map((taxEvent) => (
              <TaxEventRow key={taxEvent.txn_id} {...taxEvent} />
            ))}
          </div>
        );
      })}
    </div>
  );
};
