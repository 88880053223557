import React from "react";
import moment from "moment";
import { ProgressBar } from "./common/ProgressBar";
import { Button } from "./Button";

const TaxReportProgress = ({
    selectedTaxYear,
    current_year,
    operationStatus,
    isLoadingTaxCalcButton,
    setIsLoadingTaxCalcButton,
    lastUpdatedTaxReport,
    refetch,
  }) => {
    const taxYearEndDate = selectedTaxYear === current_year.toString()
      ? moment(new Date()).format('MMMM DD, YYYY')
      : `Dec 31 ${selectedTaxYear}`;
  
    const isTaxCalculating = operationStatus?.status === 'running' || isLoadingTaxCalcButton;
    const buttonText = isTaxCalculating ? 'Calculating tax' : 'Re-calculate tax';
  
    const handleButtonClick = () => {
      setIsLoadingTaxCalcButton(true);
    };
  
    const handleProgressBarCompletion = () => {
      if (operationStatus?.progress === 100) {
        console.log('refresh cgt report');
        refetch();
        setIsLoadingTaxCalcButton(false);
      }
      return operationStatus?.progress > 0 ? operationStatus?.progress : 10;
    };
  
    return (
        <div className="flex flex-col gap-4">
            <div className="flex justify-between">
                <p className="text-grey-700">
                Jan 1 {selectedTaxYear} - {taxYearEndDate}
                </p>
                <div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <Button
                        isLoading={isTaxCalculating}
                        color="bg-[#52c41a]"
                        onClick={handleButtonClick}
                        buttonText={buttonText}
                    />
                    <p className="text-xs mt-1 text-gray-600">
                        Last calculation: {lastUpdatedTaxReport}
                    </p>
                    </div>
                </div>
            </div>
            {
                operationStatus?.status === 'running' && (
                <ProgressBar
                    completed={handleProgressBarCompletion}
                    bgcolor="#52c41a"
                />
                )
            }
        </div>
    );
  };
  
export default TaxReportProgress;