import React, { useState } from "react";

// use our axios that has the interceptors
// import { axios } from "../axios";
// TODO: have the intercept either work for non authed routes or redirect maybe
import axiosLib from "axios";

import { TradeListError } from "@/components";
import {
  AuditTradeItem,
  AuditTradeHeadings,
} from "@/components/TradeList/AuditTradeRow";

import { LinkExchangeAccountPrompt } from "@/screens/TradesScreen";

import { createJSDocImplementsTag } from "typescript";
import { ReadyToDiveIn } from "@/screens/TradesScreen/ReadyToDiveIn";
import { useProfile } from "@moonscape/shared";

export const RawTradeItem = ({
  screen,
  isEmpty,
  text,
  rawTransaction,
  ...props
}) => {
  if (isEmpty || text) {
    return (
      <tr>
        <td colSpan="100%" className="text-center py-8">
          No data has been imported for this exchange.
        </td>
      </tr>
    );
  }

  const rawData = JSON.stringify(rawTransaction?.raw_data, null, 2); // Pretty print the JSON

  const exchange = rawTransaction?.exchange;

  return (
    <tr>
      {/* Render other trade data here */}
      {/* ... */}
      {/* Add a new cell for raw_data */}
      <td className="py-2 px-4 whitespace-pre-wrap text-sm text-gray-800">
        {exchange}
      </td>
      <td className="py-4 px-4 font-mono whitespace-pre-wrap text-sm text-gray-800">
        {rawData}
      </td>
    </tr>
  );
};

const tableHeadings = ["Exchange", "Raw data (JSON)"];

export const RawTradeHeadings = () => (
  <thead className=" ">
    <tr>
      {tableHeadings.map((heading) => (
        <th className="px-3 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 lg:px-6 ">
          {heading}
        </th>
      ))}
    </tr>
  </thead>
);

export const RawTradeList = ({
  exchange,
  asset,
  isSearching,
  trades,
  isQueryLoading,
  hasLinkedExchangeAccount,
  error,
}) => {
  console.log("[TradeList] trades useTradesData()", trades);
  // console.log("useTradesData exchange", exchange);
  // console.log("useTradesData asset", asset);
  // console.log("isQueryLoading", isQueryLoading);

  const [isError, setIsError] = useState(false);

  const {
    isQueryLoading: isProfileQueryLoading,
    error: profileQueryError,
    profile,
  } = useProfile();

  let items = trades?.items;

  console.log("raw items: ", items);

  const tradeListErrorProps = {
    error,
    isSearching,
    setIsError,
    trades,
    items,
  };

  const TradeTable = ({ children }) => {
    return (
      <>
        {/* md and lg screens */}
        <div className="items-center w-full  mx-auto mt-2 px-4 sm:px-6 lg:px-8">
          <div className=" hidden md:block lg:block">
            <div className=" align-middle inline-block min-w-full ">
              <div
                className="overflow-x-auto
              rounded-lg shadow border-b border-gray-200 sm:rounded-lg "
              >
                <TradeListError {...tradeListErrorProps} />

                {children}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <TradeTable>
      <table className="w-full border border-collapse table-auto min-w-full">
        <RawTradeHeadings />

        <tbody className="bg-white divide-y divide-gray-200">
          {items && items.length && !isQueryLoading ? (
            items.map((txn) => {
              console.log("raw: ", txn);
              return <RawTradeItem screen="md" rawTransaction={txn} />;
            })
          ) : (
            <RawTradeItem screen="md" isEmpty={true} text="No results" />
          )}
        </tbody>
      </table>
    </TradeTable>
  );
};
