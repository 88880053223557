/**
 * get the files from app-store0screenshots-v1-todoriliev.com copy.sketch
 * and export as .svg and then minify with link:
 * https://svg2jsx.com - this works better
 * https://www.svgminify.com
 *
 */
export function MoonscapeLogoText(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 163 16" {...props}>
      <text
        fill="#E5E7EB"
        fillOpacity="0.85"
        fillRule="evenodd"
        fontFamily="Raleway-Bold, Raleway"
        fontSize="21"
        fontWeight="bold"
        letterSpacing="2"
        transform="translate(-104 -332)"
      >
        <tspan x="103.088" y="347">
          MOONSCAPE
        </tspan>
      </text>
    </svg>
  );
}
