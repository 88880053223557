import React from "react";
import { ScreenHeader } from "./ScreenHeader";
import { MobileNavigation } from "./common";
export const Screen = ({
  children,
  heading,
  className,
}: {
  children: React.ReactNode;
  heading?: string;
  className?: string;
}) => {
  return (
    <div className={`flex-1 flex-col overflow-auto ${className}`}>
      <div
        className={`min-h-screen flex overflow-auto  bg-gray-100 ${className}`}
      >
        <div className={`flex-1 overflow-auto focus:outline-none ${className}`}>
          <MobileNavigation />
          {/* <NavBar /> */}
          <ScreenHeader heading={heading} />
          <div
            className={`max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8 ${className}`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
