// TODO: fix module imports to just set where it is
import React from "react";

import { Screen } from "@/components";

import { SettingsContent } from "./SettingsContent";

export const SettingsScreen = (props) => {
  // usePageTracking();

  return (
    <Screen heading="Settings">
      {/* pass ...props from react-router to pass down the path  */}
      <SettingsContent {...props} />
    </Screen>
  );
};
