import { useState } from "react"

export const useDebounce = () => {
  const [typingTimeout, setTypingTimeout] = useState("")
  const debounce = (func, wait = 300) => {
    clearTimeout(typingTimeout)
    const timeout = setTimeout(() => func(), wait)
    setTypingTimeout(timeout)
  }

  return debounce
}
