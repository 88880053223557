import React, { useState, useEffect } from "react";

import { loadStripe } from "@stripe/stripe-js";
import { axios, authorizedAxios } from "@/axios";
import { useAuth0 } from "@auth0/auth0-react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import {
  // Button, ButtonLink,
  Screen,
} from "@/components";

import { Pricing } from "@/components/Checkout/Pricing";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// TODO feed key from .env

const includedFeatures = [
  "Email support",
  "Access to all upcoming premium features",
  "Entry to annual conference",
  "Official member t-shirt",
];

/** you need to call the /create-checkout-session endpoint and redirect to
 * stripe checkout with it
 *
 */
export const StripeCheckout = () => {
  const [stripeError, setStripeError] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [stripeSessionId, setStripeSessionId] = useState(null);

  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const userId = user?.email;
  /** load stripe  */
  // prettier-ignore

  useEffect(() => {
    async function setupStripe() {
      const stripeKey = process.env.REACT_APP_STRIPE_KEY;
      if (!stripeKey) {
        console.log("[checkout] stripe key missing ");
        return;
      }
      let response = await loadStripe(stripeKey);
      console.log("[checkout] stripe response", response);
      // TODO  check response
      setStripe(response);
    }

    async function getStripeSessionId() {
      try {
        const token = await getAccessTokenSilently();

        // endpoint on server to create stripe session
        const endpoint = `/create-checkout-session/${userId}`;
        const response = await authorizedAxios(token).get(
          `${process.env.REACT_APP_SERVER_URL}${endpoint}`
        );
        const sessionId = response.data.id;
        console.log("[checkout] checkout session id", sessionId);
        setStripeSessionId(sessionId);
      } catch (e) {
        console.log("[checkout] error", e);
      }
    }
    // get stripe session_id from /api
    if (userId) { 
      getStripeSessionId();
    }

    // setup Stripe
    if (!stripe) {
      setupStripe();
    }
  }, [getAccessTokenSilently, stripe, userId]);

  console.log("[checkout] stripeSessionId ", stripeSessionId);

  if (!stripeSessionId) {
    return (
      <div className="grid h-screen place-items-center">
        <p>Loading</p>
      </div>
    );
  }

  const OldCheckout = () => {
    return (
      <div className="bg-gray-100">
        <div className="pt-12 sm:pt-16 lg:pt-20">
          <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
                Free trial until 2023 🎉
              </h2>
              <p className="mt-4 text-xl text-gray-600">
                We've just launched so would love to offer you a free trial for
                the rest of the year while polish our offering
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-gray-100" />
            <div className="relative mx-auto max-w-full px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none">
                <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                  {/* <h3 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">
                    Early access to all upcoming features
                  </h3> */}
                  {/* <p className="mt-6 text-base text-gray-500">
                    Early access to all upcoming features
                  </p> */}
                  <div className="mt-8">
                    <div className="flex items-center">
                      <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">
                        What's included
                      </h4>
                      <div className="flex-1 border-t-2 border-gray-200" />
                    </div>
                    <ul
                      role="list"
                      className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0"
                    >
                      {includedFeatures.map((feature) => (
                        <li
                          key={feature}
                          className="flex items-start lg:col-span-1"
                        >
                          <div className="flex-shrink-0">
                            <CheckCircleIcon
                              className="h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          </div>
                          <p className="ml-3 text-sm text-gray-700">
                            {feature}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="bg-gray-50 py-8 px-6 text-center lg:flex lg:flex-shrink-0 lg:flex-col lg:justify-center lg:p-12">
                  <p className="text-lg font-medium leading-6 text-gray-900">
                    Early Supporter Deal
                  </p>
                  <div className="mt-4 flex items-center justify-center text-5xl font-bold tracking-tight text-gray-900">
                    <span>$5</span>
                    <span className="ml-3 text-xl font-medium tracking-normal text-gray-500">
                      USD/month
                    </span>
                  </div>
                  <p className="mt-4 text-sm">
                    <a href="#" className="font-medium text-sm text-gray-500 ">
                      You won't be charged until 2023
                    </a>
                  </p>
                  <div className="mt-6">
                    <div className="rounded-sm shadow">
                      <button
                        className="flex w-full items-center justify-center rounded-sm border border-transparent bg-gray-800 px-5 py-3 text-base font-medium text-white hover:bg-gray-900"
                        onClick={() => {
                          try {
                            stripe.redirectToCheckout({
                              //
                              sessionId: stripeSessionId,
                            });
                          } catch (e) {
                            setStripeError(e);
                          }
                        }}
                      >
                        Get Access
                      </button>
                    </div>
                  </div>
                  <div className="mt-4 text-sm">
                    {/* <a href="#" className="font-medium text-gray-900">
                      Get a free sample{" "}
                      <span className="font-normal text-gray-500">(20MB)</span>
                    </a> */}
                  </div>
                </div>
              </div>
              {stripeError ? (
                <p className="mt-8 text-red-500 text-center">
                  {stripeError?.toString()}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Screen heading="Plan">
      <div className="bg-gray-100 ">
        <div className="mx-auto max-w-screen-2xl align-center overflow-y-auto px-4 sm:px-6 lg:px-8">
          <Pricing
            stripe={stripe}
            setStripeError={setStripeError}
            stripeSessionId={stripeSessionId}
          />
          {/* <OldCheckout /> */}
        </div>
      </div>
    </Screen>
  );
};
