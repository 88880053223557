import dayjs from "dayjs";

import { formatPrice, formatAmount } from "@/utils";
import { TradeItemProps, TxnType, FormattedTxnType } from "@moonscape/shared";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

/**
 * @deprecated use AuditTradeItemClass instead - has more info in txn
 */
export class TradeItemClass {
  // class property types
  id: string;
  tradeid: string;
  type: string;
  exchange: string;
  timestamp: string;
  datetime: string;
  buyasset: string;
  buyqty: string;
  sellasset: string;
  sellqty: string;
  feeqty: string;
  feeasset: string;
  wallet: string;
  side: string;
  userId: string;

  constructor(tradeItem) {
    const {
      id,
      tradeid,
      type,
      exchange,
      timestamp,
      datetime,
      buyasset,
      buyqty,
      sellasset,
      sellqty,
      feeqty,
      feeasset,
      wallet,
      side,
      userId,
    } = tradeItem;

    this.id = id;
    this.tradeid = tradeid;
    this.type = type;
    this.exchange = exchange;
    this.timestamp = timestamp;
    this.datetime = datetime;
    this.buyasset = buyasset;
    this.buyqty = buyqty;
    this.sellasset = sellasset;
    this.sellqty = sellqty;
    this.feeqty = feeqty;
    this.feeasset = feeasset;
    this.wallet = wallet;
    this.side = side;
    this.userId = userId;
  }

  getExchangeName() {
    switch (this.exchange) {
      case "coinbase":
        return "Coinbase";
      case "binance":
        return "Binance";
      case "kraken":
        return "Kraken"
      case "cryptocom":
        return "Crypto.com"
    }
  }

  getFormattedSide() {
    if (this.side === "buy") return "Buy";
    if (this.side === "sell") return "Sell";
  }

  getSideColor() {
    if (this.isTransferType()) return "grey";
    return `${this.side === "buy" ? "green" : "red"}`;
  }

  getTransferColor() {
    if (this.type === TxnType.DEPOSIT) return "green";
    if (this.type === TxnType.WITHDRAWAL) return "blue";
    if (this.type === TxnType.INTEREST) return "indigo";
  }

  formattedTimestamp() {
    if (this.datetime !== "") {
      return dayjs(this.datetime).utc().format("MMM D YYYY, HH:mm:ss");
    } else {
      return "";
    }
  }

  /**
   * get amount - should handle transfers and interest
   * @returns formattedAmount
   */
  getFormattedAmount() {
    if (this.isTradeType()) {
      if (this.side === "buy") {
        return `+${formatAmount(this.buyqty)}`;
      } else {
        return `-${formatAmount(this.sellqty)}`;
      }
    } else if (this.isTransferType()) {
      if (this.type === TxnType.DEPOSIT) {
        return `+${formatAmount(this.buyqty)}`;
      } else if (this.type === TxnType.WITHDRAWAL) {
        return `-${formatAmount(this.sellqty)}`;
      }
    } else if (this.isInterestType()) {
      return `+${formatAmount(this.buyqty)}`;
    }
  }

  getFormattedAmountWithCurrency() {
    return `${this.getFormattedAmount()} ${this.getAsset()}`;
  }

  getCounterAmount() {
    if (this.isTradeType()) {
      if (this.side === "buy") {
        return `-${formatAmount(this.sellqty)}`;
      } else {
        return `+${formatAmount(this.buyqty)}`;
      }
    } else if (this.isTransferType()) {
      // this.type === TxnType.WITHDRAWAL ?
    }
  }

  getAsset() {
    // console.log("buyasset", {
    //   buyasset: this.buyasset,
    //   sellasset: this.sellasset,
    // });

    if (this.isInterestType()) {
      return this.buyasset;
    }

    if (this.isTradeType()) {
      return this.side === "buy" ? this.buyasset : this.sellasset;
    }

    if (this.isTransferType()) {
      return this.type === TxnType.WITHDRAWAL ? this.sellasset : this.buyasset;
    }
  }

  getCounterAsset() {
    return this.side === "buy" ? this.sellasset : this.buyasset;
  }

  /** is txn a withdrawal or deposit?  */
  isTransferType() {
    if ([TxnType.DEPOSIT, TxnType.WITHDRAWAL].includes(this.type as TxnType)) {
      return true;
    } else {
      return false;
    }
  }
  /** is txn a interest?  */
  isInterestType() {
    if (
      [TxnType.INTEREST, TxnType.STAKING_REWARD].includes(this.type as TxnType)
    ) {
      return true;
    } else {
      return false;
    }
  }
  /** is txn a trade?  */
  isTradeType() {
    if (
      [TxnType.TRADE, TxnType.BUY, TxnType.SELL].includes(this.type as TxnType)
    ) {
      return true;
    } else {
      return false;
    }
  }

  formattedType() {
    // console.log("this.type", this);
    switch (this.type) {
      case TxnType.WITHDRAWAL:
        return "Withdrawal";
      case TxnType.DEPOSIT:
        return FormattedTxnType.DEPOSIT;
      case TxnType.BUY:
        return "Buy";
      case TxnType.SELL:
        return "Sell";
      case TxnType.TRADE:
        // return "Trade";
        return this.getFormattedSide();

      case TxnType.ADVANCED_TRADE_ORDER:
      case TxnType.ADVANCED_TRADE_FILL:
        // return "Trade";
        return this.getFormattedSide();

      case TxnType.INTEREST:
        return "Interest";
      default:
        return this.type;
    }
  }

  formattedBuyAmountWithCurrency() {
    const amount = this.getFormattedAmount();
    const asset = this.getAsset();
    if (amount && asset) {
      return `${this.getFormattedAmount()} ${this.getAsset()}`;
    } else {
      return "";
    }
  }

  formattedSellAmountWithCurrency() {
    const counterAmount = this.getCounterAmount();
    const counterAsset = this.getCounterAsset();
    console.log("counterAmount", counterAmount);
    console.log("counterAsset", counterAsset);
    if (counterAmount && counterAsset) {
      return `${this.getCounterAmount()} ${this.getCounterAsset()}`;
    } else {
      return "";
    }
  }

  truncate(input, nchars) {
    if (input.length > nchars) {
      return input.substring(0, nchars) + "...";
    }
    return input;
  }
}