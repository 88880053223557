import React, { useContext, useState } from "react";

import { EyeSlashIcon, EyeIcon } from "@heroicons/react/24/solid";
import { PrivacyModeContext } from "@/contexts";

export const ScreenHeader = ({ heading }) => {
  const { privacyMode, setPrivacyMode } = useContext(PrivacyModeContext);

  if (!heading) {
    return null;
  }

  const togglePrivacyMode = () => {
    setPrivacyMode(!privacyMode);
  };

  const Icon = privacyMode ? EyeSlashIcon : EyeIcon;

  return (
    <div className="bg-white shadow">
      <div className="px-4 sm:px-6 lg:max-w-full lg:mx-auto lg:px-8">
        <div className="py-4 flex-1  md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div className="flex justify-between items-center">
            <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
              {heading}
            </h1>

            <p className="pl-2 text-gray-800 font-mono font-semibold">beta</p>
          </div>
          <div
            className="cursor-pointer text-slate-700"
            onClick={togglePrivacyMode}
          >
            <Icon className="h-5 w-5" aria-hidden="true" />
          </div>
        </div>
      </div>
    </div>
  );
};
