import { useQuery } from "@tanstack/react-query";
import { axios, authorizedAxios } from "@/axios";
import { useAuth0 } from "@auth0/auth0-react";

export const useRawTransactionsData = (
  page,
  exchange,
  asset,
  isSearching,
  searchedValue,
  onLoading
) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  // userId
  const email = user?.email;

  console.log("useRawTransactionsData-page", page);
  console.log(`Searched DATA FROM useRawTransactionsData ${searchedValue}`);
  let exchangeQueryParam = "";
  let assetQueryParam = "";

  if (asset) assetQueryParam = `&asset=${asset}`;
  if (exchange) exchangeQueryParam = `&exchange=${exchange}`;

  const getTradesData = async () => {
    try {
      const token = await getAccessTokenSilently();
      let url = isSearching
        ? `/trades/search?search=${searchedValue}&cursor=${page}`
        : `/trades/raw?cursor=${page}${exchangeQueryParam}${assetQueryParam}`;

      const res = await authorizedAxios(token).get(
        // TODO: add filter here for exchange and asset

        url
      );
      console.log("useRawTransactionsData() url ", url);

      console.log("/trades res", res.data, user?.email);
      return res.data;
    } catch (error) {
      console.log("[useRawTransactionsData] error ", error);
      return { error };
    }
  };

  // useQuery key to cache
  const useQueryKey = isSearching
    ? `transactions-search-${searchedValue}-${page ?? 0}`
    : `raw-transactions-${exchange}-${asset}-${page ?? 0}`;

  const {
    isLoading: isQueryLoading,
    isSuccess,
    isIdle,
    error,
    isError,
    data: rawTransactions,
    isPreviousData,
    isFetching,
  } = useQuery({
    queryKey: [useQueryKey, page],
    queryFn: getTradesData
  });
  isSearching && isFetching ? onLoading(isFetching) : onLoading(false);

  return { isQueryLoading, isIdle, error, rawTransactions, isPreviousData };
};
