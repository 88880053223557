import React, { useState, useRef, useEffect } from "react";

import { useAPIKeysData, useProfile } from "@moonscape/shared";
import { LinkedExchangeList } from "./ConnectedExchangeList";

import { ProgressBar } from "@/components/common/ProgressBar";
import { useSyncJobStatus } from "@/hooks/useSyncJobStatus";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { authorizedAxios, axios } from "@/axios";

import { CheckCircleIcon } from "@heroicons/react/24/outline";

import { useAuth } from "@moonscape/shared";


import { Link, useRouteMatch } from "react-router-dom";

dayjs.extend(relativeTime);

export const ExchangeAPISettings = () => {
  const [manuallyTriggerSync, setManuallyTriggerSync] = useState(false);

  const { user, isAuthenticated, isLoading, getToken } =
    useAuth();

  const { path, url } = useRouteMatch();

  // get a list of linked exchange connections
  const {
    isQueryLoading,
    error: queryError,
    apikeys,
    email,
    linkedExchanges,
  } = useAPIKeysData();

  const {
    isQueryLoading: isProfileQueryLoading,
    error: profileQueryError,
    profile,
  } = useProfile({ refetchOnWindowFocus: true });

  console.log("profile", profile);
  const lastSynced = profile?.lastSynced;

  const lastSyncFormatted = lastSynced
    ? new Intl.DateTimeFormat("en-GB", {
        dateStyle: "full",
        timeStyle: "long",
      }).format(new Date(lastSynced))
    : "Never";

  /** flag to determine if user has added/updated api keys on modal form */
  const [showSyncProgressBar, setShowSyncProgressBar] =
    useState<boolean>(false);

  // sync job status - and pass down to progress bar
  // TODO: how to set that job is being sync?
  const {
    isQueryLoading: isJobStatusQueryLoading,
    error: jobStatusQueryError,
    jobStatus,
    refetch: refetchSyncJobStatus,
  } = useSyncJobStatus({
    showSyncProgressBar: showSyncProgressBar,
    refetchInterval: showSyncProgressBar ? 2000 : 10000,
  });

  const lastJobStatusProgress = useRef(jobStatus?.totalProgress);

  const [syncCompleted, setSyncCompleted] = useState(false);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // if there is a job status, show it

    // timer to remove the progress bar after a bit after we find out the sync finished
    let timer;
    // if we were synching, and last jobStatus update was a number and now it's zero. set to 100 percent
    console.log(
      "[cc] ",
      lastJobStatusProgress?.current,
      jobStatus?.totalProgress
    );

    if (
      showSyncProgressBar &&
      (lastJobStatusProgress?.current ?? 0) &&
      jobStatus?.totalProgress === 0
    ) {
      // was syncing so turn off polling
      // setShowSyncProgressBar(false);
      setSyncCompleted(true);
      setProgress(100);

      timer = setTimeout(() => {
        // hide the progress bar
        setShowSyncProgressBar(false);
      }, 10000);
    } else if (showSyncProgressBar && jobStatus?.totalProgress !== null) {
      setProgress(jobStatus?.totalProgress || 0);
      lastJobStatusProgress.current = jobStatus?.totalProgress;
    } else if (jobStatus?.totalProgress === null) {
      setShowSyncProgressBar(false);
    }
    return () => clearTimeout(timer);
  }, [jobStatus, showSyncProgressBar]);

  /** hook to handle manually sync trigger */
  useEffect(() => {
    console.log("manually triggering sync", manuallyTriggerSync, jobStatus);

    if (jobStatus?.totalProgress) {
      setShowSyncProgressBar(true);
    } else {
      setShowSyncProgressBar(false);
    }

    if (manuallyTriggerSync) {
      console.log("[sync] going to fetch");
      // trigger sync
      setShowSyncProgressBar(true);
      // TODO: do that progress bar loading stuff here too
      refetchSyncJobStatus();
      console.log("jobStatus", jobStatus?.totalProgress);
    } else if (!jobStatus && !manuallyTriggerSync) {
      setShowSyncProgressBar(false);
      // cancel sync
      // call cancel endpoint
    }
  }, [manuallyTriggerSync, refetchSyncJobStatus, jobStatus]);

  /** holds ref to the formik form so that i can get the handleSubmit function on it and call it  */
  const keyFormRef = useRef<HTMLDivElement>(null);

  const [showModal, setShowModal] = useState(false);

  /** useEffect to trigger a jobStatus progress bar - the backend should have
   * triggered a backend sync after having keys added  */
  useEffect(() => {
    //
  }, [showSyncProgressBar]);

  // console.log("time lastSynced", lastSynced);
  // console.log("time", dayjs(lastSynced).toISOString());
  const syncTimeAgo = lastSynced ? dayjs(dayjs(lastSynced)).fromNow() : "Never";
  // console.log("time syncTimeAgo", syncTimeAgo);

  return (
    <div>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Exchange connections
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Add API keys to enable syncing of your transaction history from your
            exchanges
          </p>
        </div>
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Sync progress
                {/* <p className="font-normal text-xs text-gray-400">
                  A full sync can take 10 mins
                </p> */}
              </dt>
              {/* mark this flag as true to see progress bar  */}
              <dd className="mt-1 flex flex-col text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {showSyncProgressBar ? (
                  <>
                    {/* {profile?.lastSynced?.map(({ exchange }) => ( */}
                    <div className="flex m-3 items-center ">
                      {/* <span>{exchange}</span> */}
                      <ProgressBar
                        // key={exchange}
                        // completed={jobStatus?.totalProgress}
                        completed={() => {
                          if (syncCompleted) {
                            return 100;
                          }

                          if (progress < 6) {
                            return 6;
                          }
                          if (!progress) {
                            return 1;
                          }
                          if (progress > 100) {
                            return 100;
                          }
                          return progress;
                        }}
                        bgcolor="green"
                      />
                    </div>
                    {/* ))} */}
                    {jobStatus?.totalProgress === 100 && (
                      <span className="flex-grow pl-4 ">
                        Last sync: {syncTimeAgo}
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    <span className="flex-grow">Last sync: {syncTimeAgo}</span>
                  </>
                )}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Connected exchanges
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">
                  <LinkedExchangeList exchanges={linkedExchanges} />
                </span>
                <span className="ml-4 flex-shrink-0 ">
                  <Link to={`${url}/new`} className="">
                    <button
                      type="button"
                      className="rounded-full bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Connect exchange
                    </button>
                  </Link>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Import data from a CSV file
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">
                  {/* <LinkedExchangeList exchanges={linkedExchanges} /> */}
                </span>
                <span className="ml-4 flex-shrink-0 ">
                  <a
                    href={`mailto:hello@moonscape.app?subject=Please%20upload%20this%20CSV%20from%20[enter%20exchange%20name]&body=Hi%20-%20my%20Moonscape%20login%20email%20is%20[insert%20email].%0D%0A%0D%0APlease%20upload%20this%20CSV%20to%20my%20account%20so%20that%20it's%20visible%20in%20my%20Dashboard.%0D%0A%0D%0AThis%20CSV%20was%20exported%20from%20[insert%20exchange%20name].%0D%0A%0D%0AI'm%20happy%20to%20answer%20any%20questions%20related%20to%20the%20import.%0D%0A%0D%0AThanks`}
                    className="hyperlink font-medium pl-2"
                  >
                    <button
                      type="button"
                      className="rounded-full bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Upload CSV
                    </button>
                  </a>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
              <dt className="text-sm font-medium text-gray-500">
                Sync frequency
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">Once every three hours</span>
                {manuallyTriggerSync ? (
                  <CheckCircleIcon
                    className="h-8 w-8 text-green-400"
                    aria-hidden="true"
                  />
                ) : (
                  <span className="ml-4 flex-shrink-0 flex items-start space-x-4">
                    <button
                      onClick={async () => {
                        // post to sync router
                        const token = await getToken();
                        const resp = await authorizedAxios(token).post(
                          "/sync/manual",
                          {
                            force: true,
                          }
                        );
                        console.log("sync resp", resp);
                        if (resp.status === 200 && resp.data === "OK") {
                          console.log("sync triggered ", resp);
                          setManuallyTriggerSync(true);
                        }
                      }}
                      className="hyperlink font-medium"
                    >
                      Manually sync
                    </button>
                  </span>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};
