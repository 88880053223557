import { useState, useRef, useEffect } from "react";

import { useAuditData } from "./useAuditData";

export const useAuditPagination = ({
  exchange,
  asset,
  // TODO: handle auditType
  auditType,
  isSearching,
  searchedValue,
  onLoading,
}) => {
  // cursor for pagination
  const [page, setPage] = useState(0);

  /**
   * handles trade search to return trade data
   *
   * either calls the /search endpoint
   * if there is a search value or just gets all trade data (paginated)
   */
  let { isQueryLoading, isIdle, error, auditTransactions, isPreviousData } =
    useAuditData(
      page,
      exchange,
      asset,
      auditType,
      isSearching,
      searchedValue,
      onLoading
    );

  const cursorStack = useRef<number[]>([]);

  const { items, cursor, remaining } = auditTransactions || {};
  const hasMore = auditTransactions?.remaining > 0;

  const resetPagination = () => {
    setPage(0);
    // update stack
    cursorStack.current = [0];
  };

  const nextPagination = () => {
    if (!isPreviousData && hasMore) {
      const newPage = cursor;
      setPage(newPage);
      cursorStack.current = [...cursorStack.current, newPage];
    }
  };

  const previousPagination = () => {
    if (cursorStack.current.length <= 2) {
      resetPagination();
      // // this is here and not in resetPagination because we want to go back to the first page during search and this was setting the url params while searching which was bugging out
      // const newSearchParams = new URLSearchParams(location.search);
      // newSearchParams.set("page", "0".toString());
    } else {
      const temp = cursorStack.current.slice(0, -2); // Remove the last two cursors from the stack
      const newPage = temp[temp.length - 1];
      setPage(newPage);
      cursorStack.current = temp;
    }
  };

  return {
    auditTransactions,
    isQueryLoading,
    error,
    items,
    // pagination handlers
    previousPagination,
    nextPagination,
    resetPagination,
    // pagination data
    hasMore,
    remaining,
    page,
    setPage,
    isPreviousData,
  };
};
