"use client";
import { useState } from "react";
import clsx from "clsx";

import { Button } from "@/components/Checkout/Button";
import { Container } from "@/components/Checkout/Container";
import { Logomark } from "@/components/Checkout/Logo";

const plans = [
  // NOTE: user already logged in show don't need to show Beta plan
  // {
  //   name: "Beta",
  //   featured: false,
  //   highlight: false,
  //   price: { Monthly: "£0", Annually: "£0" },
  //   description:
  //     "Our beta is open! As we work to improve and address any issues, we’d love to hear your feedback. ",
  //   button: {
  //     label: "Join the beta",
  //     href: "https://dashboard.moonscape.app/login",
  //   },
  //   features: [
  //     {
  //       headline: "Automated Transaction Sync",
  //       body: "Effortlessly synchronize your transactions across various exchanges in real-time.",
  //     },
  //     {
  //       headline: "Live Tax Updates on Every Sync",
  //       body: "Experience the convenience of tax reports that update automatically with each sync.",
  //     },

  //     {
  //       headline: "Instant Chat Support",
  //       body: "Get your questions answered promptly with our responsive chat widget.",
  //     },
  //   ],
  //   logomarkClassName: "fill-gray-300",
  // },
  // {
  //   name: 'Investor',
  //   featured: false,
  //   price: { Monthly: '$7', Annually: '$70' },
  //   description:
  //     'You’ve been investing for a while. Invest more and grow your wealth faster.',
  //   button: {
  //     label: 'Subscribe',
  //     href: '/register',
  //   },
  //   features: [
  //     'Commission-free trading',
  //     'Multi-layered encryption',
  //     'One tip every hour',
  //     'Invest up to $15,000 each month',
  //     'Basic transaction anonymization',
  //   ],
  //   logomarkClassName: 'fill-gray-500',
  // },
  {
    name: "Crypto Tax Reporting",
    googleAnalyticsLabel: "paid-1",

    featured: false,
    /* to make the button on the pricing page Green and beta one to be black */
    highlight: true,
    price: { Monthly: "$199", Annually: "£29" },
    // https://dashboard.stripe.com/products/prod_Ofq7RqBH3SGwK3
    pricing: { priceId: "price_1NsUQoK0wR3V9vWW02nYkdSI" },
    description:
      "Recommended for HODLers and Crypto Investors. Calculate your taxes accurately and keep more of your gains.",
    button: {
      label: "Get started",
      // TODO: change this to either be a new page or a page with a param so i can show something different
      href: "/login?register=true",
    },
    features: [
      {
        headline: "Automated Transaction Sync",
        body: "Effortlessly synchronize your transactions across multiple exchanges.",
      },
      {
        headline: "Priority Support & Enhanced Features",
        body: "Receive top-notch support and watch your feature requests take the lead.",
      },
      {
        headline: "2022/23 and 2023/24 Tax Reports",
        body: "Simplify tax season with comprehensive reports tailored to the 2023/24 tax year.",
      },
      {
        headline: "Live Tax Updates on Every Sync",
        body: "Stay up-to-date as tax reports automatically refresh with every sync.",
      },
      {
        headline: "Historical Fiat Value Calculations",
        body: "Explore your past transactions with precision, calculating their fiat value using historical rates.",
      },
      {
        headline: "Live Chat Widget",
        body: "Get instant answers to your queries with our convenient chat widget.",
      },
      {
        headline: "Exclusive Early Access",
        body: "Be the first to access new features as we roll them out.",
      },
    ],
    logomarkClassName: "fill-green-500",
  },
];

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
        fill="currentColor"
      />
      <circle
        cx="12"
        cy="12"
        r="8.25"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function Plan({
  name,
  price,
  description,
  button,
  features,
  featured = false,
  highlight = false,
  googleAnalyticsLabel,
  pricing, // contains stripe priceId
  activePeriod,
  logomarkClassName,
  stripe,

  setStripeError,
  stripeSessionId,
}) {
  return (
    <section
      className={clsx(
        "flex flex-col overflow-visible rounded-3xl p-6 shadow-lg shadow-gray-900/5",
        featured ? "order-first bg-gray-900 lg:order-none" : "bg-white"
      )}
    >
      <h3
        className={clsx(
          "flex items-center text-sm font-semibold",
          featured ? "text-white" : "text-gray-900"
        )}
      >
        <Logomark className={clsx("h-6 w-6 flex-none", logomarkClassName)} />
        <span className="ml-4">{name}</span>
      </h3>
      {/* Price  */}
      <p
        className={clsx(
          "relative mt-5 flex text-3xl tracking-tight",
          featured ? "text-white" : "text-gray-900"
        )}
      >
        {price.Monthly === price.Annually ? (
          price.Monthly
        ) : (
          <>
            <span
              aria-hidden={activePeriod === "Annually"}
              className={clsx(
                "transition duration-300",
                activePeriod === "Annually" &&
                  "pointer-events-none translate-x-6 select-none opacity-0"
              )}
            >
              {price.Monthly}
            </span>
            <span
              aria-hidden={activePeriod === "Monthly"}
              className={clsx(
                "absolute left-0 top-0 transition duration-300",
                activePeriod === "Monthly" &&
                  "pointer-events-none -translate-x-6 select-none opacity-0"
              )}
            >
              {price.Annually}
            </span>
          </>
        )}
      </p>
      {/* One-time payment for the tax year
       */}
      {name !== "Beta" && (
        <p className={clsx("mt-1 text-xs ")}>
          <span
            className={clsx(
              " text-sm",
              featured ? "text-white" : "text-gray-600"
            )}
          >
            Annual subscription
          </span>
        </p>
      )}
      <p
        className={clsx(
          "mt-3 text-sm",
          featured ? "text-gray-300" : "text-gray-700"
        )}
      >
        {description}
      </p>
      <div className="order-last mt-6">
        {/* Money back guarantee  */}
        {name !== "Beta" && (
          <div
            className={clsx(
              " ",
              featured ? " text-gray-300" : " text-gray-700"
            )}
          >
            <p className="mb-4 -mt-4 text-center text-sm  text-gray-500">
              30 day money-back guarantee
            </p>
          </div>
        )}

        {/* Feature list  */}
        <ul
          role="list"
          className={clsx(
            "-my-2 divide-y text-sm",
            featured
              ? "divide-gray-800 text-gray-300"
              : "divide-gray-200 text-gray-700"
          )}
        >
          {features.map((feature, index) => (
            <li key={index} className="flex py-2">
              <CheckIcon
                className={clsx(
                  "h-6 w-6 flex-none",
                  featured ? "text-white" : "text-green-500"
                )}
              />
              <div className="ml-4">
                <h3 className="text-md font-semibold">{feature.headline}</h3>
                <p className="text-gray-600">{feature.body}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <Button
        onClick={() => {
          // TODO: need to tell stripe what plan they are signing up for
          if (highlight) {
            // highlight means it's paid. rather i highlight the paid plan so reusing this variable
            try {
              console.log("[cc] trying to redirect", stripeSessionId);
              stripe.redirectToCheckout({
                sessionId: stripeSessionId,
                // rest of fields are set on server side in the stripe-router.js
              });
            } catch (e) {
              console.log("[mw] catch stripe redirect");
              setStripeError(e);
            }
          }
        }}
        href={!highlight ? button.href : undefined}
        // color={highlight ? 'green' : 'gray'}
        className={clsx(
          "mt-12",
          highlight
            ? "bg-green-500 hover:bg-green-400"
            : "bg-gray-400 hover:bg-gray-300"
        )}
        aria-label={`Get started with the ${name} plan for ${price}`}
        id={`pricing-${googleAnalyticsLabel}-cta`}
      >
        {button.label}
      </Button>
    </section>
  );
}

export function Pricing({ stripe, setStripeError, stripeSessionId }) {
  let [activePeriod, setActivePeriod] = useState("Annually");

  const { countryCode, taxAgencyName, isLoading } = {
    countryCode: "UK",
    taxAgencyName: "HMRC",
    isLoading: false,
  };

  return (
    <>
      <section
        id="pricing"
        aria-labelledby="pricing-title"
        className="border-t border-gray-200 bg-gray-100 py-20 sm:py-32"
      >
        <Container>
          <div className="mx-auto max-w-2xl text-center  lg:max-w-3xl">
            <h2 className="text-3xl font-medium tracking-tight text-gray-900">
              Simplify your {taxAgencyName} obligations today.
            </h2>
            <p className="mt-2 text-lg text-gray-600">
              Affordable plans tailored for every {countryCode} crypto investor
              and HODLer.
              <br />
              Choose Your Path to Crypto Tax Clarity.
            </p>
            {/* <p className="mt-2 text-lg text-gray-600">
            Signup to start syncing your exchanges and calculating your crypto
            tax!
          </p> */}
          </div>
          <div className="mx-auto max-w-2xl text-center">
            {/* <Button
              className="mt-12 mb-4 md:mb-4 md:mt-4"
              color="green"
              href="https://dashboard.moonscape.app/login"
            >
              Join the Beta
            </Button> */}
            {/* <p className="pt-4 text-gray-400">
            Our beta is open and it's free! As we work to improve and
            address any issues, we'd love to hear your feedback
          </p> */}
          </div>
          {/* <div className="mx-auto max-w-2xl text-center">
            <h2
              id="pricing-title"
              className="text-3xl font-medium tracking-tight text-gray-900"
            >
              Flat pricing, no management fees.
            </h2>
            <p className="mt-2 text-lg text-gray-600">
              Whether you’re one person trying to get ahead or a big firm trying
              to take over the world, we’ve got a plan for you.
            </p>
          </div> */}

          {/* <MonthlyOrAnnualSwitch
            activePeriod={activePeriod}
            setActivePeriod={setActivePeriod}
          /> */}

          <div className="mx-auto mt-4 grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-8 sm:mt-20 lg:max-w-none lg:grid-cols-1">
            {plans.map((plan) => (
              <Plan
                key={plan.name}
                {...plan}
                activePeriod={activePeriod}
                stripe={stripe}
                setStripeError={setStripeError}
                stripeSessionId={stripeSessionId}
              />
            ))}
          </div>
        </Container>
      </section>
    </>
  );
}
