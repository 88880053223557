import { UseQueryResult, useQuery, useMutation } from "@tanstack/react-query";
import { authorizedAxios } from "@/axios";
import { useAuth } from "@moonscape/shared";

import { Profile } from "@/types/moonscape";
import { minutes } from "@/utils";

interface ProfileOptions {
  // Define your optional properties here
  // Example: firstName?: string;
  refetchOnWindowFocus?: boolean;
}

export const useProfileQueryKey = ["profile"];

export const useProfile = (options?: ProfileOptions) => {
  // get auth0 user details
  const { user, isAuthenticated, isLoading, getToken } = useAuth();

  const email = user?.email; // userId

  // react-query fetch data function
  const getProfile = async (): Promise<Profile> => {
    const url = `/profile`;
    try {
      const token = await getToken();

      const res = await authorizedAxios(token).get(url);

      console.log("[profile] res", res);
      return res.data;
    } catch (error) {
      console.log("[useProfile] error", error);
      throw error;
    }
  };

  // do the useQuery
  // react-query - waiting on auth0 email field before triggering query
  const {
    isLoading: isQueryLoading,
    isIdle,
    isSuccess,
    error,
    isError,
    data: profile,
    refetch,
  }: UseQueryResult<Profile, unknown> = useQuery({
    queryKey: [useProfileQueryKey],
    queryFn: getProfile,

    // The query will not execute until the userId exists
    enabled: !!email,

    refetchOnWindowFocus: !!options?.refetchOnWindowFocus,

    // refetch every x ms
    staleTime: 1 * minutes,

    // refetch every x ms
    refetchInterval: 5 * minutes
  });

  return { isQueryLoading, isIdle, error, profile, email, refetch };
};

export const useUpdateProfileTax = () => {
  const { getToken } = useAuth();

  const updateProfileTax = async (updatedProfile: Profile) => {
    const token = await getToken();
    const url = `/profile/tax`;

    const response = await authorizedAxios(token).post(url, updatedProfile);

    if (!response.data) {
      throw new Error("Profile update failed");
    }

    return response.data;
  };

  return useMutation({
    mutationFn: updateProfileTax
  });
};
