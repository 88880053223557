import React from "react";
import {
  PieChart,
  Pie,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
} from "recharts";

import { formatAmount, getCurrencySymbol } from "@/utils";
import { Skeleton } from "antd";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",

  "#AF19FF",

  "#6A5ACD",
  "#20B2AA",
  "#FF69B4",
];

export const CustomPieChart = ({ data, baseCurrency }) => {
  // Calculate total value for each asset and group small slices
  const chartData:any = [];
  const otherCategory:any = { name: "Other", value: 0, items: [] };
  if (data){
    // Determine the threshold for small slices, e.g., 1% of the total value
    const totalValue = Object.keys(data?.totals).reduce(
      (acc, key) =>
        acc + data?.totals[key].amount * (data?.totals[key]?.price ?? 1),
      0
    );
    const threshold = totalValue * 0.01; // 1% of total value

    const currencySymbol = getCurrencySymbol(baseCurrency);
    Object.keys(data?.totals).forEach((key) => {
      const asset = data?.totals[key];
      const value = asset.amount * (asset.price ?? 1);
      if (value < threshold) {
        otherCategory.value += value; // Add to 'Other' category
        otherCategory.items.push({ name: key, value: value }); // Keep track of small items
      } else {
        chartData.push({
          name: key,
          value: value,
          formattedValue: formatAmount(value, currencySymbol),
        });
      }
    });
    // If 'Other' category is not empty, add it to the chart data
    if (otherCategory.value > 0) {
      otherCategory.formattedValue = formatAmount(
        otherCategory.value,
        currencySymbol
      );
      chartData.push(otherCategory);
    }
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload[0]?.payload?.formattedValue}`}</p>
        </div>
      );
    }

    return null;
  };
  // Custom legend component
  const renderCustomLegend = (props) => {
    const { payload } = props;

    return (
      <ul className="list-none m-0 p-0 flex flex-wrap font-sans">
        {payload.map((entry, index) => {
          const item = chartData.find((item) => item.name === entry.value);
          return (
            <li
              key={`item-${index}`}
              className="text-sm mr-2"
              style={{ color: entry.color }}
            >
              {entry.value}: {item.formattedValue}
              {entry.value === "Other" && (
                <ul className="pl-4">
                  {otherCategory.items.map((subEntry) => (
                    <li key={subEntry.name} className="text-xs">
                      {subEntry.name}:{" "}
                      {subEntry.value.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    // Calculate total value for each asset
    <ResponsiveContainer width="100%" height="100%">
      {
        chartData.length > 0 ?
          <PieChart>
            <Pie
              cx="50%"
              cy="50%"
              data={chartData}
              labelLine={false}
              label={({ name, percent }) => {
                return `${name}: ${(percent * 100).toFixed(0)}%`
              }}
              innerRadius={80}
              outerRadius={100}
              paddingAngle={3}
              fill="#8884d8"
              dataKey="value"
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    entry.name === "BTC" ? "#EF8F1E" : COLORS[index % COLORS.length]
                  }
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />

            {/* <Legend content={renderCustomLegend} /> */}
            <>{console.log("test: ", data)}</>
          </PieChart>
        : <div className="flex w-full h-full justify-center mt-28">
          < Skeleton.Avatar size={window.innerWidth/2- 100 - 32 - 150} active/>
          </div>
      }
    </ResponsiveContainer>
  );
};

export default CustomPieChart;
