import { useAuth0 } from "@auth0/auth0-react";
import { authorizedAxios } from "@/axios";

export const useTriggerTaxReport = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const recalculateTax = async () => {
    try {
      const token = await getAccessTokenSilently();

      let endpoint = `/tax`;

      const res = await authorizedAxios(token).post(endpoint);
      console.log("recalculateTax() endpoint ", endpoint);

      console.log("recalculateTax res", res.data);
      return res.data;
    } catch (error) {
      console.log("[recalculateTax] error ", error);
      return { error };
    }
  };

  return {
    recalculateTax,
  };
};
