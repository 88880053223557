import React, { Fragment, useState } from "react";
import { Formik, Field } from "formik";
import { useAuth0 } from "@auth0/auth0-react";
import * as yup from "yup";

import { authorizedAxios } from "@/axios";

import { Thumb } from "./Thumb";

const FILE_SIZE = 5000 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const validationSchema = yup.object().shape({
  // yup.object().shape({
  //   file: yup.mixed().required(),
  // })
  file: yup
    .mixed()
    .required("A file is required")
    .test("fileSize", "File too large", (value) => {
      console.log("fileSize", value);
      return value && value.size <= FILE_SIZE;
    })
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
});

export const FeedbackPane = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const email = user?.email; // userId

  return (
    <>
      <Formik
        initialValues={{ file: null }}
        validationSchema={validationSchema}
        validateOnBlur={true}
        onSubmit={async (values) => {
          alert(
            JSON.stringify(
              {
                fileName: values.file.name,
                type: values.file.type,
                size: `${values.file.size} bytes`,
              },
              null,
              2
            )
          );

          // post data
          try {
            const token = await getAccessTokenSilently();

            const formData = new FormData();

            formData.append("userId", email);
            formData.append("message", values.message);
            formData.append("image", values.file);

            const resp = await authorizedAxios(token).post(
              "/feedback",
              formData,
              {
                // headers: {
                //   "Content-Type": `multipart/form-data; ${formData.getBoundary()}`,
                // },
              }
            );

            console.log("feedback resp", resp);
          } catch (error) {
            console.log("feedback axios error", error);
          }
        }}
        // validationSchema={yup.object().shape({
        //   file: yup.mixed().required(),
        // })}
      >
        {({ errors, touched, values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label for="file">File upload</label>
              <input
                id="file"
                name="file"
                type="file"
                onChange={(event) => {
                  setFieldValue("file", event.currentTarget.files[0]);
                }}
                className="form-control"
              />
              <Thumb file={values.file} />

              <label for="message">Message</label>

              <Field
                id="message"
                name="message"
                component="textarea"
                rows="4"
              />
            </div>
            {errors.file ? <div>{errors.file}</div> : null}

            <button type="submit" className="btn btn-primary">
              submit
            </button>
          </form>
        )}
      </Formik>
    </>
  );
};
