import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { authorizedAxios } from "@/axios";
import { useAuth } from "@moonscape/shared";

type CapitalGainsReport = {
  userid: string;
  gain: number;
  total_proceeds: number;
  total_cost: number;
  disposals: number;
  total_gain: number;
  total_loss: number;
  allowance_used: number;
  taxable_gain: number;
  proceeds_warning: boolean;
  cgt_rate: number;
  estimated_tax_due: number;
  tax_year: string;
  created_at: string;
  updated_at: string;
  disposal_type: string;
  allowance: number;
};

export const useCapitalGainsData = ({
  taxYear,
}): {
  isQueryLoading: boolean;
  isIdle: boolean;
  error: Error | null;
  cgt_report: CapitalGainsReport | undefined;
  email: string | undefined;
  refetch: () => void;
} => {
  // get auth0 user details
  const { getToken, user } = useAuth();

  const email = user?.email; // userId

  // react-query fetch data function
  const getCapitalGainsReport = async (): Promise<any> => {
    console.log("calling getCapitalGainsReport");
    const url = `/tax/cgt/${taxYear}`;
    try {
      const token = await getToken();

      const res = await authorizedAxios(token).get(url);
      console.log("[useCapitalGainsData] res", res?.data);

      return res.data;
    } catch (error) {
      console.log("[useCapitalGainsData] error", error);
      throw error;
    }
  };

  const useQueryKey = `cgt_report-${taxYear}`;

  // do the useQuery
  // react-query - waiting on auth0 email field before triggering query
  const {
    isLoading: isQueryLoading,
    isIdle,
    isSuccess,
    error,
    isError,
    data: cgt_report,
    refetch,
  }: UseQueryResult<CapitalGainsReport, Error> = useQuery({
    queryKey: [useQueryKey],
    queryFn: getCapitalGainsReport,

    // The query will not execute until the userId exists
    enabled: !!email,

    refetchOnWindowFocus: false,

    // When staleTime elapses, your data is marked as stale - but that doesn't mean it will be instantly refetched.
    // staleTime: 10000,
    // refetch every x ms
    // refetchInterval: 10000,
    keepPreviousData: true,
  });

  return { isQueryLoading, isIdle, error, cgt_report, email, refetch };
};
