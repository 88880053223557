import { useQuery } from "@tanstack/react-query";

import { request, gql } from "graphql-request";

// const graphqlEndpoint = "http://apix.getmoon.io:8080/graphql";
const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_URL;

export const usePriceData = (exchange, ccypair) => {
  // const exchange = "binance"
  // const ccypair = "BTCGBP"

  const getPriceData = async () => {
    try {
      const response = await request(
        graphqlEndpoint || "",
        gql`
          query marketPrice($exchange: String, $ccypair: String) {
            price(exchange: $exchange, ccypair: $ccypair) {
              price
              summaryData {
                priceChange
                priceChangePercent
              }
            }
          }
        `,
        { exchange, ccypair }
      );
      console.log("response", response);
      return response;
    } catch (e) {
      console.log("caught error", e);
    }
  };
  // const { isLoading, isSuccess, isIdle, error, price } =
  console.log("exchange, ccypair", exchange, ccypair);
  return useQuery({
    queryKey: ["price", exchange, ccypair],
    queryFn: getPriceData,
    refetchOnWindowFocus: true
  });
};
