import { Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

export const ButtonLink = ({ buttonText, href, color, openInNewTab }) => {
  // Define a default color in case 'color' prop is not provided
  const backgroundColor = color || "bg-indigo-600";
  return (
    <a
      href={href}
      className={`rounded-xl ${backgroundColor} px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:${backgroundColor} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:${backgroundColor}`}
      target={openInNewTab ? "_blank" : "_self"}
      rel="noreferrer"
    >
      {buttonText}
    </a>
  );
};
export const Button = ({ buttonText, onClick, color, isLoading = false }) => {
  // Define a default color in case 'color' prop is not provided
  const backgroundColor = color || "bg-green-600";

  return (
    <button
      disabled={isLoading}
      onClick={onClick}
      className={`hover:${backgroundColor} rounded-xl ${backgroundColor} px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:${backgroundColor}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
    >
      <div className="flex gap-2 items-center">
        {isLoading ? (
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 14 }}
                spin
                color="#000 !important"
              />
            }
            style={{ color: "#fff" }}
          />
        ) : (
          <></>
        )}
        {buttonText}
      </div>
    </button>
  );
};
export const GreenButton = ({ buttonText, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="rounded-xl bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-700"
    >
      {buttonText}
    </button>
  );
};
