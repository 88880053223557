/// <reference types="@types/segment-analytics" />
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

import posthog from "posthog-js";

/**
 * Segment analytics - extends window object for analytics
 */
declare global {
  interface Window {
    analytics: any;
  }
}

const DEFAULT_DEV_POSTHOG_KEY = "XXXXXXXX";

const DEFAULT_DEV_SEGMENT_WRITE_KEY = "GSQnEh2JIoyHSbcfXyOwyXJKqQZgPbdT";

const development =
  !process.env.APP_ENV || process.env.NODE_ENV === "development";

export function isDev() {
  return development;
}

/** loads Segment analytics - script in public/index.html */
export const loadAnalytics = () => {
  // If window.analytics is already defined, return immediately
  // if (window.analytics) {
  //   console.log("[analytics] Analytics is already loaded");
  //   return;
  // }

  // TODO: move into env var
  // Load Segment Analytics
  console.log("loading analytics ", process.env.REACT_APP_SEGMENT_WRITE_KEY);
  if (window.analytics.load){
    window.analytics.load(
      process.env.REACT_APP_SEGMENT_WRITE_KEY || DEFAULT_DEV_SEGMENT_WRITE_KEY,
      { initialPageview: false, autoPageview: false }
    );
  }
  // load post hog
  posthog.init(process.env.REACT_APP_POSTHOG_KEY ?? DEFAULT_DEV_POSTHOG_KEY, {
    api_host: "https://app.posthog.com",
  });

  // load Google Analytics

  /** google analytics production tracking id  */
  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  const tagManagerArgs = {
    gtmId: TRACKING_ID,
  };

  // don't track non-production environments
  if (isDev()) {
    if (TRACKING_ID) {
      ReactGA.initialize(TRACKING_ID);
    } else {
      console.log("no GA tracking id found");
    }
    return;
  }
  TagManager.initialize(tagManagerArgs);
};

export const page = (category?: any, pathname?: any) => {
  if (!category || !pathname) {
    return;
  }

  console.log("[analytics] tracking page", pathname, category);
  window.analytics.page(category, pathname);

  // NOTE: use usePageTracking hook instead
  // ReactGA.pageview(window.location.pathname + window.location.search);
};

export const identify = (userId: string, traits?: any) => {
  window.analytics.identify(userId, traits);

  // identify with posthog
  posthog.identify(userId, traits);
};

export const reset = () => {
  window.analytics.reset();
};

// TODO: add segment analytics calls here

/** segment and GA track call - should be used for interaction events i.e. user clicked button or initiated action
 * https://segment.com/academy/collecting-data/the-anatomy-of-a-track-call/
 *
 *
 */

export const trackEvent = (eventName: string, properties: any) => {
  if (!window.analytics) {
    console.error("Segment has not been initialized");
    return;
  }

  window.analytics.track(eventName, properties);

  // posthog
  // posthog.capture('my event', { property: 'value' })
};

// export const trackEvent = (name: string, properties: any) => {
//   window.analytics.track(name, properties);

//   ReactGA.event({
//     category: "User",
//     action: name,
//     label: properties?.label,
//     value: properties?.value,
//   });
// };
