import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { Button, ButtonLink, Screen, ScreenHeader } from "@/components";
import { trackEvent } from "@/analytics";
import axios, { Axios, AxiosError } from "axios"; // this is to post to coinbase to get access token

import { authorizedAxios } from "@/axios"; // this is to post to moonscape to save access token
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { logAxiosError } from "@/utils";
import { useQueryClient } from "@tanstack/react-query";

import {
  useProfileQueryKey,
  useGetLinkedExchangesQueryKey,
} from "@moonscape/shared";

// console.log("[oauth] client_id", client_id)
// console.log("[oauth] client_secret", client_secret)
// console.log("[oauth] redirect_uri", redirect_uri);
/**
 * Extracts the authorization code from the URL.
 *
 * @param location - The location object from react-router.
 *
 * The location object typically has the following properties:
 * - pathname: A string representing the path of the URL.
 * - search: A string representing a query string, including the leading "?" character.
 * - state: The state object, if any, passed to history.push or history.replace when this location was pushed onto the stack. Useful for passing extra information to the route.
 * - hash: A string representing a URL fragment identifier, including the leading "#" character.
 *
 * @returns The authorization code.
 */
export const getCodeFromLocation = ({ location }) => {
  console.log("[oauth] [getCodeFromLocation] location ", location);
  const split = location.toString().split("?");
  if (split.length < 2) {
    return null;
  }
  const pairs = split[1].split("&");
  for (const pair of pairs) {
    const [key, value] = pair.split("=");
    if (key === "code") {
      return decodeURIComponent(value || "");
    }
  }
  return null;
};

export const Error = ({ children }) => {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            Coinbase Account Linking: Just a Moment Away
          </h3>
          <div className="mt-2 text-sm text-red-700">{children}</div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              {/* <button
                type="button"
                className="rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
              >
                View status
              </button> */}
              <Link to={`settings/exchange`}>
                <button
                  type="button"
                  className="rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                >
                  Go back
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Success = ({ children }) => {
  const queryClient = useQueryClient();

  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">
            Coinbase Account Linking: Success!
          </h3>
          <div className="mt-2 text-sm text-green-700">{children}</div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              {/* <button
                type="button"
                className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
              >
                View status
              </button> */}
              <Link to={`settings/exchange`}>
                <button
                  type="button"
                  className=" rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                  onClick={() => {
                    queryClient.invalidateQueries({
                      queryKey: [`audit-${page}`],
                    });
                  }}
                >
                  Go back to Settings
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Coinbase OAuth2.0 handler component.
 * takes the authorization code from the URL and exchanges it for an access token.
 *
 * for testing - Chrome blocks CORS from localhost - chrome://flags/#block-insecure-private-network-requests
 * see https://stackoverflow.com/a/69464875/427736
 *
 * use this extension and toggle ON
 * https://chrome.google.com/webstore/detail/allow-cors-access-control/lhobafahddgcelffkeicbaginigeejlf?hl=en
 *
 */
export const CoinbaseAuth = () => {
  const location = useLocation();
  const queryClient = useQueryClient();

  console.log("[oauth] location: ", location);
  const [isCoinbaseAuthenticated, setIsCoinbaseAuthenticated] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();

  const exchangeAccessToken = useCallback(
    async (newCode, state) => {
      // Get the CSRF token from local storage
      const csrfToken = localStorage.getItem("csrfToken");

      // If the state parameter doesn't match the CSRF token, throw an error
      if (state !== csrfToken) {
        console.log("[oauth] Invalid CSRF token", state, csrfToken);
      } else {
        console.log("[oauth] csrf token OK");
      }

      try {
        const token = await getAccessTokenSilently();

        const res = await authorizedAxios(token).post(`/oauth/coinbase/token`, {
          code: newCode,
        });

        console.log("[oauth] coinbase authenticated", res);
        setIsCoinbaseAuthenticated(true);

        // invalidate profile query to refetch the user's profile
        queryClient.invalidateQueries({
          queryKey: [useProfileQueryKey],
        });
        queryClient.invalidateQueries({
          queryKey: [useGetLinkedExchangesQueryKey],
        });
      } catch (error) {
        console.error("[oauth] Error exchanging for access token:", error);

        logAxiosError(error);
      }
    },
    [getAccessTokenSilently, queryClient]
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const newCode = new URLSearchParams(location.search).get("code");
    const state = urlParams.get("state");

    console.log("[oauth] code=", newCode);

    if (newCode) {
      try {
        exchangeAccessToken(newCode, state);
      } catch (e) {
        console.error("[oauth] error exchanging access token", e);
      }
    }
  }, []);

  return (
    <div className="min-h-screen flex-1 flex flex-col overflow-hidden">
      <Screen heading="Connect Coinbase">
        <div className="max-w-7xl my-8 mx-auto py-4 px-4 sm:px-6 lg:px-8">
          {isCoinbaseAuthenticated ? (
            <Success>
              <p>
                We're syncing your transactions. Hold tight. You'll soon see
                them start coming into your Dashboard
              </p>
            </Success>
          ) : (
            <Error>
              <p>
                We're almost there! There was a slight delay in linking your
                Coinbase account. Rest assured, your data remains protected.
              </p>
            </Error>
          )}
        </div>
      </Screen>
    </div>
  );
};
