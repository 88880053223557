import React from "react";

import { Alert } from "@/components/OnboardingAlert";
import {
  BackButton,
  Screen
} from "@/components";

import { Positions } from "@/screens";

import { useHistory } from "react-router-dom";
import { useProfile } from "@moonscape/shared";

export const PositionsScreen = () => {
  const {
    isQueryLoading: isProfileQueryLoading,
    error: profileQueryError,
    profile,
  } = useProfile();
  console.log("[PositionsScreen] profile", profile);

  let hasLinkedExchangeAccount = profile?.hasLinkExchangeAccounts;
  hasLinkedExchangeAccount = true;

  return (
    <Screen heading="Positions">
      <main className="flex-1 relative pb-8 z-0">
        {!hasLinkedExchangeAccount && <Alert />}

        <div className="px-4 py-4">
          <BackButton />
        </div>
        <div className="mt-8">
          <Positions
            showAll={true}
            hasLinkedExchangeAccount={hasLinkedExchangeAccount}
          />

          {/* <RecentActivity
                hasLinkedExchangeAccount={hasLinkedExchangeAccount}
              /> */}
        </div>
      </main>
      <div className="py-40"></div>
    </Screen>
  );
};
