import React from "react";
import dayjs from "dayjs";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { AuditTradeItemClass } from "@moonscape/shared";

import { TradeItemSmall } from "./TradeItemSmall";
import { TradeItemMedium } from "./TradeItemMedium";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export interface TradeItemProps {
  id: string;
  tradeid: string;
  type: string;
  exchange: string;
  timestamp: string;
  datetime: string;
  buyasset: string;
  buyqty: string;
  sellasset: string;
  sellqty: string;
  feeqty: string;
  feeasset: string;
  wallet: string;
  side: string;
  userId: string;
  screen: "sm" | "md" | "lg";
  // for showing emtpy row i.e. no results
  isEmpty: boolean;
  text: string;
  isLoading: boolean;
}

export enum TxnType {
  DEPOSIT = "deposit",
  WITHDRAWAL = "withdrawal",
  TRADE = "trade",
  BUY = "buy",
  SELL = "sell",
  INTEREST = "interest",
  STAKING_REWARD = "staking reward",
  ADVANCED_TRADE_FILL = "advanced trade fill",
}

export enum FormattedTxnType {
  DEPOSIT = "Deposit",
  WITHDRAWAL = "Withdrawal",
  TRADE = "Trade",
  BUY = "Buy",
  SELL = "Sell",
  INTEREST = "Interest",
  ADVANCED_TRADE_FILL = "Advanced Trade Fill",
}

export const TradeItem = (props) => {
  const {
    id,
    side,
    userId,
    screen, // if screen is sm for breakpoints
    isEmpty = false,
    text,
    isQueryLoading
  }: TradeItemProps = props;

  const t = new AuditTradeItemClass(props);

  const EmptyStateText = () => (
    <div className="flex flex-col  items-center justify-center p-10">
      <div className="bg-gray-100 p-5 rounded-full">
        <MagnifyingGlassIcon
          className={`h-8 w-8  text-gray-900`}
          aria-hidden="true"
        />
      </div>
      <h2 className="mt-4 text-xl font-semibold text-gray-800">
        No transactions
      </h2>
      <p className="text-gray-600 py-2">
        There are no transactions to display.
      </p>
    </div>
  );

  // empty state - no results
  if (isEmpty === true && screen === "sm") {
    return (
      // sm screen
      <div className="flex-1 bg-white px-4 py-2 items-start text-left text-sm ">
        <EmptyStateText />
      </div>
    );
    // md screen
  } else if (isEmpty === true && screen === "md") {
    return (
      <td colSpan={10} className="flex-1 px-6 py-4 text-left">
        <div className="text-sm text-gray-900">
          <EmptyStateText />
        </div>
      </td>
    );
  }

  // for sm screen
  if (screen === "sm") {
    return <TradeItemSmall t={t} isQueryLoading={isQueryLoading} />;
  } else {
    // for 'md' and larger screens
    return <TradeItemMedium t={t} isQueryLoading={isQueryLoading} />;
  }
};
const tableHeadings = [
  "Transaction",
  "Type",
  "Side",
  "Buy Asset",
  "Sell Asset",
  "Buy Amount",
  "Sell Amount",
  "Date (UTC)",
  "", // empty
];

export const TradeHeadings = () => (
  <thead className=" ">
    <tr>
      {tableHeadings.map((heading) => (
        <th
          key={heading}
          className="px-3 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 lg:px-6 "
        >
          {heading}
        </th>
      ))}
    </tr>
  </thead>
);
